import React from "react";
import { Table } from "@chakra-ui/react";
import { overviewTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import OverviewTableBody from "./OverviewTableBody";

function OverviewTable({ request }) {
  return (
    <Table variant="striped" colorScheme="gray" bg="#fff" borderRadius={"5px"}>
      <TableHead tableHeadData={overviewTableHead} />
      <OverviewTableBody request={request} />
    </Table>
  );
}

export default OverviewTable;
