import { errorNotifier } from "../index";
import http, { AUTH_ROUTES } from "./api";

export const getNotifications = async (setData, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_NOTIFICATIONS);
    setLoading(false);
    setData(data);
  } catch (e) {
    setLoading(false);
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const readNotification = async (id, setLoading, setRefresh) => {
  try {
    const result = await http.put(AUTH_ROUTES.READ_NOTIFICATION(id));
    console.log(result, "Notification Read");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    setLoading(false);
    console.log(e?.data?.message, "Error Reading Notification");
  }
};