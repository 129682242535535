import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import dayjs from "dayjs";

const MessageCard = ({ message, showDate }) => {
  return (
    <>
      {!showDate && (
        <Flex alignItems="center" width="100%" justifyContent="center" mb="7px">
          <Box flex="1" borderBottom="1px solid #70707030" />
          <Text
            fontSize="12px"
            textTransform="uppercase"
            color="#707070"
            mx="8px"
          >
            {dayjs(message?.createdAt)?.format("DD, dddd MMM YYYY")}
          </Text>
          <Box flex="1" borderBottom="1px solid #70707030" />
        </Flex>
      )}
      <Flex
        direction={message?.sender === "Admin" ? "row-reverse" : "row"}
        mt="8px"
      >
        <Box ml="12px">
          <Text
            fontSize="12px"
            py="4px"
            px="8px"
            maxWidth="400px"
            rounded="md"
            bg={message?.sender === "Admin" ? "#C2D0D0" : "#FDEFEF"}
            color={message?.sender === "Admin" ? "#47464698" : "#24242498"}
          >
            {message?.message && message?.message}
          </Text>
          <Flex direction={"column"} mt="10px">
            {message?.image && (
              <Image
                alt="file"
                maxWidth="200px"
                maxHeight="200px"
                src={message?.image}
                borderRadius={"5px"}
              />
            )}
            {/* <Text fontSize="12px" color="#707070">
            {message?.senderId?._id === state?.userInfo?._id
              ? message?.senderId?.fullName
              : message?.recipientId?.fullName}
          </Text>{" "} */}
            {/* <span>-</span> */}
            <Text fontSize="12px" color="#707070">
              {dayjs(message?.createdAt).format("hh:mm a")}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default MessageCard;
