import { Box, Table } from "@chakra-ui/react";
import { responsePageTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import ResponsePageTableBody from "./ResponsePageTableBody";

const ResponsePageTable = ({ data }) => {
  return (
    <Box width="100%" overflowX={["scroll"]} className="table-container">
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
      >
        <TableHead tableHeadData={responsePageTableHead} />
        <ResponsePageTableBody data={data} />
      </Table>
    </Box>
  );
};

export default ResponsePageTable;
