import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { FaTimes, FaUpload } from "react-icons/fa";
import { formatToNaira } from "../../../utils/numberFormat";

function ResponseListModal({ datum }) {
  return (
    <Box>
      <Flex flexDirection="row" justify="space-between" alignItems="center">
        <Text fontWeight="bold">Vendor:</Text>
        <Text>{datum?.name}</Text>
      </Flex>
      <Flex
        flexDirection="row"
        justify="space-between"
        mt="15px"
        alignItems="center"
      >
        <Text fontWeight="bold">Phone Number:</Text>
        <Text>{datum?.phoneNumber}</Text>
      </Flex>
      <Flex
        flexDirection="row"
        justify="space-between"
        mt="15px"
        alignItems="center"
      >
        <Text fontWeight="bold">Category:</Text>
        <Text>{datum?.category?.name}</Text>
      </Flex>
      <Flex
        flexDirection="row"
        justify="space-between"
        mt="15px"
        alignItems="center"
      >
        <Text fontWeight="bold">Amount:</Text>
        <Text>{formatToNaira(datum?.costAmount)}</Text>
      </Flex>
      <Flex flexDirection="column" mt="15px">
        <Text fontWeight="bold">Response Message:</Text>
        <Text>{datum?.responseNote}</Text>
      </Flex>
      <Flex
        justifyContent="space-between"
        mt="15px"
        fontWeight="bold"
        alignItems="center"
      >
        <Text color="#830f0f">Withholding Tax:</Text>
        <Text>
          {datum?.withHoldingTax ? (
            <BsCheckLg color={"#3C7B79"} />
          ) : (
            <FaTimes color="#830f0f" />
          )}
        </Text>
      </Flex>
      <Box mt="15px">
        <Text color="#393838" pb="15px" fontWeight="bold">
          Uploaded File:
        </Text>
        <Box
          borderRadius="7px"
          bg={
            datum?.file
              ? `url(${datum?.file}) center/cover no-repeat`
              : "transparent"
          }
          boxShadow="md"
          width="80%"
          height="120px"
          cursor="pointer"
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {datum.file ? null : <FaUpload size={30} color="#3C7B79" />}
        </Box>
      </Box>
    </Box>
  );
}

export default ResponseListModal;
