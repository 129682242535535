import React from "react";
import { Tbody, Tr, Td, Tooltip, HStack } from "@chakra-ui/react";
import { formatToNaira } from "../../../utils/numberFormat";
import { AiOutlineEye } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import CustomModal from "../../CustomModal";
import ResponseListModal from "./ResponseListModal";

const RequestListTable = ({ data }) => {

  return (
      <Tbody>
        {data?.map((datum) => (
          <Tr key={datum?._id} fontSize={"16px"}>
            <Tooltip label={datum?.Requester}>
              <Td
                isTruncated
                maxWidth="170px !important"
                borderRight="1px solid #dadedf !important"
              >
                {datum?.name}
              </Td>
            </Tooltip>
            <Tooltip label={datum?.phoneNumber}>
              <Td
                isTruncated
                maxWidth="170px !important"
                borderRight="1px solid #dadedf !important"
              >
                {datum?.phoneNumber}
              </Td>
            </Tooltip>
            <Tooltip label={datum?.Category}>
              <Td
                isTruncated
                maxWidth="170px !important"
                borderRight="1px solid #dadedf !important"
              >
                {datum?.category?.name}
              </Td>
            </Tooltip>
            <Tooltip label={datum?.Amount}>
              <Td
                isTruncated
                maxWidth="170px !important"
                borderRight="1px solid #dadedf !important"
              >
                {formatToNaira(datum?.costAmount)}
              </Td>
            </Tooltip>
            
            <Td>
              <HStack spacing={"20px"}>
                <CustomModal
                  icon={<AiOutlineEye size="18px" color={"#154141"} />}
                  header={`Vendors' Response`}
                  maxH={"500px"}
                  overflow={"scroll"}
                >
                  <ResponseListModal datum={datum}/>
                </CustomModal>
                <FiDownload size="18px" color={"#154141"} />
              </HStack>
            </Td>
          </Tr>
        ))}
      </Tbody>

  );
};

export default RequestListTable;
