import {
  Divider,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { MdUploadFile } from "react-icons/md";
import { FileInput } from "./FileInput";
import sampleFile from "../assets/bulkSample.png";
import csvSample from "../assets/sampleCSV.csv";
import { CustomBtn } from "./CustomBtn";
import { FaFileCsv } from "react-icons/fa";

export default function BulkUpload({ setInputFile, modalName, bg }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  return (
    <>
      <CustomBtn
        childComp={<MdUploadFile />}
        // width={["90%", "90%", "90%", "90%", "100%"]}
        text="Bulk Upload"
        bg="#fff"
        color="#3C7B79"
        handleClick={onOpen}
      />
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Bulk Upload</ModalHeader>
          <Text px="25px" color="#3C7B79">
            See sample below(CSV only)
          </Text>
          <ModalCloseButton />
          <Divider mb="3px" />
          <ModalBody mb="10px">
            <Image sizes="md" src={sampleFile} alt="bulk contact sample" />
          </ModalBody>

          <ModalFooter>
            <Flex width="100%" justify="space-between" align={"center"}>
              <a href={csvSample} download="csv sample">
                <Text color="#3C7B79" fontSize="12px">
                  Click on the image below to download template:
                </Text>
                <FaFileCsv size={24} color="#3C7B79" />
              </a>
              <FileInput setInputFile={setInputFile} />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
