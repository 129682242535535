import { Box, Flex, FormLabel, Select } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomBtn } from "../CustomBtn";
import { FormInputs } from "../FormInput";
import AddRequestModalDateField from "./AddRequestQuoteDate";
import { createQuotation } from "../../services/quotation";
import { formatToISODate } from "../../utils/formatToISODate";
import { useGetState } from "../../contexts/useGetState";

const AddRequestQuoteModal = ({ categories, setRefresh, onClose }) => {
  const { state } = useGetState();
  console.log(state);
  const [loading, setLoading] = useState(false);
  const [formValues, setformValues] = useState({
    date: "",
    time: "",
    category: "",
    requestNote: "",
    companyId: state?.companyDetails?.[0]?._id,
    deadline: "",
    title: "",
    item: "",
  });

  const itemsInCategory = categories.find(
    (category) => category._id === formValues.category
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      ...formValues,
      deadline: formatToISODate(formValues.date, formValues.time),
    };
    await createQuotation(payload, setLoading, setRefresh, onClose);
  };

  return (
    <Box width="100%">
      <FormLabel>Vendor's Category</FormLabel>
      <Select
        mb="20px"
        h="45px"
        placeholder="Choose Category"
        name="category"
        value={formValues.category}
        onChange={handleChange}
      >
        {categories.map((category) => (
          <option value={category?._id}>{category?.name}</option>
        ))}
      </Select>
      <Select
        mb="20px"
        h="45px"
        placeholder="Choose Item"
        name="item"
        value={formValues.item}
        onChange={handleChange}
      >
        {itemsInCategory?.items?.map((item) => (
          <option value={item?._id}>{item?.item}</option>
        ))}
      </Select>
      <FormInputs
        type="text"
        name="title"
        label="Request Title"
        value={formValues.title}
        handleChange={handleChange}
      />
      <Box my="20px">
        <FormLabel>Request Note</FormLabel>
        <Textarea
          placeholder="Type here"
          type="text"
          name="requestNote"
          value={formValues.requestNote}
          onChange={handleChange}
        />
      </Box>
      <Box>
        <FormLabel>Deadline</FormLabel>
        <AddRequestModalDateField
          date={formValues?.date}
          time={formValues?.time}
          handleChange={handleChange}
        />
      </Box>

      <Flex width="100%" justify="center" mt={"50px"}>
        <CustomBtn
          height="48px"
          text={"Send Request"}
          width="80%"
          loading={loading}
          handleClick={handleSubmit}
          disabled={
            !formValues.category ||
            !formValues.title ||
            !formValues.requestNote ||
            !formValues?.item
          }
        />
      </Flex>
    </Box>
  );
};

export default AddRequestQuoteModal;
