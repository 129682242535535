import { errorNotifier } from "../index";
import http, { AUTH_ROUTES } from "./api";

export const getPayoutTransactions = async (setTransactions, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PAYOUT_TRANSACTIONS);
    setTransactions(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  } finally {
    setLoading(false);
  }
};

export const getPayinTransactions = async (setTransactions, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_PAYIN_TRANSACTIONS);
    setTransactions(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  } finally {
    setLoading(false);
  }
};

export const searchTransactions = async (
  setAccount,
  keyword,
  setLoading,
  paymentFor
) => {
  try {
    const {
      data: { data },
    } = await http.get(
      AUTH_ROUTES.SEARCH_TRANSACTIONS_BY_KEYWORD(keyword, paymentFor)
    );
    setAccount(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    } else {
      console.log(e?.response);
      // errorNotifier(
      //   e.response?.data?.message || e.response?.data?.data?.message
      // );
    }
  } finally {
    setLoading(false);
  }
};

export const filterTransactions = async (
  setTransactions,
  setLoading,
  from,
  to
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.FILTER_TRANSACTIONS(from, to));
    setTransactions(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    } else {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    }
  } finally {
    setLoading(false);
  }
};

export const getTransactionsByDepartment = async (
  setTransactions,
  deptId,
  setLoading
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_TRANSACTIONS_BY_DEPARTMENT(deptId));
    setTransactions(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  } finally {
    setLoading(false);
  }
};

export const getTransactionsByStaffId = async (
  setTransactions,
  staffId,
  setLoading
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_TRANSACTIONS_BY_STAFFID(staffId));
    setTransactions(data);
  } catch (e) {
    console.log(e.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
