import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { CustomBtn } from "../components/CustomBtn";
import CustomModal from "../components/CustomModal";
import SearchField from "../components/SearchField";
import AccountTable from "../components/table/account/AccountTable";
import { getAccount, getAllBanks, searchAccount } from "../services/account";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import AddAccountModal from "../components/AddAccountModal";
import AddRequestQuoteModal from "../components/requestQoute/AddRequestQuoteModal";

import Notification from "../components/Notification";
import { getCategories } from "../services/category";
import CSVDownload from "../components/CSVDownload";
import { vendorCsvHeaders } from "../constants/csvHeaders.constants";
import { vendorsCsv } from "../utils/csvContents";

const AccountsPage = () => {
  const [allBanks, setAllBanks] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [filteredAccount, setFilteredAccount] = useState([]);
  const [categories, setCategories] = useState([]);
  const [, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const recordPerPage = 20;
  const indexOfLastRecord = currentPage * recordPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordPerPage;
  const accountData = filteredAccount.length > 0 ? filteredAccount : accounts;
  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const currentRecord = accountData?.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  useEffect(() => {
    getAccount(setAccounts, setLoading);
    getAllBanks(setAllBanks, setLoading);
    getCategories(setCategories, setLoading);
  }, [refresh]);

  const handleSearch = async (e) => {
    const keyword = e.target.value;
    if (!keyword) return;
    await searchAccount(
      setFilteredAccount,
      keyword,
      setSearchLoading,
      setError
    );
  };
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      {error && <Notification text="No Account Found" />}
      <Flex
        width="100%"
        justify={["start", "start", "space-between"]}
        alignItems={["start", "start", "start", "center"]}
        direction={["column", "column", "column", "row"]}
      >
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "20px", ""]}
        >
          Vendors
        </Heading>
        <Flex
          width={["100%", "100%", "100%", "70%"]}
          flexWrap={["wrap", "wrap", "nowrap"]}
          justify="space-between"
          gap={["15px", "15px", "30px"]}
          align={["center"]}
        >
          <Box width={["100%", "100%", "60%"]}>
            <SearchField
              placeholder="Account Name"
              handleSearch={handleSearch}
            />
          </Box>

          <Box width={["100%", "100%", "30%"]}>
            <CustomModal
              icon={
                <CustomBtn
                  width={["100%", "100%", null]}
                  text="Request Quote"
                  bg="transparent"
                  color
                />
              }
              header={`Request Quote`}
              iconColor={"#154141"}
            >
              {" "}
              <AddRequestQuoteModal
                data={allBanks}
                categories={categories}
                setRefresh={setRefresh}
              />
            </CustomModal>
          </Box>

          <Box width={["50%", "80%", "30%"]}>
            <CustomModal
              icon={
                <CustomBtn childComp={<AiOutlinePlus />} text="Add Vendor" />
              }
              header={`Add New Vendor`}
              iconColor={"#154141"}
              maxH="500px"
              overflow="scroll"
            >
              {" "}
              <AddAccountModal
                data={allBanks}
                categories={categories}
                setRefresh={setRefresh}
              />
            </CustomModal>
          </Box>
          <CSVDownload
            headers={vendorCsvHeaders}
            data={vendorsCsv(currentRecord)}
          />
        </Flex>
      </Flex>

      <AccountTable users={currentRecord} setRefresh={setRefresh} />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {currentRecord?.length} of {accounts?.length} Users
        </Text>
        <Pagination
          dataPerPage={recordPerPage}
          totalData={accounts?.length ?? 0}
          currentPage={currentPage}
          paginate={paginate}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default AccountsPage;
