import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";
import axiosInstance from "./formDataConfig";

export const getCoversations = async (setCoversations, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_CONVERSATIONS);
    setLoading(false);
    setCoversations(data);
    return data;
  } catch (e) {
    setLoading(false);
    // errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const getCoversationsById = async (
  conversationId,
  setCoversations,
  setLoading
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_CONVERSATION_BY_ID(conversationId));
    setLoading(false);
    setCoversations(data);
    return data;
  } catch (e) {
    setLoading(false);
    // errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const sendMessage = async (payload, setLoading, setRefresh) => {
  try {
    const {
      data: { data },
    } = await axiosInstance.post(AUTH_ROUTES.MESSAGE, payload);

    console.log("MESSAGE", data);
    setRefresh((prev) => !prev);
    return data;
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e.response?.data?.errors?.[0]?.msg || e.response?.data?.message
      );
    } else {
      errorNotifier("Network error, please check your internet connection");
    }
  } finally {
    setLoading(false);
  }
};
