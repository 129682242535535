import React from "react";
import { Box, Table } from "@chakra-ui/react";
import { staffTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import StaffTableBody from "./StaffTableBody";

const StaffTable = ({ users, department, setRefresh }) => {
  return (
    <Box className="table-container" overflowX="scroll" width={"100%"}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
        // width={["100% !important"]}
        // overflowX={["scroll"]}
      >
        <TableHead tableHeadData={staffTableHead} />
        <StaffTableBody
          data={users}
          department={department}
          setRefresh={setRefresh}
        />
      </Table>
    </Box>

    // </TableContainer>
  );
};

export default StaffTable;
