import React, { useState } from "react";
import { FormInputs } from "./FormInput";
import { Box, Text } from "@chakra-ui/react";

function FilterSearch({ data = [], label, setFormValues }) {
  const [search, setSearch] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);

    const filtered = data.filter((item) => {
      return item.accountName
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });

    setSearchResults(filtered);
  };

  const handleSelect = (event, res) => {
    setSearch(event.currentTarget.innerText);
    setFormValues((prev) => ({
      ...prev,
      bankName: res?.bankName,
      accountName: res?.accountName,
      accountNumber: res?.accountNumber,
      accountId: res?._id,
      category: res?.category?.name
    }));
    setSearchResults([]);
  };

  return (
    <div>
      <FormInputs
        type="text"
        label={label}
        value={search}
        handleChange={handleSearchChange}
      />
      {searchResults?.length > 0 && (
        <Box maxH="200px" overflowY={"scroll"}>
          {searchResults?.map((result) => (
            <Text
              px="5px"
              width="100%"
              height={"30px"}
              border="1px solid grey"
              key={result.id}
              cursor="pointer"
              onClick={(e) => handleSelect(e, result)}
            >
              {result?.accountName}
            </Text>
          ))}
        </Box>
      )}
    </div>
  );
}

export default FilterSearch;
