import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";
import axiosInstance from "./formDataConfig";

export const updateAccount = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_ACCOUNT(payload._id), payload);
    successNotifier("Account successfully updated");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e?.response?.data?.errors?.[0], "error");
    errorNotifier(
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    setLoading(false);
  }
};

export const updatePassword = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_PASSWORD, payload);
    successNotifier("Password successfully updated");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e?.response?.data?.errors?.[0], "error");
    errorNotifier(
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    setLoading(false);
  }
};

export const updateImage = async (payload, setLoading, setRefresh) => {
  try {
    await axiosInstance.post(AUTH_ROUTES.UPDATE_IMAGE, payload);
    successNotifier("Image successfully updated");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e?.response, "error");
    errorNotifier(e?.response?.data?.message || e?.response?.data?.errors?.msg);
    setLoading(false);
  }
};

export const updateCompanyDetails = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(
      AUTH_ROUTES.UPDATE_COMPANY_DETAILS(payload.companyId),
      payload
    );
    successNotifier("Spending limit successfully updated");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e?.response?.data?.errors?.[0], "error");
    errorNotifier(e?.response?.data?.message);
    setLoading(false);
  }
};

export const getCompanyDetails = async (setData, setLoading, companyId) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_COMPANY_DETAILS(companyId));
    setData(data);
    setLoading(false);
    return data;
  } catch (e) {
    console.log(e?.response?.data?.message, "error");
    setLoading(false);
  }
};

export const updateApprover = async (
  payload,
  setLoading,
  setRefresh,
  approverId
) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_APPROVER(approverId), payload);
    successNotifier("Updated Successfully");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e, "Approver error");
    errorNotifier(
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    setLoading(false);
  }
};

export const createPin = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_TRANSACTION_PIN, payload);
    successNotifier("Transaction Pin update created");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e?.response?.data?.errors?.[0], "error");
    errorNotifier(
      e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
    );
    setLoading(false);
  }
};
