import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Flex, Text } from "@chakra-ui/react";

const PayinPayoutChart = ({
  selectedOption,
  monthlyPayout,
  monthlyPayin,
  dailyPayin,
  dailyPayout,
}) => {
  if (monthlyPayin && monthlyPayout && dailyPayin && dailyPayout.length === 0) {
    return (
      <Flex justify={"center"} align={"center"} h={"400px"} bg="#ECECEE">
        <Text fontWeight={"semibold"} fontSize={"22px"}>
          No data available
        </Text>
      </Flex>
    );
  }

  const data = {
    labels:
      selectedOption === "daily"
        ? Object.keys(dailyPayin)
        : Object.keys(monthlyPayout),

    datasets: [
      {
        label: "Pay In",
        data:
          selectedOption === "daily"
            ? Object.values(dailyPayin)
            : Object.values(monthlyPayin),
        backgroundColor: "#6C87A3",
        borderWidth: 1,
      },
      {
        label: "Pay Out",
        data:
          selectedOption === "daily"
            ? Object.values(dailyPayout)
            : Object.values(monthlyPayout),
        backgroundColor: "#3C7B79",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}k`,
        },
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
  };

  return <Bar data={data} options={options} />;
};
export default PayinPayoutChart;
