import React from "react";
import { Box, Table } from "@chakra-ui/react";
import TableHead from "../TableHead";
import TransactionTableBody from "./TransactionTableBody";
import { dummyData } from "../../../dummyData";

const TransactionTable = ({ transactions, tableHeadData, payin, key }) => {
  return (
    <Box width="100%" overflowX={["scroll", "scroll", "hidden"]} key={key}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
      >
        <TableHead tableHeadData={tableHeadData} />
        <TransactionTableBody
          data={transactions}
          payin={payin}
          info={dummyData}
        />
      </Table>
    </Box>
  );
};

export default TransactionTable;
