export const overviewTaleHead = [
  "User Name",
  "Email Address",
  "Phone Number",
  "Registration Date",
  "Account Type",
  "Status",
  "Action",
];

export const transactionsPayoutTableHead = [
  "Departments",
  "Categories",
  "Amounts",
  "Date",
  "Status",
  "Action",
];

export const transactionsPayinTableHead = [
  // "Departments",
  // "Categories",
  "Amounts",
  "Date",
  "Status",
  "Action",
];

export const transactionsDetailsTableHead = [
  "Requester",
  // "Departments",
  "Categories",
  "Amounts",
  "Date",
  "Payment Type",
  "Status",
  "View Details",
];

export const staffTransactionsDetailsTableHead = [
  "Requester",
  "Departments",
  "Categories",
  "Amounts",
  "Date",
  "Payment Type",
  "Status",
];
export const requestTableHead = [
  "Sender Name",
  "Sending Date",
  "Amounts",
  "Department",
  "Status",
  "Action",
];
export const accountTableHead = [
  "Vendor's Name",
  "Email Address",
  "Phone Number",
  "Account Name",
  "Account Number",
  "Bank Name",
  "Vendor's Category",
  "Action",
];
export const schedulePaymentTableHead = [
  "Vendor's Name",
  "Account Name",
  "Account Number",
  "Bank Name",
  "Vendor's Categories",
  "Schedule Payment Date",
  "Paymeent Amount",
  "Action",
];
export const overviewTableHead = [
  "Sender",
  "Date",
  "Amount",
  "status",
  "Action",
];

export const staffTableHead = [
  "ID",
  "Name",
  "Email Address",
  "Phone Number",
  "Role",
  "Department",
  "Action",
];

export const departmentsTableHead = [
  "Departments",
  "Amount Limits",
  "Spending Limits",
  "Action",
];

export const loanRepaymentsTableHead = [
  "Borrowers Name",
  "Loan Amount Received",
  "Disbursed Date",
  "Due Date",
  "Lender Receiving Payment",
  "Loan Interest",
  "Total Repayment Amount",
  "Amount Returned out of Loan",
  "Status",
  "Action",
];

export const categoriesTableHead = ["Categories","Items", "Action"];

export const borrowersTabTitles = [
  "All Status",
  "Active",
  "Unverified",
  "Suspended",
];
export const loanReportsTabTitles = ["All Status", "Active", "Pending", "Due"];
export const loanRepaymentsTabTitles = [
  "All Status",
  "Completed",
  "Pending",
  "Due",
];
export const responsePageTableHead = [
  "Admin",
  "Response Title",
  "Categories",
  "Items",
  "Date Initiated",
  "Expiry Date",
  "Total Vendors Sent To",
  "Total Vendor Response",
  "Action",
];
export const requestQuoteTableHead = [
  "Vendor",
  "Phone Number",
  "Category",
  "Amount",
  "Action",
];
