import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getSchedule = async (setSchedule, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SCHEDULE);
    setSchedule(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  } finally {
    setLoading(false);
  }
};

export const searchSchedule = async (
  setSchedule,
  keyword,
  setLoading,
  setNotFoundError
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_SCHEDULE(keyword));
    setSchedule(data);
    console.log(data);
  } catch (e) {
    setNotFoundError((prev) => !prev);
  } finally {
    setLoading(false);
  }
};

export const createSchedule = async (
  payload,
  setLoading,
  setRefresh,
  onClose
) => {
  try {
    await http.post(AUTH_ROUTES.SCHEDULE, payload);
    successNotifier("Schedule Created Successfully");
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
   if(e.response) {
     errorNotifier(
       e.response?.data?.errors?.msg || e.response?.data?.data?.message
     );
   } else {
    errorNotifier("Network error, please check your internet connections")
   }
  } finally {
    setLoading(false);
  }
};

export const updateSchedule = async (
  payload,
  setLoading,
  setRefresh,
  onClose
) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_SCHEDULE(payload?.scheduleId), payload);
    successNotifier("Schedule Updated Successfully");
    onClose();
    setRefresh((prev) => !prev);
  } catch (e) {
   
     if (e.response) {
       if (e.response?.data?.message === "Unable to verify token.") {
         errorNotifier("Session timed out");
         window.location.reload();
       }
       errorNotifier(
         e.response?.data?.errors?.[0]?.msg || e.response?.data?.data?.message
       );
     } else {
       errorNotifier("Network error, please check your internet connections");
     }
  } finally {
    setLoading(false);
  }
};

export const deleteSchedule = async (scheduleId, setLoading, setRefresh) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_SCHEDULE(scheduleId));
    successNotifier("Department Deleted Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    if (e.response) {
      if (e.response?.data?.message === "Unable to verify token.") {
        errorNotifier("Session timed out");
        window.location.reload();
      }
      errorNotifier(
        e.response?.data?.errors?.[0]?.msg || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Network error, please check your internet connections");
    }
  } finally {
    setLoading(false);
  }
};
