import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Input, Text } from "@chakra-ui/react";

import { IoArrowBackOutline } from "react-icons/io5";
import { sendOtp } from "../../services/login";
import { CustomBtn } from "../CustomBtn";

const SendTPComponent = ({ email, setNavigateTo, setEmail }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const handleReset = async () => {
    setLoading(true);
    const payload = {
      type: "email",
      userDetail: email,
      length: "4",
    };
    console.log(payload);
    await sendOtp(payload, setLoading, setNavigateTo);
  };
  return (
    <Box
      p={["20px", "20px", "50px"]}
      bg="#fff"
      textAlign={"center"}
      width={["80%", "80%", "60%", "40%"]}
      borderRadius={"10px"}
      mt="20px"
    >
      <IoArrowBackOutline onClick={() => navigate("/")} />
      <Heading as="h2" fontSize={"22px"} mb="5px">
        Reset Password!
      </Heading>
      <Text maxW={"370px"} fontSize="18px" ml="30px">
        Kindly fill in your Email Address
      </Text>
      <Box mt="40px">
        <Input
          mb="40px"
          height={"45px"}
          width="100%"
          placeholder="Enter Email Address"
          border="1px solid #3C7B79B8"
          type={"email"}
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <CustomBtn
          width={["200px", "200px", "300px"]}
          text={"Get OTP"}
          handleClick={handleReset}
          loading={loading}
          disabled={!email}
        />
      </Box>
    </Box>
  );
};

export default SendTPComponent;
