import { Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BoxShadowContainer from "./BoxShadowContainer";
import { VendorMonthlyTab } from "./VendorMonthlyTab";
import { VendorDailyTab } from "./VendorDailyTab";

export const ChartRowVendor = ({ annualPayoutToVendors, monthlyPayoutToVendors }) => {
  const [period, setPeriod] = useState("monthly");
  const [itemColors, setItemColors] = useState([]);

  useEffect(() => {
    const colors = monthlyPayoutToVendors.map(() => getRandomColor());
    setItemColors(colors);
  }, [monthlyPayoutToVendors]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <Flex
      justify={"space-between"}
      direction={["column", "column", "column", "row"]}
    >
      <BoxShadowContainer
        w={["100%", "100%", "100%", "48%"]}
        h={"350px"}
        overflowY={["scroll", "scroll", "hidden"]}
      >
        <Flex
          align={"center"}
          justify={"space-between"}
          direction={["column", "column", "row"]}
        >
          <Heading fontSize={"25px"}>Payouts to Vendors</Heading>
          <Flex gap={["6rem", "6rem", "2rem"]} pt={["10px", "10px", "0px"]}>
            <Text
              fontWeight="semibold"
              cursor="pointer"
              borderBottom={period === "monthly" && "3px solid #3C7B79"}
              color={period === "monthly" ? "#3C7B79" : "#A0A0A0"}
              onClick={() => setPeriod("monthly")}
            >
              Monthly
            </Text>
            <Text
              fontWeight="semibold"
              cursor="pointer"
              borderBottom={period === "daily" && "3px solid #3C7B79"}
              color={period === "daily" ? "#3C7B79" : "#A0A0A0"}
              onClick={() => setPeriod("daily")}
            >
              Daily
            </Text>
          </Flex>
        </Flex>
        {period === "daily" ? (
          <VendorDailyTab
            annualPayoutToVendors={annualPayoutToVendors}
            itemColors={itemColors}
          />
        ) : (
          <VendorMonthlyTab
            monthlyPayoutToVendors={monthlyPayoutToVendors}
            itemColors={itemColors}
          />
        )}
      </BoxShadowContainer>
    </Flex>
  );
};
