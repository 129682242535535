import { Box, Table } from "@chakra-ui/react";
import React from "react";
import TableHead from "../TableHead";
import { schedulePaymentTableHead } from "../../../table.constants";
import SchedulePaymentBody from "./SchedulePaymentBody";


const SchedulePaymentTable = ( { users, categories, setRefresh}) => {
  return (
    <Box width="100%" overflowX="scroll" className="table-container">
      <Table
        width={"100%"}
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
      >
        <TableHead tableHeadData={schedulePaymentTableHead} />
        <SchedulePaymentBody 
        data={users} 
        categories={categories}
        setRefresh={setRefresh}
        />
      </Table>
    </Box>
  );
};

export default SchedulePaymentTable;
