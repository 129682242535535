import React from "react";
import { Input, Flex } from "@chakra-ui/react";

const AddRequestModalDateField = ({ date, time, handleChange }) => {
  return (
    <Flex w={"100%"} justifyContent={"space-between"}>
      <Input
        type="date"
        w="45%"
        name="date"
        value={date}
        onChange={handleChange}
      />
      <Input
        type="time"
        w="45%"
        name="time"
        value={time}
        onChange={handleChange}
      />
    </Flex>
  );
};

export default AddRequestModalDateField;
