import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  //Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import FullPageLoader from "./fullPageLoader";
import { Flex, Text } from "@chakra-ui/react";

//ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  //Title,
  Tooltip,
  Filler,
  Legend
);

// const options = {
//   responsive: true,
//   plugins: {
//     legend: true,
//   },
//   scales: {
//     x: {
//       grid: {
//         display: true,
//       },
//     },
//     y: {
//       ticks: {
//         stepSize: 10,
//       },
//     },
//   },
// };

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    y: {
      ticks: {
        stepSize: 10,
      },
    },
  },
};

export function LineChart({ xAxisData, yAxisData }) {
  if (!xAxisData || xAxisData === 0) {
    return (
      <Flex justify={"center"} align={"center"} h={"400px"} bg="#ECECEE">
        <Text fontWeight={"semibold"} fontSize={"22px"}>
          No data available
        </Text>
      </Flex>
    );
  }
  const data = {
    labels: xAxisData,
    datasets: [
      // {
      //   //label: 'Finance Loaned Out',
      //   data: [0, 10, 20, 30, 40, 50, 100],
      //   backgroundColor: '#2F1267',
      //   borderColor: '#2F1267',
      //   borderWidth: 1.5,
      //   pointBorderColor: '#2F1267',
      //   pointBorderWidth: 4,
      //   tension: 0.8,
      // },
      {
        //label: 'Due Repayment                        : From 1st of November till date',
        label: "Amount",
        data: yAxisData,
        fill: true,
        backgroundColor: "#C7DBDB",
        borderColor: "#70A19F",
        borderWidth: 1.5,
        pointBorderColor: "#70A19F",
        pointBorderWidth: 4,
        tension: 1,
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}
