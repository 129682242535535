import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getAccount = async (setAccount, setLoading) => {
  try {
    const {
      data: { account },
    } = await http.get(AUTH_ROUTES.ACCOUNT);
    setAccount(account);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const searchAccount = async (setAccount, keyword, setLoading, setError) => {
  try {
    const data = await http.get(AUTH_ROUTES.SEARCH_ACCOUNT(keyword));
    setAccount(data?.data?.account);
    data?.data?.account.length < 1 && setError(true)
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const createAccount =
  (payload, setLoading, setRefresh) => async (onClose) => {
    try {
      await http.post(AUTH_ROUTES.ACCOUNT, payload);
      successNotifier("Account successfully added");
      setLoading(false);
      setRefresh((prev) => !prev);
      onClose();
    } catch (e) {
      console.log(e?.response?.data?.errors?.[0], "error");
      errorNotifier(
        e?.response?.data?.errors?.[0]?.msg || e?.response?.data?.errors?.msg
      );
      setLoading(false);
    }
  };

export const deleteAccount = async (accountId, setLoading, setRefresh) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_ACCOUNT(accountId));
    successNotifier("Account Deleted Successfully");
    setLoading(false);
    setRefresh((prev) => !prev);
  } catch (e) {
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
    //for users that have not verified their email
  } finally {
    setLoading(false);
  }
};

export const getAllBanks = async (setAllBanks, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_ALL_BANKS);
    setAllBanks(data);
  } catch (e) {
    console.log(e.response?.data?.message);
  } finally {
    setLoading(false);
  }
};
