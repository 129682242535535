import React, { useState } from "react";
import VerifyOtp from "../components/resetPassword/VerifyOTP";
import ConfirmPassowrd from "../components/resetPassword/ConfirmPassword";

import SendTPComponent from "../components/resetPassword/SendOTPComponent";
import AuthFlowWrapper from "../components/AuthFlowWrapper";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [navigateTo, setNavigateTo] = useState("");

  return (
    <AuthFlowWrapper>
      {navigateTo === "sendOTP" ? (
        <VerifyOtp userDetail={email} setNavigateTo={setNavigateTo} />
      ) : navigateTo === "verifyOTP" ? (
        <ConfirmPassowrd userDetails={email} />
      ) : (
        <SendTPComponent
          email={email}
          setNavigateTo={setNavigateTo}
          setEmail={setEmail}
        />
      )}
    </AuthFlowWrapper>
  );
};

export default ResetPassword;
