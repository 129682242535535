import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ChatFeed from "../components/messages/ChatFeed";
import FriendsList from "../components/messages/FriendsList";
import { getCoversations, getCoversationsById } from "../services/messages";
import FullPageLoader from "../components/fullPageLoader";
import { socketClient } from "../services/socket";
import { useGetState } from "../contexts/useGetState";
import { getStaff } from "../services/staff";
import notification from "../assets/audio/notification.wav";

const MessagesPage = () => {
  const { state } = useGetState();
  const [conversations, setConversations] = useState([]);
  const [staff, setStaff] = useState([]);
  const [recipientDetails, setRecipientDetails] = useState({});
  const [conversationsById, setConversationsById] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [chatBoxRef, setChatBoxRef] = useState(null);
  const [showChatFeed, setShowChatFeed] = useState(() =>
    window.innerWidth >= 600 ? true : false
  );
  const [showFriendsList, setShowFriendsList] = useState(true);
  const [addToChatList, setAddToChatList] = useState("conversations");
  const userId = state?.userInfo?._id;

  let messagesEnd = useRef(null);

  const scrollToBottom = () => {
    messagesEnd?.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const foundConversations = conversations.find(
    (conversation) =>
      conversation?.entityOneId?._id === recipientDetails?._id ||
      conversation?.entityTwoId?._id === recipientDetails?._id
  );

  const conversationId = foundConversations?._id || recipientDetails?._id;

  const [updateScroll, setUpdateScroll] = useState({});
  useEffect(() => {
    scrollToBottom(0, 0);
    //eslint-disable-next-line
  }, [chatBoxRef, updateScroll]);

  const getMessages = useCallback(() => {
    if (!conversationId) {
      return;
    }
    // setLoading(true);
    getCoversationsById(conversationId, setConversationsById, setLoading);
  }, [conversationId]);

  useEffect(() => {
    // connect(userId);
    getStaff(setStaff, setLoading);
    getCoversations(setConversations, setLoading);
    setConversationsById([]);
    getMessages();
    socketClient.on("private-message", (data) => {
      // console.log("CONVERSEee", data);
      getCoversations(setConversations, setLoading);
      setUpdateScroll({}); //scroll down when new message is received
      getMessages();
 
      const audioElement = new Audio(notification);
      audioElement.play();
    });
    return () => {
      socketClient.removeAllListeners("private-message");
      // socketClient.removeAllListeners("attachment");
    };
    //eslint-disable-next-line
  }, [userId, getMessages, refresh]);
  useEffect(() => {
    if (chatBoxRef) {
      chatBoxRef.scrollTop = chatBoxRef.scrollHeight;
    }
  }, [conversationsById, chatBoxRef]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Heading as="h2" fontSize="30px" mb="30px">
        Messages
      </Heading>
      <Flex gap="10px">
        {showFriendsList && (
          <FriendsList
            staff={staff}
            setRefresh={setRefresh}
            addToChatList={addToChatList}
            setAddToChatList={setAddToChatList}
            conversations={conversations}
            foundConversations={foundConversations}
            recipientDetails={recipientDetails}
            setRecipientDetails={setRecipientDetails}
            setConversations={setConversations}
            setConversationsById={setConversationsById}
            setShowChatFeed={setShowChatFeed}
            showChatFeed={showChatFeed}
            setShowFriendsList={setShowFriendsList}
            showFriendsList={showFriendsList}
            conversationsById={conversationsById}
          />
        )}
        {showChatFeed && (
          <ChatFeed
            setRefresh={setRefresh}
            setChatBoxRef={setChatBoxRef}
            recipientDetails={recipientDetails}
            foundConversations={foundConversations}
            setAddToChatList={setAddToChatList}
            addToChatList={addToChatList}
            conversationsById={conversationsById}
            setConversations={setConversationsById}
            setShowChatFeed={setShowChatFeed}
            showChatFeed={showChatFeed}
            setShowFriendsList={setShowFriendsList}
          />
        )}
      </Flex>
    </Box>
  );
};

export default MessagesPage;
