import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { DailyVendorChart } from "./charts/DailyVendorChart";

export const VendorDailyTab = ({ annualPayoutToVendors = [], itemColors }) => {
  const reFormatDailyCategoryExpense = annualPayoutToVendors?.map((data) => ({
    ...data,
    color: itemColors,
  }));
  return (
    <Box>
      <Flex
        align={"center"}
        justify={["center", "center", "space-between"]}
        w={"100%"}
        direction={["column", "column", "row"]}
      >
        <Box>
          <DailyVendorChart
            annualPayoutToVendors={reFormatDailyCategoryExpense}
            itemColors={itemColors}
          />
        </Box>
        <Box fontWeight={400} w={["50%", "50%", "40%"]}>
          {reFormatDailyCategoryExpense?.map((data, index) => {
            return (
              <Flex align={"center"} pb={"10px"} key={index}>
                <Box
                  h="11px"
                  w="11px"
                  rounded={"sm"}
                  bg={itemColors[index]}
                  mr="10px"
                ></Box>

                <Text>{data?.accountName}</Text>
              </Flex>
            );
          })}
        </Box>
      </Flex>
    </Box>
  );
};
