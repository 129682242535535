import { Avatar, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import { useGetState } from "../../contexts/useGetState";
import dayjs from "dayjs";

const FriendsCard = ({
  data,
  setRecipientDetails,
  foundConversations,
  // recipientDetails,
  // setConversations,
  // setConversationsById,
  showChatFeed,
  setShowChatFeed,
  setAddToChatList,
  showFriendsList,
  setShowFriendsList,
  setRefresh,
  conversationsById
}) => {
  const { state } = useGetState();

  // console.log((conversationsById, "CONVERSATIONS ID"));
  const friend =
    data?.entityOneId?._id === state?.userInfo?._id
      ? data?.entityTwoId
      : data?.entityOneId;

  return (
    <Flex
      mb="2px"
      p="10px 20px"
      fontSize={"14px"}
      bg={"#fff"}
      gap="10px"
      onClick={() => {
        setRecipientDetails(data);
        // setRefresh((prev) => !prev);
        setAddToChatList("conversations");
        if (window.innerWidth <= 600) {
          setShowChatFeed(!showChatFeed);
          setShowFriendsList(!showFriendsList);
        }
      }}
      _hover={{
        bg: "lightblue",
      }}
    >
      <Avatar
        width="38px"
        height="38px"
        src={friend?.image || data?.image}
        name={friend?.fullName || data?.fullName}
        alt={`Avatar of ${friend?.fullName || data?.fullName}`}
      />
      <Box>
        <Text width="170px" isTruncated color="#242424">
          {friend?.fullName || data?.fullName}
        </Text>
        <Tooltip label="Historically, philosophy encompassed...">
          <Text width="170px" isTruncated color="#707070">
            {friend?.email || data?.email}
          </Text>
        </Tooltip>
      </Box>
      <Text color="#707070" fontSize={"12px"}>
        {dayjs(data?.updatedAt).format("hh:mm a")}
        {/* {data?.new} */}
      </Text>
    </Flex>
  );
};

export default FriendsCard;
