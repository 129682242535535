import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ResetPassword from "../pages/ResetPassword";
import LoginPage from "../pages/LoginPage";

function UnAuthenticatedRoutes(props) {

  return (
    <Suspense fallback={<p></p>}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="/*" element={<LoginPage />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
}

export default UnAuthenticatedRoutes;
