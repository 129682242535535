import React, { useState } from "react";
import { Box, Text, List, ListItem, Flex, Divider } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import CustomModal from "../../CustomModal";
import DeleteItem from "../../DeleteItem";

function CategoryDetailsModal({ data }) {

  return (
    <Box>
      <Text fontWeight="bold" fontSize="20px" mb={4}>
        Category Name: {data?.name}
      </Text>
      <Text fontSize="18px" fontWeight="bold" mb={2}>
        Items:
      </Text>
      <Divider mb="10px" />
      <List>
        {data?.items?.map((item, index) => (
          <ListItem key={index}>
            <Flex justify="space-between" align="center" width="100%" mb={2}>
              <Text fontSize={["14px", "14px", "18px"]}>{item?.item}</Text>
              <CustomModal
                header="Delete Item"
                icon={<AiOutlineClose cursor="pointer" color="red" size={22} />}
              >
                <DeleteItem data={data} itemId={item?._id} />
              </CustomModal>
            </Flex>
            <Divider mb="10px" />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default CategoryDetailsModal;
