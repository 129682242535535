import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const PaystackModal = ({
  paymentModal,
  setPaymentModal,
  paymentData,
  setRefresh,
}) => {
  return (
    <Modal
      isOpen={paymentModal}
      onClose={() => {
        setRefresh((prev) => !prev);
        setPaymentModal(false);
      }}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payment checkout</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: "none" }} />
        <ModalBody>
          <iframe
            src={paymentData?.data?.authorizationUrl}
            frameBorder="0"
            width="100%"
            height="450"
            title="payment checkout"
          ></iframe>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PaystackModal;
