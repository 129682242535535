
import { Tbody, Tr, Td, Tooltip, HStack} from "@chakra-ui/react";
import {AiOutlineEye} from "react-icons/ai";
import CustomModal from "../../CustomModal";
import RespondDetailModal from './RespondDetailModal';
import dayjs from 'dayjs';

const ResponsePageTableBody = ({ data }) => {
  //  console.log(data, "ResponseData")
  return (
    <Tbody>
      {data?.map((datum, key) => (
        <Tr key={key} fontSize={"16px"}>
          <Tooltip
            label={datum?.userId?.fullName || datum?.admin?.[0]?.fullName}
          >
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.userId?.fullName || datum?.admin?.[0]?.fullName}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.title}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.title}
            </Td>
          </Tooltip>
          <Tooltip
            label={datum?.category?.name || datum?.categoryInfo?.name}
          >
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.category?.name || datum?.categoryInfo?.name}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.item}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.item}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.createdAt}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {dayjs(datum?.userId?.createdAt).format("DD/MM/YYYY")}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.deadline}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {dayjs(datum?.deadline).format("DD/MM/YYYY")}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.vendorsSentTo}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.vendorsSentTo}
            </Td>
          </Tooltip>
          <Tooltip
            label={
              datum?.vendorResponse?.length >= 0
                ? datum?.vendorResponse?.length
                : 0
            }
          >
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {
                /* {datum?.vendorResponse?.length >= 0
                ? datum?.vendorResponse?.length
                : 0} */
                datum?.vendorResponse?.length
              }
            </Td>
          </Tooltip>

          <Td>
            <HStack spacing={"20px"} justify={"center"}>
              <CustomModal
                icon={<AiOutlineEye size="18px" color={"#154141"} />}
                header={`Request Quotes`}
                maxH={"500px"}
                overflow={"scroll"}
                data={datum}
              >
                <RespondDetailModal data={datum} />
              </CustomModal>
              {/*<FiDownload size="18px" color={"#154141"} />*/}
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
}

export default ResponsePageTableBody
