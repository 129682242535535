import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullPageLoader from "../components/fullPageLoader";
import SearchField from "../components/SearchField";
import {
  getTransactionsByDepartment,
  searchTransactions,
} from "../services/transactions";
import TransactionDetailsTable from "../components/table/transaction/TransactionDetailsTable";

const TransactionDetailsPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [searchedTransactions, setSearchedTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setSearchLoading] = useState(false);
  const { id } = useParams();

  const transactionsData =
    searchedTransactions.length > 0 ? searchedTransactions : transactions;

  useEffect(() => {
    getTransactionsByDepartment(setTransactions, id, setLoading);
  }, [id]);

  const handleSearch = async (e) => {
    const keyword = e.target.value;
    await searchTransactions(
      setSearchedTransactions,
      keyword,
      setSearchLoading,
      "payOut"
    );
  };
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Flex width="100%" justify="space-between" alignItems="center" mb="30px">
        <Heading as="h2" fontSize="26">
          Transaction History for {transactions?.[0]?.departmentId?.department}{" "}
          Department
        </Heading>
        <Flex width="40%" justify="space-between" align="center">
          <SearchField placeholder="" handleSearch={handleSearch} />
        </Flex>
      </Flex>
      <TransactionDetailsTable
        transactions={transactionsData}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default TransactionDetailsPage;
