import React from "react";
import { Flex, Box, Heading } from "@chakra-ui/react";
import DateField from "./DateField";
import SearchField from "./SearchField";
import CSVDownload from "./CSVDownload";
import { transactionCsvHeaders } from "../constants/csvHeaders.constants";

import { transactionCsv } from "../utils/csvContents";

const HeaderSection = ({
  header,
  endDate,
  startDate,
  setEndDate,
  setStartDate,
  placeholder,
  handleSearch,
  handleSearchByDate,
  transactionsData,
}) => {
  return (
    <Flex
      width="100%"
      direction={["column", "column", "row"]}
      justify="space-between"
      align="center"
      bg={"inherit"}
      borderRadius={"10px"}
      p={["", "", "20px"]}
      mb="20px"
    >
      <Box width={["100%", "100%", "100%", "23%"]} mr={"20px"}>
        <Heading as="h2" fontSize={["22px", "22px", "28px", "30px"]}>
          {header}
        </Heading>
      </Box>
      <Flex
        width={["100%", "100%", "100%", "100%"]}
        justify="space-between"
        direction={["column", "column", "row"]}
         align={"center"}
      >
        <Box width={["100%", "100%", "40%"]} my={["20px", "20px", ""]}>
          <SearchField placeholder={placeholder} handleSearch={handleSearch} />
        </Box>
        <Flex
          justify={"space-between"}
          align="center"
          width={["100%", "100%", "100%", "50%", "47%"]}
        >
          <DateField
            width={"49%"}
            text={"From"}
            date={startDate}
            setDate={setStartDate}
          />
          <DateField
            width={"44%"}
            text={"To"}
            endDate={endDate}
            setDate={setEndDate}
            handleSearch={handleSearchByDate}
          />
        </Flex>
        <CSVDownload
          headers={transactionCsvHeaders}
          data={transactionCsv(transactionsData)}
        />
      </Flex>
    </Flex>
  );
};

export default HeaderSection;
