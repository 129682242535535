import {
  AiOutlineBlock,
  AiOutlineSchedule,
  AiOutlineSetting,
} from "react-icons/ai";
import { RiDashboardLine } from "react-icons/ri";
import {
  BsBarChartLine,
  BsCashCoin,
  BsPeople,
  BsPersonSquare,
} from "react-icons/bs";
import { BiMessageDetail } from "react-icons/bi";
import { GiNuclear } from "react-icons/gi";
import { MdOutlineContactSupport } from "react-icons/md";
import { VscFeedback } from "react-icons/vsc";
import { TbReportAnalytics } from "react-icons/tb";

export const navList = [
  {
    text: "Overview",
    link: "/",
    icon: RiDashboardLine,
  },
  {
    text: "Departments",
    link: "/departments",
    icon: BsPersonSquare,
  },

  {
    text: "Staff",
    link: "/staff",
    icon: BsPeople,
  },

  {
    text: "Categories",
    link: "/categories",
    icon: AiOutlineBlock,
  },
  {
    text: "Messages",
    link: "/messages",
    icon: BiMessageDetail,
  },
  {
    text: "Requests",
    link: "/requests",
    icon: GiNuclear,
  },
  {
    text: "Vendors",
    link: "/vendors",
    icon: BsBarChartLine,
  },
  {
    text: "Request Quotes",
    link: "/request-quotes",
    icon: VscFeedback,
  },
  {
    text: "Schedule Payments",
    link: "/payments-schedule",
    icon: AiOutlineSchedule,
  },
  {
    text: "Transactions",
    link: "/transactions",
    icon: BsCashCoin,
  },

  {
    text: "Account Settings",
    link: "/account-settings",
    icon: AiOutlineSetting,
  },
  {
    text: "Analytics",
    link: "/analytics",
    icon: TbReportAnalytics,
  },
  {
    text: "Support",
    link: "/support",
    icon: MdOutlineContactSupport,
  },
];
