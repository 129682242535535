import { Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import CustomModal from "../../CustomModal";
import DeleteModal from "../../DeleteModal";
import { deleteAccount } from "../../../services/account";
import { useState } from "react";

const AccountTableBody = ({ data, setRefresh }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    setLoading(true);
    deleteAccount(id, setLoading, setRefresh);
  };

  return (
    <Tbody>
      {data?.map((datum, key) => (
        <Tr key={key} fontSize={"16px"}>
          <Tooltip label={datum?.accountName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.accountName}
            </Td>
          </Tooltip>

          <Tooltip label={datum?.email}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.email}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.PhoneNumber}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.phoneNumber}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.accountName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.accountName}
            </Td>
          </Tooltip>
          <Tooltip maxWidth="170px !important" label={datum?.accountNumber}>
            <Td isTruncated borderRight="1px solid #dadedf !important">
              {datum?.accountNumber}
            </Td>
          </Tooltip>

          <Tooltip
            label={datum?.bankName}
            maxWidth={["40%", "40%", "170px !important"]}
          >
            <Td isTruncated borderRight="1px solid #dadedf !important">
              {datum?.bankName}
            </Td>
          </Tooltip>
          <Tooltip maxWidth="170px !important" label={datum?.category?.name}>
            <Td
              isTruncated
              borderRight="1px solid #dadedf !important"
              textTransform={"capitalize"}
            >
              {datum?.category?.name}
            </Td>
          </Tooltip>

          <Td>
            <CustomModal
              icon={<BsTrash size="18px" color={"#154141"} />}
              header={`Delete Account`}
              data={datum}
            >
              <DeleteModal
                text="Delete Account"
                loading={loading}
                handleClick={() => handleDelete(datum?._id)}
              />
            </CustomModal>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default AccountTableBody;
