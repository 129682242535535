export const AUTH_ROUTES = {
  LOGIN: "/admin/login",
  GET_LOGGED_IN_ADMIN: "/admin/me",
  UPDATE_COMPANY_DETAILS: (companyId) => `/company/update/${companyId}`,
  GET_COMPANY_DETAILS: (companyId) => `/company/fetch?id=${companyId}`,
  SEND_OTP: "/auth/send-otp",
  VERIFY_OTP: "/auth/verify/otp",
  RESET_PASSWORD: "auth/admin-password-reset",

  //ACCOUNT
  ACCOUNT: "/account?sort=desc",
  DELETE_ACCOUNT: (accountId) => `/account/delete/${accountId}`,
  UPDATE_ACCOUNT: (adminId) => `/admin/update/${adminId}`,
  UPDATE_PASSWORD: "/admin/change-password",
  UPDATE_IMAGE: "/admin/image/upload",
  SEARCH_ACCOUNT: (keyword) => `/account/search?search=${keyword}`,
  UPDATE_APPROVER: (approverId) => `/admin/approval/${approverId}`,
  UPDATE_TRANSACTION_PIN: `/admin/pin`,

  //ANALYTICS
  GET_MONTHLY_CATEGORY_EXPENSE: `/analytics/category/expenses`,
  GET_DAILY_CATEGORY_EXPENSE: `/analytics/category/daily/expenses`,
  GET_EXPENSE_PER_ITEM: `/analytics/items`,
  GET_MONTHLY_PAYIN: `/analytics/monthly/revenue?status=confirmed`,
  GET_DAILY_PAYIN: `/analytics/daily/revenue?status=confirmed`,
  GET_MONTHLY_PAYOUT: `/analytics/monthly-analytics`,
  GET_DAILY_PAYOUT: `/analytics/daily-analytics`,

  //CATEGORY
  GET_CATEGORIES: "/category?sort=desc",
  CREATE_CATEGORY: "/category/create",
  UPDATE_CATEGORY: (id) => `/category/update/${id}`,
  DELETE_CATEGORY: (id) => `/category/delete/${id}`,
  ADD_ITEMS: (_id) => `/category/item/${_id}`,
  DELETE_ITEM: (itemId) => `/category/delete/item/${itemId}`,

  //DEPARTMENT
  CREATE_DEPARTMENT: "/department",
  GET_DEPARTMENTS: "/department?sort=desc",
  UPDATE_DEPARTMENT: (deptId) => `/department/update/${deptId}`,
  DELETE_DEPARTMENT: (deptId) => `department/delete/${deptId}`,

  //MESSAGES
  MESSAGE: "/chats",
  GET_CONVERSATIONS: "/conversations?sort=desc",
  GET_CONVERSATION_BY_ID: (conversationId) =>
    `/chats?conversationId=${conversationId}`,

  //NOTIFICATIONS
  GET_NOTIFICATIONS: "/notification?sort=desc",
  READ_NOTIFICATION: (id) => `/notification/${id}`,

  //OVERVIEW
  GET_DAILY_ANALYTICS: "/analytics/daily-analytics",
  GET_EXPENSES: "/analytics/expenses",
  GET_MONTHLY_ANALYTIC_GRAPH: "/analytics/monthly-analytics",
  GET_MONTHLY_EXPENSES: "/analytics/monthly/expenses",
  GET_DEPARTMENT_EXPENSES: "/analytics/department/expenses",
  REFUND_WALLET: "/transaction/initiate",

  //QUOTATION & RESPONSE
  QUOTATION: "/quotationResponse",
  CREATE_QUOTATION: "/quotation",
  GET_QUOTATION: "/quotation?sort=desc",
  SEARCH_QUOTATION: (keyword) => `/quotation/search?search=${keyword}`,

  //SCHEDULE
  SCHEDULE: "/schedule?sort=desc",
  SEARCH_SCHEDULE: (keyword) => `/schedule?search=${keyword}`,
  UPDATE_SCHEDULE: (scheduleId) => `/schedule/update/${scheduleId}`,
  DELETE_SCHEDULE: (scheduleId) => `/schedule/delete/${scheduleId}`,

  //STAFF
  GET_STAFF: "/admin/users?sort=desc",
  CREATE_STAFF: "/admin/users",
  BULK_UPLOAD: "/admin/users/bulk-upload",
  UPDATE_STAFF: "/admin/users",
  DELETE_STAFF: (staffId) => `/admin/user/${staffId}`,
  SEARCH_STAFF: (keyword) => `/admin/users/search?search=${keyword}`,

  //REQUEST
  GET_ALL_REQUESTS: "/request/user-request?sort=desc",
  GET_DECLINED_REQUESTS: "/request/user-request?status=Declined",
  GET_APPROVED_REQUESTS: "/request/user-request?status=Approved",
  CREATE_REQUEST: "/request/create",
  UPDATE_REQUEST_DETAILS: `/request/status/update`,
  UPDATE_REQUEST: (requestId) => `/request/update/${requestId}`,
  SEARCH_REQUESTS: (startDate, endDate) =>
    `/request/user-request?from=${startDate}&to=${endDate}`,
  MAKE_PAYOUT: "/transaction/payout",

  // TRANSACIONS
  GET_PAYOUT_TRANSACTIONS: `/transaction?sort=desc&paymentFor=payOut`,
  GET_PAYIN_TRANSACTIONS: `/transaction?sort=desc&paymentFor=payIn`,
  GET_TRANSACTIONS_BY_DEPARTMENT: (deptId) =>
    `/transaction?departmentId=${deptId}`,
  GET_TRANSACTIONS_BY_STAFFID: (staffId) => `/transaction?userId=${staffId}`,
  FILTER_TRANSACTIONS: (from, to) => `/transaction?from=${from}&to=${to}`,
  SEARCH_TRANSACTIONS_BY_KEYWORD: (keyword, paymentFor) =>
    `/transaction/search?search=${keyword}&paymentFor=${paymentFor}`,
  GET_DEPARTMENTS_PAYOUTS: "/transaction/payout?sort=desc",
  GET_ALL_BANKS: "/transaction/banks",

  // SUPPORT
  CREATE_SUPPORT_TICKET: `/support/create`,
  GET_SUPPORT_TICKET: (Id) => `/support?companyId=${Id}&sort=desc`,
  RESPONSE_SUPPORT_TICKET: `/support/response`,
  DELETE_SUPPORT_TICKET: (id) => `/support/delete/${id}`,

  GET_ANNUAL_PAYOUTSTOVENDORS: `/analytics/annual-vendor-record`,
  GET_MONTHLY_PAYOUTSTOVENDORS: `/analytics/vendors`,
};
