import React from "react";
import { Box, Table } from "@chakra-ui/react";
import { accountTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import TableBody from "./AccountTableBody";


const AccountTable = ({ users, setRefresh }) => {
  return (
    <Box className="table-container" width="100%" overflowX={["scroll"]}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
        mt="50px"
      >
        <TableHead tableHeadData={accountTableHead} />
        <TableBody
          // data={users}
          data={users}
          setRefresh={setRefresh}
        />
      </Table>
    </Box>
  );
}

export default AccountTable;
