import React, { Children, cloneElement, isValidElement } from "react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Flex,
  Text,
  useDisclosure,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";

const CustomModal = ({
  children,
  icon,
  header,
  // text,
  // data,
  overflow,
  maxH,
  title,
  cursor,
  color,
  textAlign,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const btnRef = React.useRef();
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child, { onClose });
    }

    return child;
  });

  return (
    <>
      <Flex cursor="pointer" onClick={onOpen} align="center">
        {icon}
        <Text m="auto" color={color} cursor={cursor} textAlign={textAlign}>
          {title}
        </Text>
      </Flex>

      <Modal
        // id={id}
        size={"md"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        // finalFocusRef={btnRef}
      >
        <ModalOverlay />
        <ModalContent bg="#fff">
          <ModalCloseButton />
          <Flex
            direction="column"
            width="100%"
            boxShadow="md"
            p="20px 30px !important"
          >
            <Text
              textAlign="center"
              fontSize={"18px"}
              color={"#1A0031"}
              fontWeight="semibold"
              textTransform={"capitalize"}
            >
              {header}
            </Text>
          </Flex>
          <Box overflowY={overflow} maxH={maxH}>
            <ModalBody p={"30px !important"}>{childrenWithProps}</ModalBody>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;
