import { Box, Flex, Text } from "@chakra-ui/react";
import { CustomBtn } from "../../CustomBtn";
import { BsCheckLg } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";
import { FaUpload } from "react-icons/fa";
import { formatToNaira } from "../../../utils/numberFormat";

const VendorResonseModal = ({ datum }) => {
  return (
    <Box>
      <Text pb="20px" color={"#3C7B79"} fontWeight={"bold"}>
        Vendor's Response
      </Text>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Vendor's Name:</Text>
        <Text color="#7D7C7C">{datum?.name || "N/A"}</Text>
      </Flex>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Phone Number:</Text>
        <Text color="#7D7C7C">{datum?.phoneNumber || "N/A"}</Text>
      </Flex>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Category:</Text>
        <Text color="#7D7C7C" textTransform="capitalize">
          {datum?.category?.name || datum?.categoryInfo?.name || "N/A"}
        </Text>
      </Flex>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Amount:</Text>
        <Text color="#7D7C7C">{formatToNaira(datum?.costAmount) || "N/A"}</Text>
      </Flex>
      <Flex justify="space-between" pb="10px" alignItems="center">
        <Text color="#830f0f">With holding tax:</Text>
        <Text>
          {datum?.withHoldingTax ? (
            <BsCheckLg color={"#3C7B79"} />
          ) : (
            <FaTimes color="#830f0f" />
          )}
        </Text>
      </Flex>
      <Box>
        <Text color="#393838" pb="10px">
          Response Note:
        </Text>
        <Text pb="20px" color="#7D7C7C">
          {datum?.responseNote || "N/A"}
        </Text>
      </Box>
      <Box>
        <Text color="#393838" pb="10px">
          Uploaded File:
        </Text>
        <Box
          borderRadius="7px"
          bg={
            datum?.file
              ? `url(${datum?.file}) center/cover no-repeat`
              : "transparent"
          }
          boxShadow="md"
          width="80%"
          height="120px"
          cursor="pointer"
          margin="auto"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {datum.file ? null : <FaUpload size={30} color="#3C7B79" />}
        </Box>
      </Box>
      <Box align="center" mt="40px">
        <CustomBtn text={"Download CSV"} width="250px" height="48px" />
      </Box>
    </Box>
  );
};

export default VendorResonseModal;
