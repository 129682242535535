import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const createSupport = async (
  payload,
  setLoading,
  setRefresh,
  onClose
) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_SUPPORT_TICKET, payload);
    successNotifier("Support Ticket Created Successfully");
    setRefresh((prev) => !prev);
    
  } catch (e) {
    console.log(e.response?.data?.message);
    errorNotifier(e.response?.data?.message);
  } finally {
    setLoading(false);
    onClose();
  }
};

  export const getSupportTicket = async (
    setTicket,
    ticketId,
    setLoading
  ) => {
    try {
      const {
        data: { data },
      } = await http.get(AUTH_ROUTES.GET_SUPPORT_TICKET(ticketId));
      setTicket(data);
    } catch (e) {
      if (e.response?.data?.message === "Unable to verify token.") {
        errorNotifier("Session timed out");
        window.location.reload();
      }
    } finally {
      setLoading(false);
    }
  };

  export const responseSupport = async (
    payload,
    setIsLoading,
    setRefresh,
    onClose
  ) => {
    try {
      await http.post(AUTH_ROUTES.RESPONSE_SUPPORT_TICKET, payload);
      successNotifier("Support Ticket Responded Successfully");
      setRefresh((prev) => !prev);
      onClose();
    } catch (e) {
      errorNotifier(e.response.data.errors.message);
    } finally {
      setIsLoading(false);
    }
  };

  export const deleteTicket = async ( ticketId, payload, setIsLoading, setRefresh) => {
    try {
        console.log(payload);
      await http.put(AUTH_ROUTES.DELETE_SUPPORT_TICKET(ticketId), payload);
      successNotifier("Ticket Deleted Successfully");
      setRefresh((prev) => !prev);
    } catch (e) {
      errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
    } finally {
      setIsLoading(false);
    }
    // onClose();
  };