import React from "react";
import { BiDownload } from "react-icons/bi";
import { CSVLink } from "react-csv";

const CSVDownload = ({ headers, data, currentRecord, responseData, text}) => {
  return (
    <CSVLink headers={headers} data={data} text={text}>
      <BiDownload size={22} color="#154141" cursor={"pointer"} />
    </CSVLink>
  );
};

export default CSVDownload;
