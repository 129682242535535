import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select as ChakraSelect,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { CustomBtn } from "../CustomBtn";
import { updateApprover } from "../../services/accountSettings";
import SetPin from "../SetPin";
import { createPin } from "../../services/accountSettings";
import { getStaff } from "../../services/staff";
import { useEffect } from "react";
import Select from "react-select";

const ApprovalNumber = ({ userInfo, setRefresh }) => {
   const [staff, setStaff] = useState([]);
  const [pinValue, setPinValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [createPinLoading, setCreatePinLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    approverLimit: "",
    approverNeed: "",
    staff: [],
    approverRequired: [],
  });

  const formattedPayload = {
    approverLimit: formValues.approverLimit
      ? parseFloat(formValues.approverLimit)
      : "",
    approverNeed: formValues.approverNeed
      ? parseInt(formValues.approverNeed)
      : "",
    userId: formValues?.staff?.map(item => ({
    id: item.value
}))
  };

   useEffect(() => {
     getStaff(setStaff, setLoading);  
  }, []);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //    console.log("FORM VALUES", name);
  //   setFormValues((prev) => ({ ...prev, [name]: value }));
  // };
  const handleChange = (name, value) => {
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };
  //console.log("FORM VALUES", formattedPayload);
  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = [
      {
        ...formValues,
        ...formattedPayload,
      },
    ];
    setLoading(true);
    console.log(payload);
    await updateApprover(payload, setLoading, setRefresh, userInfo?.companyId);
  };

  const handlePinChange = (value) => {
    setPinValue(value);
  };

  const handleCreatePin = async (event) => {
    event.preventDefault();
    setCreatePinLoading(true);
    const createPinPayload = {
      adminId: userInfo?._id,
      transactionPin: pinValue,
    };
    await createPin(createPinPayload, setCreatePinLoading, setRefresh);
  };

  // Function to handle changes in educational background fields
  const handleAdditionalApproverNeedsChange = (index, fieldName, value) => {
    setFormValues((prev) => {
      const newAdditionalApprovals = [...prev.approverRequired];
      newAdditionalApprovals[index] = {
        ...newAdditionalApprovals[index],
        [fieldName]: value,
      };
      return { ...prev, approverRequired: newAdditionalApprovals };
    });
  };

  // Function to add a new set of educational background fields
  const addAdditionApproverNeeds = () => {
    setFormValues((prev) => ({
      ...prev,
      approverRequired: [
        ...prev?.approverRequired,
        { spending_limit: "", approver_needs: "", approver_staff: [] },
      ],
    }));
  };

  const removeAdditionalApproverNeeds = (indexToRemove) => {
    setFormValues((prev) => ({
      ...prev,
      approverRequired: prev?.approverRequired?.filter(
        (_, index) => index !== indexToRemove
      ),
    }));
  };

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        direction={["column", "column", "row"]}
        gap={["20px", "20px", "0px"]}
      >
        <Box bg="white" p={"20px"} w={["100%", "60%"]} borderRadius="5px">
          <Flex justify={"space-between"} align={"center"} pb={"20px"}>
            <Text fontWeight="Bold">Approver Amount Limit</Text>
            <Text>
              <AiOutlinePlus
                size={"16px"}
                color="#3C7B79"
                cursor="pointer"
                onClick={addAdditionApproverNeeds}
              />
            </Text>
          </Flex>
          <Flex
            align={"center"}
            pb="20px"
            justify={"space-between"}
            direction={["column", "column", "row"]}
          >
            <Box
              display="flex"
              alignItems="center"
              width={["100%", "100%", "30%"]}
            >
              <FormControl>
                <FormLabel color="#757272">Amount</FormLabel>
                <InputGroup>
                  <InputLeftAddon children={"₦"} height="38px" />
                  <Input
                    height="38px"
                    label="Set Spending Limits"
                    type="number"
                    name="approverLimit"
                    value={formValues.approverLimit}
                    onChange={(e) =>
                      handleChange("approverLimit", e.target.value)
                    }
                  />
                </InputGroup>
              </FormControl>
            </Box>
            <Box width={["100%", "100%", "30%"]}>
              <Text pb={"10px"}>Minimum Approver</Text>
              <Input
                type="number"
                min={0}
                step={1}
                name="approverNeed"
                value={formValues.approverNeed}
                onChange={(e) => handleChange("approverNeed", e.target.value)}
              />
            </Box>
            <Box width={["100%", "100%", "37%"]}>
              <Text pb={"10px"}>Staff</Text>
              <Select
                placeholder="Select Staff"
                label="Staff"
                name="staff" // Use the name attribute
                value={formValues?.staff?.map((staff) => ({
                  value: staff?.value,
                  label: staff?.label,
                }))}
                onChange={(selectedOptions) =>
                  handleChange("staff", selectedOptions)
                } // Pass the name attribute and selected options
                isMulti
                options={staff?.map((staff) => ({
                  value: staff?._id,
                  label: staff?.fullName,
                }))}
              />
            </Box>
          </Flex>
          <Divider mb="1rem" />
          {formValues?.approverRequired?.map((approver, index) => (
            <Flex
              width="100%"
              justify={"space-between"}
              direction={["column", "column", "row"]}
              align={"center"}
              pb="20px"
              key={index}
            >
              <Box
                display="flex"
                alignItems="center"
                width={["100%", "100%", "30%"]}
              >
                <FormControl>
                  <FormLabel color="#757272">Amount {index + 1}</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children={"₦"} height="38px" />
                    <Input
                      height="38px"
                      label="Set Spending Limits"
                      type="number"
                      name={`spending_limit_${index}`}
                      value={approver?.spending_limit}
                      onChange={(e) =>
                        handleAdditionalApproverNeedsChange(
                          index,
                          "spending_limit",
                          e.target.value
                        )
                      }
                    />
                  </InputGroup>
                </FormControl>
              </Box>
              <Box width={["100%", "100%", "30%"]}>
                <Flex width="100%" justify="space-between" align={"center"}>
                  <Text pb={"10px"}>Minimum Approver</Text>
                </Flex>
                <Input
                  type="number"
                  min={0}
                  step={1}
                  name={`approver_needs_${index}`}
                  value={approver?.approver_needs}
                  onChange={(e) =>
                    handleAdditionalApproverNeedsChange(
                      index,
                      "approver_needs",
                      e.target.value
                    )
                  }
                />
              </Box>
              <Box width={["100%", "100%", "37%"]}>
                <Flex width="100%" justify="space-between" align={"center"}>
                  <Text pb={"10px"}>Staff</Text>
                  <AiOutlineMinus
                    cursor={"pointer"}
                    color="red"
                    onClick={() => removeAdditionalApproverNeeds(index)}
                  />
                </Flex>
                <Select
                  placeholder="Select Staff"
                  label="Staff"
                  name={`approver_staff_${index}`}
                  value={approver.approver_staff?.map((staff) => ({
                    value: staff,
                    label: staff,
                  }))}
                  onChange={(selectedOptions) =>
                    handleAdditionalApproverNeedsChange(
                      index,
                      "approver_staff",
                      selectedOptions.map((option) => option.value)
                    )
                  }
                  isMulti
                   options={staff?.map((staff) => ({
                  value: staff?._id,
                  label: staff?.fullName,
                }))}
                />
                {/* <Select
                  placeholder="Select Staff"
                  label="Staff"
                  name={`approver_staff_${index}`}
                  value={approver?.approver_staff}
                  onChange={(e) =>
                    handleAdditionalApproverNeedsChange(
                      index,
                      "approver_staff",
                      Array.isArray(e) ? e.map((option) => option.value) : []
                    )
                  }
                >
                  <option value="Dummy Staff One">Dummy Staff One</option>
                  <option value="Dummy Staff Two">Dummy Staff Two</option>
                </Select> */}
              </Box>
            </Flex>
          ))}
          <Flex justifyContent={"right"}>
            <CustomBtn
              childComp={<AiOutlinePlus />}
              text={"Update"}
              loading={loading}
              type={"submit"}
              handleClick={handleSubmit}
              disabled={!formValues.approverLimit || !formValues.approverNeed}
            />
          </Flex>
        </Box>
        <Box
          bg="white"
          p={"20px"}
          w={["100", "38%"]}
          borderRadius="5px"
          h="fit-content"
        >
          <Text textAlign="start" fontWeight="bold" pb="30px">
            Set Transfer Pin
          </Text>
          <VStack justifyContent="center" flexDir="column" spacing="50px">
            <SetPin size="lg" handlePinChange={handlePinChange} />
            <CustomBtn
              text="Create Pin"
              loading={createPinLoading}
              handleClick={handleCreatePin}
              disabled={pinValue?.length !== 4}
            />
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default ApprovalNumber;
