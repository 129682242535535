import { Box, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ChartRows } from "../components/analytics/ChartRows";
import { CategoryExpensesCard } from "../components/analytics/CategoryExpensesCard";
import { PayinPayoutCard } from "../components/analytics/PayinPayoutCard";
import { AverageRevenueCard } from "../components/analytics/AverageRevenueCard";
import FullPageLoader from "../components/fullPageLoader";
import {
  getAnnualPayoutToVendors,
  getDailyCategoryExpense,
  getMonthlyCategoryExpense,
  getMonthlyPayoutToVendors,
} from "../services/analytics";
import { ChartRowVendor } from "../components/analytics/ChartRowVendor";

const Analytics = () => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [monthlyCategoryExpense, setMonthlyCategoryExpense] = useState([]);
  const [dailyCategoryExpense, setDailyCategoryExpense] = useState([]);
  const [monthlyPayoutToVendors, setMonthlyPayoutToVendors] = useState([]);
  const [annualPayoutToVendors, setAnnualPayoutToVendors] = useState([]);

  useEffect(() => {
    getMonthlyCategoryExpense(setMonthlyCategoryExpense, setLoading);
    getDailyCategoryExpense(setDailyCategoryExpense, setLoading);
    getMonthlyPayoutToVendors(setMonthlyPayoutToVendors, setLoading);
    getAnnualPayoutToVendors(setAnnualPayoutToVendors, setLoading);
  }, [refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Heading as="h2" fontSize={["22px", "22px", "30px"]}>
        Analytics
      </Heading>
      <ChartRows
        monthlyCategoryExpense={monthlyCategoryExpense}
        dailyCategoryExpense={dailyCategoryExpense}
        setLoading={setLoading}
        setRefresh={setRefresh}
      />
      <CategoryExpensesCard
        setLoading={setLoading}
        monthlyCategoryExpense={monthlyCategoryExpense}
        dailyCategoryExpense={dailyCategoryExpense}
      />
      <AverageRevenueCard setLoading={setLoading} />
      <PayinPayoutCard setLoading={setLoading} />
      <ChartRowVendor
        monthlyPayoutToVendors={monthlyPayoutToVendors}
        annualPayoutToVendors={annualPayoutToVendors}
        setLoading={setLoading}
      />
    </Box>
  );
};
export default Analytics;
