
export const staffCsvHeaders = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "email",
    label: "Email Address",
  },
  {
    key: "phone",
    label: "Phone Number",
  },
  {
    key: "role",
    label: "Role",
  },
  {
    key: "department",
    label: "Department",
  },
];

export const vendorCsvHeaders = [
  {
    key: "vendorName",
    label: "vendor's Name",
  },
  {
    key: "email",
    label: "Email Address",
  },
  {
    key: "phone",
    label: "Phone Number",
  },
  {
    key: "accountName",
    label: "Account Name",
  },
  {
    key: "accountNumber",
    label: "Account Number",
  },
  {
    key: "bank",
    label: "Bank Name",
  },
  {
    key: "category",
    label: "Vendor's Category",
  },
];
export const responseCsvHeaders = [
  {
    key: "admin",
    label: "Admin",
  },
  {
    key: "title",
    label: "Response Title",
  },
  {
    key: "category",
    label: "Categories",
  },
  {
    key: "created",
    label: "Date Created",
  },
  {
    key: "deadline",
    label: "Expiry Date",
  },
  {
    key: "sentTo",
    label: "Total Vendor Sent To",
  },
  {
    key: "response",
    label: "Total Responses",
  },
];
export const vendorModalCsvHeaders = [
  {
    key: "vendorName",
    label: "Vendor's Name",
  },
  {
    key: "phoneNumber",
    label: "Phone Number",
  },
  {
    key: "category",
    label: "Categories",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "tax",
    label: "Holding Tax",
  },
  {
    key: "note",
    label: "Response Note",
  },
  {
    key: "file",
    label: "File",
  },
];
export const scheduleCsvHeaders = [
  {
    key: "vendorName",
    label: "vendor's Name",
  },
  {
    key: "accountName",
    label: "Account Name",
  },
  {
    key: "accountNumber",
    label: "Account Number",
  },
  {
    key: "bank",
    label: "Bank Name",
  },
  {
    key: "category",
    label: "Vendor's Category",
  },
  {
    key: "date",
    label: "Schedule Payment Date",
  },
  {
    key: "amount",
    label: "Payment Amount",
  },
];
export const requestCsvHeaders = [
  {
    key: "senderName",
    label: "Sending Name",
  },
  {
    key: "date",
    label: "Sending Date",
  },
  {
    key: "amount",
    label: "Amount",
  },
  {
    key: "department",
    label: "Department",
  },
  {
    key: "status",
    label: "Status",
  },
];
export const departmentCsvHeaders = [
  {
    key: "department",
    label: "Departments",
  },
  {
    key: "amount",
    label: "Amount Limits",
  },
  {
    key: "spending",
    label: "Spending Limits",
  },
];
export const transactionCsvHeaders = [
  {
    key: "department",
    label: "Departments",
  },
  {
    key: "category",
    label: "Catergories",
  },
  {
    key: "amount",
    label: "Amounts",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "status",
    label: "Status",
  },
];