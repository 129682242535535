import http, { AUTH_ROUTES } from "../services/api";

export const getUserProfile = async (setState) => {
  let loading = true;
  try {
    loading = true;
    const data = await http.get(AUTH_ROUTES.GET_LOGGED_IN_ADMIN);
    loading = false;
    setState((prev) => ({
      ...prev,
      loading,
      userInfo: data?.data?.date,
    }));

    return data?.data.date;
  } catch (e) {
    loading = false;
   
  }
};
