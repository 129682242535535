import dayjs from "dayjs";
import { formatToNaira } from "./numberFormat";

export const staffCsv = (data) => {
  return data?.map((data) => {
    return {
      name: data?.fullName || "--",
      email: data?.email || "--",
      phone: String(data?.phoneNumber) || "--",
      role: data?.role || "--",
      department: data?.department?.department || "--",
    };
  });
};
export const vendorsCsv = (data) => {
  return data?.map((data) => {
    return {
      vendorName: data?.accountName || "--",
      email: data?.email || "--",
      phone: String(data?.phoneNumber) || "--",
      accountName: data?.accountName || "--",
      accountNumber: data?.accountNumber || "--",
      bank: data?.bankName || "--",
      category: data?.category || "--",
    };
  });
};

export const responseCsv = (data) => {
  return data?.map((data) => {
    return {
      admin: data?.userId?.fullName || "--",
      title: data?.title || "--",
      category: data?.category?.name || "--",
      created: dayjs(data?.createdAt).format("DD/MM/YYYY") || "--",
      deadline: dayjs(data?.deadline).format("DD/MM/YYYY") || "--",
      sentTo: String(data.vendorsSentTo) || "--",
      response: String(data?.vendorResponse?.length) || "--",
    };
  });
};
export const vendorResponseCsv = (data) => {
  return data?.map((data)=>{
    return {
      vendorName: data?.name || "--",
      phoneNumber: String(data?.phoneNumber) || "--",
      category: data?.category?.name || "--",
      amount: formatToNaira(data?.costAmount) || "--",
      tax: data?.withHoldingTax ? "true":"false" || "--",
      note: data?.responseNote || "--",
      file: data?.file || "--",
    };
   });
};

export const scheduleCsv = (data) => {
  return data?.map((data) => {
    return {
      vendorName: data?.name || "--",
      accountName: data?.accountName || "--",
      accountNumber: data?.accountNumber || "--",
      bank: data?.bank || "--",
      category: data?.category || "--",
      date: dayjs(data?.date).format("MMM, DD YYYY") || "--",
      amount: formatToNaira(data?.paid) || "--",
    };
  });
};
export const requestCsv = (data) => {
  return data?.map((data) => {
    return {
      senderName: data?.userId?.fullName || "--",
      date: dayjs(data?.updatedAt).format("MMM, DD YYYY") || "--",
      amount: formatToNaira(data?.amount) || "--",
      department: data?.department?.department || "--",
      status: data?.status || "--",
    };
  });
};
export const departmentCsv = (data) => {
  return data?.map((data) => {
    return {
      department: data?.department || "--",
      amount: formatToNaira(data?.spendingLimit) || "--",
      spending: `${
        formatToNaira(data?.amountSpent) || 0
      } Spent out of ${formatToNaira(data?.spendingLimit)}`,
    };
  });
};
export const transactionCsv = (data) => {
  return data?.map((data) => {
    return {
      department: data?.department || "--",
      category: data?.categories || "--",
      amount: formatToNaira(data?.amount) || "--",
      date: dayjs(data?.updatedAt).format("MMM, DD YYYY") || "--",
      status: data?.status || "--",
    };
  });
};
