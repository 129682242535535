import { Box, Flex, FormLabel, Select as ChakraSelect } from "@chakra-ui/react";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { addItems } from "../services/addItem";
import { CustomBtn } from "./CustomBtn";

const AddItemModal = ({ text, setRefresh, categories, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState("");

  const handleChange = (selectedOption) => {
    const selectedItems = selectedOption.map((option) => ({
      item: option?.value,
    }));
    setItems(selectedItems);
  };

  const handleCreateOption = (inputValue) => {
    const newItem = { item: inputValue };
    setItems((prevItems) => [...prevItems, newItem]);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = { items: items, _id: category };
    await addItems(payload, setLoading, setRefresh, onClose);
  };

  return (
    <Box width="100%">
      <FormLabel fontSize="16px" color="#585858" mt={6}>
        Item Name(s)
      </FormLabel>
      <CreatableSelect
        isMulti
        value={items}
        onChange={handleChange}
        options={items}
        onCreateOption={handleCreateOption}
        getOptionLabel={(option) => option.item}
        getOptionValue={(option) => option.item}
        placeholder="Type or select item(s) and click Enter"
      />

      <Box mb={6}>
        <FormLabel fontSize={"16px"} color={"#585858"} mt={6}>
          Under what category
        </FormLabel>
        <ChakraSelect
          height="48px"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option>Select Category</option>
          {categories.map((category) => (
            <option key={category._id} value={category._id}>
              {category.name}
            </option>
          ))}
        </ChakraSelect>
      </Box>

      <Flex width="100%" justify="center">
        <CustomBtn
          height="48px"
          text={text}
          width="80%"
          loading={loading}
          handleClick={handleSubmit}
          disabled={!items.length || !category}
        />
      </Flex>
    </Box>
  );
};

export default AddItemModal;
