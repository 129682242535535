import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getDepartment = async (setDepartment, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DEPARTMENTS);
    setDepartment(data);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const createDepartment = async (payload, setLoading, setRefresh) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_DEPARTMENT, payload);
    successNotifier("Department Created Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Please check your network connection");
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const updateDepartment = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_DEPARTMENT(payload?.deptId), payload);
    successNotifier("DEPARTMENT Updated Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const deleteDepartment = async (
  payload,
  deptId,
  setLoading,
  setRefresh
) => {
  try {
    await http.put(AUTH_ROUTES.DELETE_DEPARTMENT(deptId), payload);
    successNotifier("Department Deleted Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
