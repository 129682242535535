import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { CustomBtn } from "../components/CustomBtn";
import CustomModal from "../components/CustomModal";
import DepartmentModalContent from "../components/DepartmentModalContent";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import DepartmentTable from "../components/table/departments/DepartmentTable";
import { getDepartmentWithHighestExpenses } from "../services/overview";
import CSVDownload from "../components/CSVDownload";
import { departmentCsv } from "../utils/csvContents";
import { departmentCsvHeaders } from "../constants/csvHeaders.constants";

const DepartmentsPage = () => {
  const [department, setDepartment] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPge = 20;
  const lastIndex = dataPerPge * currentPage;
  const firstIndex = lastIndex - dataPerPge;

  const departmentArray = Object.keys(department).map((key) => ({
    id: key,
    ...department[key],
  }));

  const allDepartments = departmentArray?.filter(
    (ar) => ar.id !== "generalSpending"
  );

  const records = allDepartments?.slice(firstIndex, lastIndex);

  const total = dataPerPge * currentPage + records?.length - dataPerPge;

  useEffect(() => {
    getDepartmentWithHighestExpenses(setDepartment, setLoading);
  }, [refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <Flex
        width="100%"
        justify="space-between"
        alignItems={["flex-start", "flex-start", "center"]}
        mb="30px"
        direction={["column", "column", "row"]}
      >
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "0px"]}
        >
          Departments
        </Heading>
        <Flex
          align={"center"}
          justify={"space-between"}
          w={["100%", "100%", "50%", "30%"]}
        >
          <CustomModal
            icon={
              <CustomBtn childComp={<AiOutlinePlus />} text="Add Department" />
            }
            header={`Add New Department`}
            iconColor={"#154141"}
          >
            <DepartmentModalContent
              text="Add Department"
              setRefresh={setRefresh}
            />
          </CustomModal>
          <CSVDownload
            headers={departmentCsvHeaders}
            data={departmentCsv(allDepartments)}
          />
        </Flex>
      </Flex>
      <DepartmentTable departments={records} setRefresh={setRefresh} />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {allDepartments?.length} Requests
        </Text>
        <Pagination
          totalData={allDepartments?.length ?? 0}
          dataPerPage={dataPerPge}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default DepartmentsPage;
