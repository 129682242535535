import React, { useState } from "react";
import { Box, Text, Flex, HStack } from "@chakra-ui/react";
import { FormInputs } from "./FormInput";
import { Textarea } from "@chakra-ui/react";
import { CustomBtn } from "./CustomBtn";
import { Avatar, AvatarBadge, AvatarGroup } from "@chakra-ui/react";

function RequestCommentModal({ data }) {
  return (
    <Box>
      <Flex direction="column">
        <Flex w="full" mb="20px" align={"center"}>
          <Text pr="20px" fontWeight="semibold">
            Sending To:
          </Text>

          <HStack>
            <Avatar size="sm" name={data?.userAccount?.accountName} />
            <Text>{data?.userAccount?.accountName}</Text>
          </HStack>
        </Flex>

        <Flex w="full" justify="space-between" align="center" mb="20px">
          <Text pr="30px" fontWeight="semibold">
            Request:
          </Text>
          <FormInputs
            mt="20px"
            backgroundColor="grey"
            placeholder={data?.title}
            height={"40px"}
          />
        </Flex>
        <Flex w="full" justify="space-between" mb="20px">
          <Text pr="30px" fontWeight="semibold">
            Respond
          </Text>
          <Textarea placeholder="Type Here..." resize={"none"} h="150px" />
        </Flex>
        <Flex justify="flex-end" mb="20px">
          <CustomBtn text={`Send`} width="100px"></CustomBtn>
        </Flex>
      </Flex>
    </Box>
  );
}

export default RequestCommentModal;
