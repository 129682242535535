import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {createSupport} from "../services/support"

 const ContactUsModal = ({ isOpen, onClose, setRefresh }) => {
  const [formData, setFormData] = useState({
    reason: "",
    message: "",
  });

  const [loading, setLoading] =useState(false)
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = formData;
    console.log("Form Data:", payload);
    await createSupport(payload, setLoading, setRefresh, onClose);
    setFormData({ reason: "", message: "" })
  };

  return (
    <>
      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent px="20px" bg={"#F8F8F8"} w={"100%"}>
          <ModalCloseButton color={"brandColor"} />
          <ModalBody py={6}>
            <Box w={"100%"} bg={"#FFFFFF"} p={"30px"}>
              <Text color={"#264653"} fontSize={"15px"} fontWeight={"semibold"} py={"10px"}>
                Reason
              </Text>
              <Input
                bg={"#F4F9F9"}
                size={"lg"}
                name="reason"
                value={formData.reason}
                onChange={handleInputChange}
              />
              <Text color={"#264653"} fontSize={"15px"} fontWeight={"semibold"} py={"10px"} pt={"15px"}>
                Message
              </Text>
              <Textarea
                resize={"none"}
                bg={"#F4F9F9"}
                h={"150px"}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
              <Flex justify={"center"}>
                <Button
                  bg={"#2A9D8F"}
                  color={"#FFFFFF"}
                  fontSize={"14px"}
                  fontWeight={"light"}
                  alignContent="center"
                  mt={"40px"}
                  px={"60px"}
                  _hover={{ bg: "brandColor" }}
                  disabled={!formData.reason || !formData.message}
                  isLoading={loading}
                  onClick={handleSubmit}
                  w={["100%", "100%", "60%"]}
                >
                  Proceed
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactUsModal