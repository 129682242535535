import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Flex } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

export function VendorMonthlyChart({ monthlyPayoutToVendors, itemColors }) {
  const labels = monthlyPayoutToVendors.map((item) => item?.accountName);
  const values = monthlyPayoutToVendors.map((item) =>
    Object.values(item?.transactions).reduce((acc, curr) => acc + curr, 0)
  );
  const data = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: itemColors,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = data.labels[context.dataIndex];
            const value = data.datasets[0].data[context.dataIndex];
            const total = data.datasets[0].data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${percentage}%`;
          },
        },
      },
    },
    responsive: true,
    backgroundColor: monthlyPayoutToVendors?.map((data) => data?.color),
    maintainAspectRatio: false,
    legend: {
      display: false,
      position: "bottom",
      usePointStyle: true,
    },
  };

  return (
    <Flex justify={["center", "center", "start"]} w={"100%"}>
      <Pie data={data} options={options} height={"300px"} width={"200px"} />
    </Flex>
  );
}
