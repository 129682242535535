import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { formatToNaira } from "../../utils/numberFormat";

export const TransactionDetailsModal = ({ data }) => {
  return (
    <>
      <Flex color={"#393838"} justify={"space-between"}>
        <Box fontWeight={"semibold"} py={"15px"} textTransform="capitalize">
          <Text>Transaction Type:</Text>
          <Text pt={"15px"}>Requester:</Text>
          <Text pt={"15px"}>Department:</Text>
          <Text pt={"15px"}>Category:</Text>
          <Text pt={"15px"}>Item:</Text>

          <Text pt={"15px"}>Amount Requested:</Text>
          <Text pt={"15px"}>Amount Disbursed:</Text>
          <Text pt={"15px"}>Purpose for Request:</Text>
          <Text pt={"15px"}>Account Disbursed to:</Text>
          <Text pt={"15px"}>Bank Account Holder:</Text>
          <Text pt={"15px"}>Bank Name:</Text>
        </Box>
        <Box fontSize={"1rem"} py={"15px"} textTransform="capitalize">
          <Text color={data?.paymentFor === "payOut" ? "green" : "gold"}>
            {data?.paymentFor === "payOut"
              ? "Pay Out"
              : data?.paymentFor === "payIn"
              ? "Pay In"
              : "N/A"}
          </Text>

          <Text pt={"15px"}>
            {data?.userId?.fullName ||
              data?.requestId?.[0]?.userId?.fullName ||
              "N/A"}
          </Text>
          <Text pt={"15px"}>
            {data?.department?.department ||
              data?.departmentId?.[0]?.department ||
              "N/A"}
          </Text>
          <Text pt={"15px"}>
            {data?.category?.name ||
              data?.requestId?.[0]?.category?.name ||
              "N/A"}
          </Text>
          <Text pt={"15px"}>{data?.item || "N/A"}</Text>

          <Text pt={"15px"}>{formatToNaira(data?.amount)}</Text>
          <Text pt={"15px"}>{formatToNaira(data?.amount)}</Text>
          <Text pt={"15px"}>
            {data?.requestId?.description ||
              data?.rquestId?.[0]?.description ||
              "N/A"}
          </Text>
          <Text pt={"15px"}>
            {data?.userAccount?.accountNumber ||
              data?.requestId?.[0]?.userAccount?.accountNumber ||
              "N/A"}
          </Text>
          <Text pt={"15px"}>
            {data?.userAccount?.accountName ||
              data?.requestId?.[0]?.userAccount?.accountName ||
              "N/A"}
          </Text>
          <Text pt={"15px"}>
            {data?.userAccount?.bankName ||
              data?.requestId?.[0]?.userAccount?.bankName ||
              "N/A"}
          </Text>
        </Box>
      </Flex>
      <Box>
        <Text>Approvers</Text>
        <Flex width="100%" wrap="wrap" gap="1rem">
          {data?.approvers?.map((approver) => (
            <Text p=".1rem .5rem" bg="#F4F4F4" borderRadius=".2rem">
              {approver?.approverId?.fullName}
            </Text>
          ))}
        </Flex>
      </Box>
    </>
  );
};
