import { Tab, TabList } from "@chakra-ui/react";
import React from "react";

const TabHeadings = ({ tabHeadings, setTabName }) => {
  return (
    <TabList>
      {tabHeadings.map((tab, index) => (
        <Tab
          key={index}
          pt="5 !important"
          pb="1 !important"
          _selected={{
            borderBottom: "8px solid #5E6CE7",
            color: "#030007",
            fontWeight: "bold",
          }}
          onClick={(e) => {
            e.preventDefault();
            setTabName(tab);
          }}
        >
          {tab}
        </Tab>
      ))}
    </TabList>
  );
};

export default TabHeadings;
