import { Stack, Box, Flex } from "@chakra-ui/react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AccountSettings from "../pages/AccountSettings";
import AccountsPage from "../pages/AccountsPage";
import CategoriesPage from "../pages/CategoriesPage";
import DepartmentsPage from "../pages/DepartmentsPage";
import Support from "../pages/Support";
import MessagesPage from "../pages/MessagesPage";

import OverviewPage from "../pages/OverviewPage";
import RequestsPage from "../pages/RequestsPage";
import StaffPage from "../pages/StaffPage";
import StaffTransactionDetailsPage from "../pages/StaffTransactionDetailsPage";
import TransactionDetailsPage from "../pages/TransactionDetailsPage";
import Transactionspage from "../pages/Transactionspage";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { connect, disconnect } from "../services/socket";
import { useGetState } from "../contexts/useGetState";
import FullPageLoader from "./fullPageLoader";
import RequestQuotesPage from "../pages/RequestQuotePage";
import SchedulePaymentPage from "../pages/SchedulePaymentPage";
import RequestQuoteList from "../pages/RequestQuoteList";
import Analytics from "../pages/Analytics";

function AuthenticatedRoutes() {
  const { state } = useGetState();
  const [showSidebar, setShowSidebar] = useState(() =>
    window.innerWidth < 720 ? false : true
  );
  const [tabWidth, setTabWidth] = useState("80px");
  const [isLoading, setIsLoading] = useState(true);
  const userId = state?.userInfo?._id;

  // const { userIsActive, resetTimer } = useIdleTimeout(600000, 300000); // 10 minutes and 5 minutes (in milliseconds)

  useEffect(() => {
    connect({ userId, type: "Admin" }).then(() => {
      setIsLoading(false);
    });

    return disconnect;
  }, [userId]);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth <= 720 ? setShowSidebar(false) : setShowSidebar(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabWidth]);

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Suspense fallback={<p></p>}>
      <BrowserRouter>
        <Stack direction={"row"} position="relative" width="100%">
          {showSidebar && (
            <Box
              width={["", "", "", tabWidth, "260px"]}
              position={"fixed"}
              left={"0"}
              zIndex="1032"
            >
              <SideBar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                setTabWidth={setTabWidth}
                tabWidth={tabWidth}
              />
            </Box>
          )}

          <Flex width="100%" position={"relative"} top="0">
            <Stack
              flex="1"
              alignItems={"center"}
              borderRadius="2px"
              position={"fixed"}
              right={[0, 0, 0, "0px", "0"]}
              left={[0, 0, 0, "80px", "200px"]}
              zIndex="1022"
            >
              <NavBar
                showSidebar={showSidebar}
                setTabWidth={setTabWidth}
                setShowSidebar={setShowSidebar}
              />
            </Stack>
            <Box
              width={["100%", "80%", "80%", "88%", "80%"]}
              position={"relative"}
              top="80px"
              left={[0, 0, 0, "80px", "260px"]}
              color="#000"
              p={["30px", "30px", "30px", "30px 10px", "30px"]}
              overflowX={"hidden !important"}
            >
              <Routes>
                <Route path="/" element={<OverviewPage />} />
                <Route path="/staff" element={<StaffPage />} />
                <Route path="/departments" element={<DepartmentsPage />} />
                <Route path="/categories" element={<CategoriesPage />} />
                <Route path="/messages" element={<MessagesPage />} />
                <Route path="/requests" element={<RequestsPage />} />
                <Route path="/vendors" element={<AccountsPage />} />
                <Route path="/request-quotes" element={<RequestQuotesPage />} />
                <Route path="/support" element={<Support />} />

                <Route
                  path="/request-quotes/list"
                  element={<RequestQuoteList />}
                />
                <Route
                  path="/payments-schedule"
                  element={<SchedulePaymentPage />}
                />
                <Route path="/transactions" element={<Transactionspage />} />
                <Route path="/account-settings" element={<AccountSettings />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route
                  path="/transactions/:id"
                  element={<TransactionDetailsPage />}
                />
                <Route
                  path="/staff/:id"
                  element={<StaffTransactionDetailsPage />}
                />
              </Routes>
            </Box>
          </Flex>
        </Stack>
      </BrowserRouter>
    </Suspense>
  );
}

export default AuthenticatedRoutes;
