import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Input } from "@chakra-ui/react";
import { IoArrowBackOutline } from "react-icons/io5";

import { CustomBtn } from "../CustomBtn";
import { verifyOTP } from "../../services/login";

const VerifyOtp = ({ userDetail, setNavigateTo }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const handleOTP = async () => {
    setLoading(true);
    const payload = {
      otp,
      userDetail,
    };
    await verifyOTP(payload, setLoading, setNavigateTo);
  };
  return (
    <Box
      p={["20px", "20px", "50px"]}
      bg="#fff"
      textAlign={"center"}
      width={["80%", "80%", "60%", "40%"]}
      borderRadius={"10px"}
      mt="20px"
    >
      <IoArrowBackOutline onClick={() => navigate("/")} />
      <Heading as="h2" fontSize={"22px"} mb="5px">
        Enter the OTP sent to your Email
      </Heading>
      <Box mt="40px">
        <Input
          mb="40px"
          height={"45px"}
          width="100%"
          placeholder="Enter Your OTP"
          border="1px solid #3C7B79B8"
          type={"number"}
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
        />
        <CustomBtn
          width={["200px", "200px", "300px"]}
          text={"Verify"}
          handleClick={handleOTP}
          loading={loading}
          disabled={!otp}
        />
      </Box>
    </Box>
  );
};

export default VerifyOtp;
