import React from "react";
import { Flex } from "@chakra-ui/react";
import RequestDateField from "./RequestDateField";

const FilterSection = ({
  startDate,
  endDate,
  setEndDate,
  setStartDate,
  handleSearch,
}) => {
  return (
    <Flex justify={"space-between"} align="center" width={["100%", "100%", "100%", "45%", "43%"]}>
      <RequestDateField
        width={"49%"}
        text={"From"}
        date={startDate}
        setDate={setStartDate}
      />
      <RequestDateField
        width={"44%"}
        text={"To"}
        date={endDate}
        setDate={setEndDate}
        handleSearch={handleSearch}
      />
    </Flex>
  );
};

export default FilterSection;
