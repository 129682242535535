import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Heading, Input } from "@chakra-ui/react";

import { CustomBtn } from "../CustomBtn";
import { resetPassword } from "../../services/login";

const ConfirmPassowrd = ({ userDetails }) => {
  const [passwordValues, setPasswordValues] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    setLoading(true);
    const payload = {
      email: userDetails,
      newPassword: passwordValues,
    };
    console.log(payload);
    await resetPassword(payload, setLoading, navigate);
  };

  return (
    <Box
      p={["20px", "20px", "50px"]}
      bg="#fff"
      textAlign={"center"}
      width={["80%", "80%", "60%", "40%"]}
      borderRadius={"10px"}
      mt="20px"
    >
      <Heading as="h2" fontSize={"22px"} mb="5px">
        Enter Your New Password
      </Heading>
      <Box mt="40px">
        <Input
          mb="40px"
          height={"45px"}
          width="100%"
          placeholder="Enter New Password"
          border="1px solid #3C7B79B8"
          type={"password"}
          name="newPassword"
          value={passwordValues}
          onChange={(e) => setPasswordValues(e.target.value)}
        />

        <CustomBtn
          width={["200px", "200px", "300px"]}
          text={"Confirm"}
          handleClick={handleLogin}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default ConfirmPassowrd;
