import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BoxShadowContainer from "./BoxShadowContainer";
import CategoryExpensesChart from "./charts/CategoryExpensesChart";

export const CategoryExpensesCard = ({
  dailyCategoryExpense,
  monthlyCategoryExpense,
}) => {
  const [selectedOption, setSelectedOption] = useState("monthly");
  const [currentData, setCurrentData] = useState([]);
  const [itemColors, setItemColors] = useState([]);

  useEffect(() => {
    if (selectedOption === "daily") {
      setCurrentData(dailyCategoryExpense);
    } else if (selectedOption === "monthly") {
      setCurrentData(monthlyCategoryExpense);
    }
  }, [selectedOption, dailyCategoryExpense, monthlyCategoryExpense]);
  // color
  useEffect(() => {
    const colors = currentData.map(() => getRandomColor());
    setItemColors(colors);
  }, [currentData]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };
  // end
  const reFormatExpense = currentData?.map((data, index) => ({
    ...data,
    color: itemColors[index],
  }));
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <BoxShadowContainer w={["100%", "100%", "100%"]} color={"#393838"}>
      <Flex justify={"space-between"} w={"100%"} align={"center"}>
        <Box>
          <Heading fontSize={"25px"}>Category Expenses</Heading>
        </Box>
        <Select
          placeholder="Daily"
          color={"#3C7B79"}
          borderColor={"#3C7B79"}
          w={"200px"}
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <option value="monthly">Monthly</option>
          <option value="daily">Daily</option>
        </Select>
      </Flex>
      <Flex w={"100%"} align={"center"} justify={"center"}>
        <Flex
          w={["100%", "100%", "60%"]}
          justify={"space-between"}
          flexWrap={"wrap"}
        >
          {reFormatExpense.map((datum, index) => {
            return (
              <Flex align={"center"} pb={"10px"} key={index}>
                <Box
                  h="11px"
                  w="12px"
                  rounded={"sm"}
                  bg={datum?.color}
                  mr="10px"
                ></Box>
                <Flex justify={"space-between"} w="100%">
                  <Text>{datum?.name}</Text>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
      <CategoryExpensesChart data={reFormatExpense} itemColors={itemColors} />
    </BoxShadowContainer>
  );
};
