import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const createQuotation = async (
  payload,
  setLoading,
  setRefresh,
  onClose
) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_QUOTATION, payload);
    successNotifier("QUOTATION Created Successfully");
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e?.response?.data?.errors?.message || e?.response?.data?.message
      );
    } else {
      errorNotifier("Network error, please check your internet connections");
    }
  } finally {
    setLoading(false);
  }
};

export const getQuotation = async (setLoading, setQuotation) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_QUOTATION);
    setQuotation(data);
  } catch (err) {
    if (err?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  } finally {
    setLoading(false);
  }
};

export const searchQuotation = async (
  setSchedule,
  keyword,
  setLoading,
  setNotFoundError
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_QUOTATION(keyword));
    setSchedule(data);
    console.log(data);
  } catch (e) {
    setNotFoundError((prev) => !prev);
  } finally {
    setLoading(false);
  }
};
