import {
  Box,
  Tabs,
  Tab,
  TabList,
  Flex,
  Text,
  TabPanel,
  TabPanels,
  Circle,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { getNotifications } from "../../services/notifications";
import FullPageLoader from "../fullPageLoader";
import { NotificationList } from "./NotificationList";

const NotificationContent = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const TAB_TITLES = [
    { name: "All", selected: "#686278" },
    { name: "Requisition Requests", selected: "#686278" },
  ];

  useEffect(() => {
    getNotifications(setNotifications, setLoading);
  }, [refresh]);

  const RequisitionRequestData = notifications?.filter(
    (notification) => notification?.title === "Requistion"
  );
console.log(notifications);
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Tabs mt="30px">
        <TabList>
          {TAB_TITLES.map((tab, id) => {
            return (
              <Tab
                key={id}
                outline="none !important"
                _focus={{ boxShadow: "none" }}
                px={[2, 2, 4, 8]}
                color="#B5B5B5"
                _selected={{
                  borderBottomColor: tab.selected,
                  color: tab.selected,
                  borderBottom: `4px solid ${tab.selected}`,
                  fontWeight: "bold",
                }}
              >
                <Flex alignItems="center">
                  <Text
                    fontSize={["xs", "sm", "md"]}
                    padding={["", "", "", "0 10px"]}
                  >
                    {tab.name}
                  </Text>
                  {tab.name === "All" ? (
                    <Circle
                      width="30px"
                      fontSize={".7em"}
                      bg={"#154141"}
                      color="#fff"
                    >
                      {notifications?.length}
                    </Circle>
                  ) : (
                    <Circle
                      width="30px"
                      fontSize={".7em"}
                      bg={"#154141"}
                      color="#fff"
                    >
                      {RequisitionRequestData?.length}
                    </Circle>
                  )}
                </Flex>
              </Tab>
            );
          })}
        </TabList>

        <TabPanels py={4}>
          <TabPanel>
            <NotificationList data={notifications} setRefresh={setRefresh} />
          </TabPanel>

          <TabPanel>
            <NotificationList
              data={RequisitionRequestData}
              setRefresh={setRefresh}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NotificationContent;
