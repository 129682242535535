import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getCategories = async (setCategories, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_CATEGORIES);
    setCategories(data);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const createCategories =
  (setLoading, setRefresh, onClose) => async (payload) => {
    try {
      await http.post(AUTH_ROUTES.CREATE_CATEGORY, payload);
      successNotifier("Category successfully added");
      setRefresh((prev) => !prev);
      onClose();
    } catch (e) {
      if (e.response) {
        if (e.response?.data?.message === "Unable to verify token.") {
          errorNotifier("Session timed out");
          window.location.reload();
        } else {
          errorNotifier(
            e?.response?.data?.message || e?.response?.data?.data?.message
          );
        }
      }
      errorNotifier("Network error, Please check your internet connection");
    } finally {
      setLoading(false);
    }
  };

export const deleteCtegory = async (categoryId, setLoading, setRefresh) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_CATEGORY(categoryId));
    successNotifier("Category Deleted Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const updateCategory =
  (setLoading, setRefresh, onClose) => async (payload) => {
    try {
      await http.put(AUTH_ROUTES.UPDATE_CATEGORY(payload?._id), payload);
      successNotifier("Category Updated Successfully");
      onClose();
      setRefresh((prev) => !prev);
    } catch (e) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } finally {
      setLoading(false);
    }
  };
