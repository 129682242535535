import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BoxShadowContainer from "./BoxShadowContainer";
import { ExpensePieChart } from "./charts/ExpensePieChart";
import { DailyTab } from "./DailyTab";
import { MonthlyTab } from "./MonthlyTab";
import { getExpensePerItem } from "../../services/analytics";

export const ChartRows = ({
  setLoading,
  dailyCategoryExpense,
  monthlyCategoryExpense,
}) => {
  const [period, setPeriod] = useState("monthly");
  const [expensePerItem, setExpensePerItem] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [itemColors, setItemColors] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getExpensePerItem(setExpensePerItem, setLoading);
  }, [setLoading]);

  useEffect(() => {
    if (expensePerItem && expensePerItem.length > 0) {
      setSelectedCategory(expensePerItem[0]?.category);
      const colors = monthlyCategoryExpense.map(() => getRandomColor());
      setItemColors(colors);
    }
  }, [expensePerItem, monthlyCategoryExpense]);

  useEffect(() => {
    if (expensePerItem && expensePerItem.length > 0) {
      const filteredData = expensePerItem.find(
        (category) => category.category === selectedCategory
      );

      const data =
        filteredData?.items.map((item) => ({
          name: item.item,
          value: item.monthlySums[0].totalAmount,
        })) || [];

      setChartData(data);
    }
  }, [expensePerItem, selectedCategory]);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  console.log(monthlyCategoryExpense, "itemColors", itemColors);

  return (
    <Flex
      justify={"space-between"}
      direction={["column", "column", "column", "row"]}
    >
      <BoxShadowContainer
        w={["100%", "100%", "100%", "48%"]}
        h={"350px"}
        overflowY={["scroll", "scroll", "hidden"]}
      >
        <Flex
          align={"center"}
          justify={"space-between"}
          direction={["column", "column", "row"]}
        >
          <Heading fontSize={"25px"}>Expenses on Categories</Heading>
          <Flex gap={["6rem", "6rem", "2rem"]} pt={["10px", "10px", "0px"]}>
            <Text
              fontWeight="semibold"
              cursor="pointer"
              borderBottom={period === "monthly" && "3px solid #3C7B79"}
              color={period === "monthly" ? "#3C7B79" : "#A0A0A0"}
              onClick={() => setPeriod("monthly")}
            >
              Monthly
            </Text>
            <Text
              fontWeight="semibold"
              cursor="pointer"
              borderBottom={period === "daily" && "3px solid #3C7B79"}
              color={period === "daily" ? "#3C7B79" : "#A0A0A0"}
              onClick={() => setPeriod("daily")}
            >
              Daily
            </Text>
          </Flex>
        </Flex>
        {period === "daily" ? (
          <DailyTab
            dailyCategoryExpense={dailyCategoryExpense}
            period={period}
            itemColors={itemColors}
          />
        ) : (
          <MonthlyTab
            monthlyCategoryExpense={monthlyCategoryExpense}
            period={period}
            itemColors={itemColors}
          />
        )}
      </BoxShadowContainer>

      {/* Right card*/}
      <BoxShadowContainer
        w={["100%", "100%", "100%", "48%"]}
        h={"350px"}
        overflowY={["scroll", "scroll", "hidden"]}
      >
        <Flex justify={"space-between"} align={"center"}>
          <Heading fontSize={"25px"}>Expense on Items</Heading>
          <Select
            placeholder="Select option"
            color={"#3C7B79"}
            borderColor={"#3C7B79"}
            w={["180px", "180px", "200px"]}
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            {expensePerItem.map((data, index) => (
              <option key={index} value={data?.category}>
                {data?.category}
              </option>
            ))}
          </Select>
        </Flex>
        {expensePerItem.map((datum, index) => {
          if (datum?.category === selectedCategory) {
            const labels = chartData.map((data) => data.name);
            const values = chartData.map((data) => data.value);

            return (
              <Flex
                align={"center"}
                justify={"space-between"}
                w={"100%"}
                direction={["column", "column", "row"]}
                key={index}
              >
                <Box>
                  <ExpensePieChart
                    labels={labels}
                    values={values}
                    itemColors={itemColors}
                    chartData={chartData}
                  />
                </Box>
                <Box fontWeight={400} w={["50%", "50%", "40%"]}>
                  {datum?.items.map((item, itemIndex) => (
                    <Flex align={"center"} pb={"10px"} key={itemIndex}>
                      <Box
                        h="11px"
                        w="11px"
                        rounded={"sm"}
                        bg={itemColors[itemIndex]}
                        mr="10px"
                      ></Box>
                      <Text>{item?.item}</Text>
                    </Flex>
                  ))}
                </Box>
              </Flex>
            );
          }
          return null;
        })}
      </BoxShadowContainer>
    </Flex>
  );
};
