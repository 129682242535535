import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getMonthlyAnalyticsData = async (setAnalyticsData, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_ANALYTIC_GRAPH);
    setAnalyticsData(result?.data?.data);
    setLoading(false);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
    setLoading(false);
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getMonthlyExpenses = async (setMonthlyExpenses, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_EXPENSES);
    setMonthlyExpenses(result?.data?.data);
    setLoading(false);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
    setLoading(false);
  }
};

export const getExpenses = async (setExpenses, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_EXPENSES);
    setExpenses(result?.data?.data);
    setLoading(false);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
    setLoading(false);
  }
};

export const getDailyAnalytics = async (setDailyAnalytics, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DAILY_ANALYTICS);
    setDailyAnalytics(result?.data?.data);
    setLoading(false);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
    setLoading(false);
  }
};

export const getDepartmentWithHighestExpenses = async (
  setDepartmentExpenses,
  setLoading
) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DEPARTMENTS_PAYOUTS);
    setDepartmentExpenses(result?.data?.data);
    setLoading(false);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
    setLoading(false);
  }
};


