import React, { useState } from "react";
import { Box, Flex, FormLabel, HStack, Input, Text } from "@chakra-ui/react";
import { BsCalendar2Day } from "react-icons/bs";
import dayjs from "dayjs";

const DateField = ({ text, width, handleSearch, date, setDate }) => {
  const [inputOpacity, setInputOpacity] = useState("0");
  return (
    <Flex
      width={width || "48%"}
      justify={"space-between"}
      align="center"
      borderRadius={"5px"}
    >
      <Text ml="10px" mr="5px">{text}:</Text>
      <HStack
        justifyContent={"space-between"}
        width="100%"
        bg="#ECECEE"
        borderRadius={"5px"}
        p="7px"
        px="15px"
      >
        <Text>{dayjs().format("DD/MM/YYYY")}</Text>
        <FormLabel width={"100%"} htmlFor="profile-pic">
          {" "}
          <BsCalendar2Day color="#000" />
        </FormLabel>
        <Box position={"absolute"} opacity={inputOpacity} zIndex={100}>
          <Input
            marginLeft="-26px"
            id="profile-pic"
            width={"100%"}
            bg="#ECECEE"
            color="#5D5D5E"
            borderRadius={"5px"}
            type="date"
            value={date}
            onChange={(e) => {
              setInputOpacity("1");
              setDate(e.target.value);
              handleSearch(e);
            }}
          />
        </Box>
      </HStack>
    </Flex>
  );
};

export default DateField;
