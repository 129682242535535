import { Box, Flex, Text } from "@chakra-ui/react";
import { CustomBtn } from "../../CustomBtn";
import { formatToNaira } from "../../../utils/numberFormat";
import CustomModal from "../../CustomModal";
import { IoIosArrowForward } from "react-icons/io";
import VendorResonseModal from "./VendorResponseModal";
import { Avatar } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const RespondDetailModal = ({ data }) => {
  let navigate = useNavigate();

  console.log(data);
  const lessVendor = data.vendorResponse
    ?.sort((a, b) => {
      return a?.costAmount - b?.costAmount;
    })
    ?.slice(0, 3);

  return (
    <Box>
      <Text color={"#3C7B79"} pb="20px" fontWeight={"bold"}>
        Request Quote
      </Text>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Category:</Text>
        <Text color="#7D7C7C" textTransform="capitalize">
          {data?.category?.name || data?.categoryInfo?.name || "N/A"}
        </Text>
      </Flex>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Items:</Text>
        <Text color="#7D7C7C" textTransform="capitalize">
          {data?.item || "N/A"}
        </Text>
      </Flex>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Requester:</Text>
        <Text color="#7D7C7C">
          {data?.userId?.fullName || data?.admin?.[0]?.fullName || "N/A"}
        </Text>
      </Flex>
      <Flex justify="space-between" pb="10px">
        <Text color="#393838">Deadline:</Text>
        <Text color="#7D7C7C">
          {dayjs(data?.deadline).format("DD/MM/YYYY") || "N/A"}
        </Text>
      </Flex>
      <Text color="#393838" pb="10px">
        Request Note:
      </Text>
      <Text pb="20px" color="#7D7C7C">
        {data?.requestNote || "N/A"}
      </Text>
      <Box>
        <Text color={"#3C7B79"} fontWeight="bold" pb="20px">
          Vendor's Response
        </Text>
        {lessVendor?.map((datum) => (
          <Flex
            key={datum?._id}
            justify="space-between"
            align="center"
            mb="20px"
            border="1px solid #70707017"
            borderRadius="8px"
            p="8px"
          >
            <Avatar
              name={datum?.name}
              src="https://bit.ly/tioluwani-kolawole"
            />
            <Box>
              <Text>Request Quote Response</Text>
              <Text>{datum?.name || "N/A"}</Text>
              <Flex gap="50px">
                <Text>{dayjs(datum?.createdAt).format("DD/MM/YYYY")}</Text>
                <Text>{formatToNaira(datum?.costAmount) || "N/A"}</Text>
              </Flex>
            </Box>
            <CustomModal
              icon={<IoIosArrowForward size="18px" color={"#154141"} />}
              header={`Vendors Responses`}
              maxH={"500px"}
              overflow={"scroll"}
              datum={datum}
            >
              <VendorResonseModal datum={datum} />
            </CustomModal>
          </Flex>
        ))}
      </Box>
      <Flex mt="20px" justifyContent="center">
        <CustomBtn
          text="Show More"
          mt="20px"
          disabled={data?.vendorResponse?.length < 1 || !data?.vendorResponse}
          handleClick={() => {
            navigate("/request-quotes/list", { state: data });
          }}
        />
      </Flex>
    </Box>
  );
};

export default RespondDetailModal;
