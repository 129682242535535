import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getRequest = async (setRequest, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_ALL_REQUESTS);
    setRequest(result?.data?.data);
    setLoading(false);
  } catch (e) {
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
    setLoading(false);
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getDeclinedRequests = async (setRequests, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DECLINED_REQUESTS);
    setRequests(result?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const getApprovedRequests = async (setRequests, setLoading) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_APPROVED_REQUESTS);
    setRequests(result?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    console.log(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const createRequest = async (payload, setLoading, setRefresh) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_REQUEST, payload);
    successNotifier("Request Created Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
   if(e.response) {
     if (e?.response?.data?.message === "Unable to verify token.") {
       errorNotifier("Session timed out");
       window.location.reload();
     } else {
       errorNotifier(
         e?.response?.data?.message || e?.response?.data?.data?.message
       );
     }
   } else {
    errorNotifier("Network error, please check your internet connection")
   }
  } finally {
    setLoading(false);
  }
};

export const updateRequest = async (payload, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_REQUEST_DETAILS, payload);
    setRefresh((prev) => !prev);
    successNotifier(`Approved successfully`);
  } catch (e) {
   if (e.response) {
     if (e.response?.data?.message === "Unable to verify token.") {
       errorNotifier("Session timed out");
       window.location.reload();
     } else {
       errorNotifier(
         e?.response?.data?.message || e.response?.data?.data?.message
       );
     }
   } else {
     errorNotifier("Please check your network connection");
   }
  } finally {
    setLoading(false);
  }
};

export const searchRequestsByDates = async (
  setData,
  setLoading,
  startDate,
  endDate,
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_REQUESTS(startDate, endDate));
    setLoading(false);
    setData(data);
    return data;
  } catch (e) {
    setLoading(false);
    // errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const makePayOut = async (payload, setLoading, setRefresh) => {
  try {
    await http.post(AUTH_ROUTES.MAKE_PAYOUT, payload);
    successNotifier("Payout made Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    console.log(e, e.response)
   if(e.response) {
     if (e.response?.data?.message === "Unable to verify token.") {
       errorNotifier("Session timed out");
       window.location.reload();
     } else {
       errorNotifier(
         e?.response?.data?.message || e.response?.data?.data?.message
       );
     }
   } else {
    errorNotifier("Please check your network connection")
   }
  } finally {
    setLoading(false);
  }
};
