import { useState } from "react";
import { CustomBtn } from "../CustomBtn";
import { CustomInputGroup } from "../FormInput";
import { FormControl, Box, Stack, Flex, Text } from "@chakra-ui/react";
import { MdDriveFolderUpload } from "react-icons/md";
import { updatePassword } from "../../services/accountSettings";

function AccountDetails({ userInfo, setRefresh }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    id: userInfo?._id,
  });
  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await updatePassword(formData, setLoading, setRefresh);
  };

  return (
    <Box w={["100%", "100%", "75vw"]}>
      <Box bg="white" p="20px" color="#272A3A">
        <Text fontWeight="Bold">Account Details</Text>
      </Box>
      <Stack p="10px 20px">
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection={["column", "column", "row"]}
          >
            <FormControl w={["100%", "100%", "30%"]}>
              <CustomInputGroup
                label="Present Password"
                type="password"
                name="oldPassword"
                value={formData.presentPassword}
                handleChange={handleChange}
              />
            </FormControl>

            <FormControl w={["100%", "100%", "30%"]}>
              <CustomInputGroup
                type="password"
                label="New Password"
                name="newPassword"
                value={formData.newPassword}
                handleChange={handleChange}
              />
            </FormControl>

            <FormControl w={["100%", "100%", "30%"]}>
              <CustomInputGroup
                type="password"
                label="Confirm Password"
                name="confirmPassword"
                focusBorderColor="#C2C2C2"
                value={formData.confirmPassword}
                handleChange={handleChange}
              />
            </FormControl>
          </Box>

          <Flex justifyContent="flex-end" mt={4}>
            <CustomBtn
              bg="btnbg"
              text="Update"
              childComp={<MdDriveFolderUpload size={20} color="white" />}
              type="submit"
              loading={loading}
              disabled={
                !formData.oldPassword ||
                !formData.confirmPassword ||
                !formData.newPassword ||
                formData.confirmPassword !== formData.newPassword
              }
            />
          </Flex>
        </form>
      </Stack>
    </Box>
  );
}

export default AccountDetails;
