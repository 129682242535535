import { useEffect, useState } from "react";
import AccountDetails from "../components/account/AccountDetails";
import GeneralSpending from "../components/account/GeneralSpending";
import { Box, Heading, Flex } from "@chakra-ui/react";
import FullPageLoader from "../components/fullPageLoader";
import UserProfile from "../components/account/UserProfile";
import { getUserProfile } from "../contexts/getUserProfile";
import { getCompanyDetails } from "../services/accountSettings";
import ApprovalNumber from "../components/account/ApprovalNumber";



const AccountSettings = () => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    getUserProfile(setData).then((data) =>
      getCompanyDetails(setCompanyDetails, setLoading, data?.companyId)
      
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width={["100%", "100%", "80vw", "75vw"]}>
      <Heading as="h2" fontSize={["20px", "22px", "30px"]} mb="30px">
        Account Settings
      </Heading>
      <Flex
        direction={["column", "column", "column", "row"]}
        width={["100%"]}
        gap="20px"
      >
        <UserProfile state={data} setRefresh={setRefresh} />
        <Box bg="#fff" borderRadius="5px">
          <GeneralSpending
            setRefresh={setRefresh}
            userInfo={data?.userInfo}
            companyDetails={companyDetails}
          />
        </Box>
      </Flex>

      <Box bg="#fff" mt="20px" borderRadius="5px" mb="20px">
        <AccountDetails setRefresh={setRefresh} userInfo={data?.userInfo} />
      </Box>

      <ApprovalNumber
        setRefresh={setRefresh}
        userInfo={data?.userInfo}
      />
    </Box>
  );
};

export default AccountSettings;
