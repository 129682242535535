import {
  Box,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomBtn } from "./CustomBtn";
import { FormInputs } from "./FormInput";
import { createDepartment, updateDepartment } from "../services/department";

const DepartmentModalContent = ({ text, setRefresh, data, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [formValues, setformValues] = useState({
    deptId: data?._id,
    department: data?.department,
    spendingLimit: data?.spendingLimit,
    duration: data?.duration || "monthly",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = { ...formValues };
    !data
      ? await createDepartment(payload, setLoading, setRefresh)
      : await updateDepartment(payload, setLoading, setRefresh);

    onClose();
  };

  return (
    <Box width="100%">
      <FormInputs
        type="text"
        name="department"
        label="Department Name"
        value={formValues.department}
        handleChange={handleChange}
      />

      <Flex width="100%" justify={"space-between"} mt="20px" my="30px">
        <Box width="48%">
          <FormLabel fontSize={"18px"}>Set Spending Limits</FormLabel>
          <InputGroup>
            <InputLeftAddon children={"₦"} height="48px" />
            <Input
              height="48px"
              name="spendingLimit"
              label="Set Spending Limits"
              type="text"
              value={formValues.spendingLimit}
              onChange={handleChange}
            />
          </InputGroup>
        </Box>
        <Box width="48%">
          <FormLabel fontSize={"18px"}>Duration</FormLabel>
          <Select
            height="48px"
            name="duration"
            value={formValues?.duration}
            onChange={handleChange}
          >
            <option>Select Duration</option>
            <option value="monthly">Monthly</option>
            <option value="weekly" disabled>Weekly</option>
            <option value="daily" disabled>Daily</option>
          </Select>
        </Box>
      </Flex>

      <Flex width="100%" justify="center">
        <CustomBtn
          height="48px"
          text={text}
          width="80%"
          loading={loading}
          handleClick={handleSubmit}
          disabled={
            !formValues.department || !formValues.spendingLimit
            // !formValues.duration
          }
        />
      </Flex>
    </Box>
  );
};

export default DepartmentModalContent;
