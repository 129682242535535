import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import { FormInputs } from "./FormInput";
import { CustomBtn } from "./CustomBtn";
import { refundWallet } from "../services/refundWallet";
import { useGetState } from "../contexts/useGetState";
import PaystackModal from "./PaystackModal";

const RefundWalletModal = ({ text, setRefresh, onClose }) => {
  const { state } = useGetState();

  const [formValues, setFormValues] = useState({
    userType: "Admin",
    userId: state?.userInfo?._id,
    email: state?.userInfo?.email,
    amount: "",
    paymentFor: "payIn",
  });

  const [paymentData, setPaymentData] = useState({});
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleSubmit = async () => {
    setPaymentLoading(true);

    const payload = { ...formValues };
    await refundWallet(payload)(setPaymentLoading)(setRefresh)(setPaymentModal)(
      setPaymentData
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleSubmit = async () => {
  //   setLoading(true);
  //   const payload = { ...formValues };
  //   await refundWallet(payload, setLoading, setRefresh);
  // };

  return (
    <Box>
      <FormInputs
        type="number"
        name="amount"
        placeholder="Please enter amount"
        value={formValues.amount}
        handleChange={handleChange}
        label="Amount"
      />
      <Box align="center" mt="40px">
        <CustomBtn
          text={text}
          width="250px"
          height="48px"
          loading={paymentLoading}
          handleClick={handleSubmit}
          disabled={!formValues.amount}
        />
        <PaystackModal
          paymentData={paymentData}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          setRefresh={setRefresh}
          onClose={onClose}
        />
      </Box>
    </Box>
  );
};

export default RefundWalletModal;
