import {
  Box,
  Divider,
  Flex,
  Image,
  Progress,
  Select,
  Text,
} from "@chakra-ui/react";
import wallet from "../assets/wallet.svg";
import expense from "../assets/expense.svg";
import React, { useEffect, useState } from "react";
import OverviewTable from "../components/table/overview/OverviewTable";
import { LineChart } from "../components/LineChart";
import { Link } from "react-router-dom";
import { CustomBtn } from "../components/CustomBtn";
import CustomModal from "../components/CustomModal";
import RefundWalletModal from "../components/RefundWalletModal";
import { getRequest } from "../services/request";
import FullPageLoader from "../components/fullPageLoader";
import { useNavigate } from "react-router-dom";
import {
  getDailyAnalytics,
  getDepartmentWithHighestExpenses,
  getExpenses,
  getMonthlyAnalyticsData,
  getMonthlyExpenses,
} from "../services/overview";
import { formatToNaira } from "../utils/numberFormat";
import { useGetState } from "../contexts/useGetState";
import dayjs from "dayjs";


const OverviewPage = () => {
  const navigate = useNavigate()
  const { state } = useGetState();
  const [duration, setDuration] = useState("monthy");
  const [request, setRequest] = useState([]);
  const [, setExpenses] = useState({});
  const [monthlyExpenses, setMonthlyExpenses] = useState({
    Expenses: [],
    walletBalance: "",
  });
  const [monthlyAnalyticsData, setMonthlyAnalyticsData] = useState([]);
  const [dailyAnalytics, setDailyAnalytics] = useState([]);
  const [departmentWithHighestExpenses, setDepartmentWithHighestExpenses] =
    useState({});
  const [graphData, setGraphData] = useState({
    xAxis: [],
    yAxis: [],
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    getExpenses(setExpenses, setLoading);
    getMonthlyExpenses(setMonthlyExpenses, setLoading);
    getDailyAnalytics(setDailyAnalytics, setLoading);
    getMonthlyAnalyticsData(setMonthlyAnalyticsData, setLoading);
    getDepartmentWithHighestExpenses(
      setDepartmentWithHighestExpenses,
      setLoading
    );
    getRequest(setRequest, setLoading);
    setGraphData({
      xAxis: monthlyXAxis,
      yAxis: monthlyYAxis,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  const monthlyXAxis =
    monthlyAnalyticsData?.totalFundsDisbursed &&
    Object.keys(monthlyAnalyticsData?.totalFundsDisbursed);
  const monthlyYAxis =
    monthlyAnalyticsData?.totalFundsDisbursed &&
    Object.values(monthlyAnalyticsData?.totalFundsDisbursed);

  const dailyXAxis =
    dailyAnalytics?.totalFundDisbursed &&
    Object.keys(dailyAnalytics?.totalFundDisbursed);
  const dailyYAxis =
    dailyAnalytics?.totalFundDisbursed &&
    Object.values(dailyAnalytics?.totalFundDisbursed);

  const handleChange = (e) => {
    const { value } = e.target;
    setDuration(value);
    if (value === "daily") {
      setGraphData({
        xAxis: dailyXAxis,
        yAxis: dailyYAxis,
      });
    } else {
      setGraphData({
        xAxis: monthlyXAxis,
        yAxis: monthlyYAxis,
      });
    }
  };

  const arr = Object.keys(departmentWithHighestExpenses)?.map((key) => ({
    id: key,
    ...departmentWithHighestExpenses[key],
  }));

  const highestValueObject = arr
    ?.filter((ar) => ar?.id !== "generalSpending")
    ?.reduce(
      (prev, current) =>
        prev?.amountSpent > current?.amountSpent ? prev : current,
      arr?.[0]
    );

  return loading ? (
    <FullPageLoader />
  ) : (
    <Flex
      width="100%"
      justify={"space-between"}
      direction={["column-reverse", "column-reverse", "column", "row"]}
      mt="20px"
    >
      {/*Left side*/}
      <Box width={["100%", "100%", "100px", "30%"]}>
        <Box
          bg={"#FFFFFF"}
          p={"15px 20px"}
          shadow={"md"}
          rounded={"md"}
          lineHeight={1.5}
          mb={"20px"}
        >
          <Text color={"#000000"} fontSize={"1.5rem"} fontWeight="semibold">
            {state?.companyDetails?.[0]?.paymentAccount?.bankName}
          </Text>
          <Text color={"#575555"} fontSize={"1rem"} fontWeight="semibold">
            {state?.companyDetails?.[0]?.paymentAccount?.accountNumber}
          </Text>
          <Text color={"#575555"} fontSize={"1rem"} fontWeight={400}>
            {state?.companyDetails?.[0]?.paymentAccount?.accountName}
          </Text>
        </Box>
        {/**Wallet remaining balance*/}
        <Box
          bg={"#DAE7E7"}
          p={"15px 20px"}
          shadow={"md"}
          rounded={"md"}
          lineHeight={1.5}
          mb={"25px"}
        >
          <Flex justify={"space-between"}>
            <Text color={"#000000"} fontSize={"2rem"} fontWeight={"bold"}>
              {formatToNaira(monthlyExpenses?.walletBalance?.toString()) ||
                "--"}
            </Text>
            <Image src={wallet} alt="wallet icon" />
          </Flex>
          <Text color={"#343434"} fontSize={"1rem"} fontWeight={400}>
            Wallet Remaining Balance
          </Text>
          <Flex mt={"20px"} justify={"start"}>
            <CustomModal
              icon={<CustomBtn text="Fund Wallet" />}
              header={`Fund Wallet`}
              iconColor={"#154141"}
            >
              <RefundWalletModal text={"Fund Wallet"} setRefresh={setRefresh} />
            </CustomModal>
          </Flex>
        </Box>
        {/**Expenses card*/}
        <Box
          bg={"#F4E8E8"}
          p={"15px 20px"}
          shadow={"md"}
          cursor="pointer"
          rounded={"md"}
          onClick={() => {
            navigate("/analytics");
          }}
          lineHeight={1.5}
          mb={"25px"}
        >
          <Flex justify={"space-between"}>
            <Text color={"#F80404"} fontSize={"1.5rem"} fontWeight={"semibold"}>
              Expenses
            </Text>
            <Image src={expense} alt="wallet icon" />
          </Flex>
          <Text color={"#000000"} fontSize={"2rem"} fontWeight={"bold"}>
            {departmentWithHighestExpenses?.generalSpending &&
              formatToNaira(departmentWithHighestExpenses?.generalSpending)}
          </Text>
          <Text color={"#575555"} fontSize={"1rem"} fontWeight={400}>
            Total Expense this month
          </Text>
          <Text color={"#575555"} fontSize={"1rem"} fontWeight={"semibold"}>
            {departmentWithHighestExpenses?.generalSpending &&
              state?.companyDetails?.[0]?.spendingLimit &&
              parseFloat(
                (departmentWithHighestExpenses?.generalSpending * 100) /
                  state?.companyDetails?.[0]?.spendingLimit
              ).toFixed(1)}
            %
          </Text>
        </Box>
        {/*Daily Spending Limits*/}
        <Box
          bg={"#FFFFFF"}
          pt={"15px"}
          shadow={"md"}
          rounded={"md"}
          lineHeight={1.5}
          mb={"20px"}
        >
          <Text
            color={"#000000"}
            fontSize={"1.45rem"}
            fontWeight={"semibold"}
            px="20px"
          >
            Monthly Spending Limits
          </Text>
          <Box px="20px">
            <Flex
              justify={"space-between"}
              color={"#3A3A3A"}
              fontWeight={"semibold"}
              pt={"30px"}
              pb={"10px"}
            >
              <Text>General</Text>
              <Text>
                {departmentWithHighestExpenses?.generalSpending &&
                  state?.companyDetails?.[0]?.spendingLimit &&
                  parseFloat(
                    (departmentWithHighestExpenses?.generalSpending * 100) /
                      state?.companyDetails?.[0]?.spendingLimit
                  ).toFixed(1)}
                %
              </Text>
            </Flex>
            <Progress
              colorScheme={
                parseFloat(
                  (departmentWithHighestExpenses?.generalSpending &&
                    state?.companyDetails?.[0]?.spendingLimit &&
                    departmentWithHighestExpenses?.generalSpending * 100) /
                    state?.companyDetails?.[0]?.spendingLimit
                ).toFixed(1) <= 50
                  ? "green"
                  : parseFloat(
                      (departmentWithHighestExpenses?.generalSpending &&
                        state?.companyDetails?.[0]?.spendingLimit &&
                        departmentWithHighestExpenses?.generalSpending * 100) /
                        state?.companyDetails?.[0]?.spendingLimit
                    ).toFixed(1) >= 51 &&
                    parseFloat(
                      (departmentWithHighestExpenses?.generalSpending &&
                        state?.companyDetails?.[0]?.spendingLimit &&
                        departmentWithHighestExpenses?.generalSpending * 100) /
                        state?.companyDetails?.[0]?.spendingLimit
                    ).toFixed(1) <= 75
                  ? "orange"
                  : "red"
              }
              value={
                departmentWithHighestExpenses?.generalSpending &&
                state?.companyDetails?.[0]?.spendingLimit &&
                parseFloat(
                  (departmentWithHighestExpenses?.generalSpending * 100) /
                    state?.companyDetails?.[0]?.spendingLimit
                ).toFixed(1)
              }
              rounded={"full"}
              h={"17px"}
            />
            <Text fontWeight={"semibold"} color={"#3A3A3A"} pt={"10px"}>
              <Text as={"span"} color="#7D6A14">
                {" "}
                {departmentWithHighestExpenses?.generalSpending
                  ? formatToNaira(
                      departmentWithHighestExpenses?.generalSpending
                    )
                  : "--"}
              </Text>{" "}
              Spent of{" "}
              {formatToNaira(state?.companyDetails?.[0]?.spendingLimit)}
            </Text>
          </Box>

          <Divider mt="20px" />
          <Box p={"10px 20px"} bg="#15414111">
            <Text color="green" fontWeight="semibold">
              Highest Spending Department
            </Text>
            <Flex
              justify={"space-between"}
              color={"green"}
              fontWeight={"semibold"}
              pt={"15px"}
              pb={"16px"}
            >
              <Text>{highestValueObject?.department}</Text>
              <Text>
                {highestValueObject?.amountSpent &&
                  parseFloat(
                    (highestValueObject?.amountSpent * 100) /
                      highestValueObject?.spendingLimit
                  ).toFixed(1)}
                %
              </Text>
            </Flex>
            <Progress
              colorScheme={
                parseFloat(
                  (highestValueObject?.amountSpent * 100) /
                    highestValueObject?.spendingLimit
                ).toFixed(1) <= 50
                  ? "green"
                  : parseFloat(
                      (highestValueObject?.amountSpent * 100) /
                        highestValueObject?.spendingLimit
                    ).toFixed(1) >= 51 &&
                    parseFloat(
                      (highestValueObject?.amountSpent * 100) /
                        highestValueObject?.spendingLimit
                    ).toFixed(1) <= 75
                  ? "orange"
                  : "red"
              }
              value={
                highestValueObject?.amountSpent &&
                parseFloat(
                  (highestValueObject?.amountSpent * 100) /
                    highestValueObject?.spendingLimit
                ).toFixed(1)
              }
              rounded={"full"}
              h={"17px"}
              border="1px solid #15414120"
            />
            <Text
              fontWeight={"semibold"}
              color={"#3A3A3A"}
              pt={"10px"}
              pb={"20px"}
            >
              <Text as={"span"} color="#065D50">
                {formatToNaira(highestValueObject?.amountSpent?.toString())}
              </Text>{" "}
              Spent of {formatToNaira(highestValueObject?.spendingLimit)}
            </Text>
          </Box>
        </Box>
      </Box>
      {/*Right Side*/}
      <Box width={["100%", "100%", "100%", "66%"]} pb="20px">
        <Box bg={"#FFFFFF"} p={"20px"} rounded={"md"} shadow={"md"}>
          <Flex justify={"space-between"} align={"center"}>
            <Text color={"#000000"} fontSize={"1.6rem"} fontWeight={"semibold"}>
              Average Expenses
            </Text>
            <Select
              w={"100px"}
              placeholder="Select a duration"
              color={"#636161"}
              bg="#FFFFFF"
              borderColor={"#3C7B79"}
              onChange={(e) => handleChange(e)}
            >
              <option value={"monthly"}>Monthly</option>
              <option value={"daily"}>Daily</option>
              {/* <option value={"yearly"}>Yearly</option> */}
            </Select>
          </Flex>
          <Text
            color={"#575555"}
            fontSize={"1rem"}
            fontWeight={"semibold"}
            pt={"7px"}
          >
            Basic Statistic Presenting Daily, Weekly, Monthly Expenses
          </Text>
          <Text
            color={"#575555"}
            fontSize={"0.8rem"}
            fontWeight={"semibold"}
            display={"flex"}
            justifyContent={"flex-end"}
            pt={"7px"}
          >
            {duration === "monthly" && graphData?.xAxis?.[0]
              ? `From ${graphData?.xAxis?.[0] || "Date"} to ${dayjs().format(
                  "MMM"
                )}`
              : `From ${graphData?.xAxis?.[0] ?? "--"} to ${dayjs().format(
                  "MMM"
                )}`}
          </Text>
          <LineChart
            xAxisData={graphData?.xAxis || monthlyXAxis}
            yAxisData={graphData?.yAxis || monthlyYAxis}
          />
        </Box>
        <Flex justify={"space-between"} py={"16px"} fontWeight={"semibold"}>
          <Text color={"#000000"} fontSize={"1.1rem"}>
            Recent Request
          </Text>
          <Link to="/requests">
            <Text color={"#3C7B79"} fontSize={"1.1rem"}>
              View more
            </Text>
          </Link>
        </Flex>
        <Box width={["100%"]} shadow={"md"}>
          <OverviewTable request={request} />
        </Box>
      </Box>
    </Flex>
  );
};

export default OverviewPage;
