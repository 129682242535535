import { Tbody, Td, Text, Tooltip, Tr, Flex } from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { formatToNaira } from "../../../utils/numberFormat";
import RequestModalContent from "../../RequestModalContent";
import CustomModal from "../../CustomModal";

const RequestTableBody = ({ request, setRefresh }) => {
  return (
    <Tbody>
      {request?.map((data, key) => (
        <Tr key={key} fontSize={"16px"}>
          <Tooltip label={data?.userAccount?.accountName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Flex align="center">
                <Avatar
                  mr="10px"
                  size="xs"
                  name={data?.userAccount?.accountName}
                  src={data?.media}
                  alt={`Avater of ${data?.userAccount?.accountName}`}
                />
                <Text isTruncated maxWidth="170px !important">
                  {data?.userAccount?.accountName}
                </Text>
              </Flex>
            </Td>
          </Tooltip>

          <Td
            borderRight="1px solid #dadedf !important"
            width="170px !important"
            isTruncated
          >
            {dayjs(data?.createdAt).format("DD MMM YYYY")}
          </Td>
          <Td borderRight="1px solid #dadedf !important">
            {formatToNaira(data?.amount)}
          </Td>
          <Tooltip
            isTruncated
            maxWidth="170px !important"
            label={data?.department?.department}
          >
            <Td borderRight="1px solid #dadedf !important">
              {data?.department?.department}
            </Td>
          </Tooltip>
          <Td color="#C9A721" borderRight="1px solid #dadedf !important">
            <Text
              textTransform="capitalize"
              textAlign={"center"}
              bg={
                data?.status?.toLowerCase() === "pending"
                  ? "#FEF4CB"
                  : data?.status?.toLowerCase() === "cancelled"
                  ? "#F5DEDE"
                  : data?.status?.toLowerCase() === "approved"
                  ? "#C7DBDB"
                  : "#F5DEDE"
              }
              color={
                data?.status?.toLowerCase() === "pending"
                  ? "#D6B941"
                  : data?.status?.toLowerCase() === "cancelled"
                  ? "#F67373"
                  : data?.status?.toLowerCase() === "approved"
                  ? "#0D6565"
                  : "#F67373"
              }
              p="2px 5px"
              borderRadius={"10px"}
            >
              {data?.status}
            </Text>
          </Td>
          <Td>
            <CustomModal
              icon={<AiOutlineEye size="18px" color={"#154141"} />}
              header={`Request Details`}
              iconColor={"#154141"}
              data={data}
            >
              <RequestModalContent
                request={data}
                setRefresh={setRefresh}
                data={data}
              />
            </CustomModal>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default RequestTableBody;
