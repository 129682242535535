import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { CategoryRevenueChart } from "./charts/CategoryRevenueChart";

export const MonthlyTab = ({
  monthlyCategoryExpense = [],
  itemColors,
}) => {
  const reFormatDailyCategoryExpense = monthlyCategoryExpense?.map((data) => ({
    ...data,
    color: itemColors,
  }));
  return (
    <Box>
      <Flex
        align={"center"}
        justify={["center", "center", "space-between"]}
        w={"100%"}
        direction={["column", "column", "row"]}
      >
        {!monthlyCategoryExpense || monthlyCategoryExpense.length === 0 ? (
          <Flex
            justify={"center"}
            align={"center"}
            h={"270px"}
            bg="#ECECEE"
            w={"100%"}
          >
            <Text fontWeight={"semibold"} fontSize={"22px"}>
              No data available
            </Text>
          </Flex>
        ) : (
          <>
            <Box>
              <CategoryRevenueChart
                monthlyCategoryExpense={reFormatDailyCategoryExpense}
                itemColors={itemColors}
              />
            </Box>

            <Box fontWeight={400} w={["50%", "50%", "40%"]}>
              {reFormatDailyCategoryExpense?.map((data, index) => {
                return (
                  <Flex align={"center"} pb={"10px"} key={index}>
                    <Box
                      h="11px"
                      w="11px"
                      rounded={"sm"}
                      bg={itemColors[index]}
                      mr="10px"
                    ></Box>

                    <Text>{data?.name}</Text>
                  </Flex>
                );
              })}
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
};
