import React, { useEffect, useState } from "react";
import { Box, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import { Tabs, TabList, Tab } from "@chakra-ui/react";
import RequestFilterSection from "../components/RequestFilterSection";
import RequestTable from "../components/table/request/RequestTable";
import Footer from "../components/Footer";
import {
  getApprovedRequests,
  getDeclinedRequests,
  getRequest,
  searchRequestsByDates,
} from "../services/request";
import { Pagination } from "../components/Pagination";
import FullPageLoader from "../components/fullPageLoader";
import CSVDownload from "../components/CSVDownload";
import { requestCsvHeaders } from "../constants/csvHeaders.constants";
import { requestCsv } from "../utils/csvContents";

const RequestsPage = () => {
  const [request, setRequest] = useState([]);
  const [declinedRequests, setDeclinedRequests] = useState([]);
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [foundRequests, setFoundRequests] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tabName, setTabName] = useState("all");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPge = 20;
  const lastIndex = dataPerPge * currentPage;
  const firstIndex = lastIndex - dataPerPge;

  const requestsByStatus =
    tabName === "all"
      ? request
      : tabName === "declined"
      ? declinedRequests
      : approvedRequests;

  const allRequestsFound =
    foundRequests.length > 0 ? foundRequests : requestsByStatus;
  const records = allRequestsFound.slice(firstIndex, lastIndex);

  const total = dataPerPge * currentPage + records.length - dataPerPge;
  useEffect(() => {
    setLoading(true);
    getRequest(setRequest, setLoading);
    getDeclinedRequests(setDeclinedRequests, setLoading);
    getApprovedRequests(setApprovedRequests, setLoading);
  }, [refresh]);

  const handleSearchByDate = async (e) => {
    await searchRequestsByDates(
      setFoundRequests,
      setLoading,
      startDate,
      e.target.value,
      tabName
    );
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <Flex direction={["column", "column", "row"]} justify="space-between">
        <Heading
          as="h2"
          fontSize={["22px", "22px", "24px", "24px", "30px"]}
          mr={["", "", "", "10px", "0"]}
        >
          Requests
        </Heading>
        <Stack direction={["column", "column", "column", "row"]}>
          <Tabs
            variant="unstyled"
            bg="#fff"
            borderRadius="5px"
            my={["20px", "20px", 0]}
          >
            <TabList px="20px">
              <Tab
                _selected={{ bg: "#3C7B7917" }}
                color="#000000"
                fontSize={["14px", "14px", "18px"]}
                fontWeight="semibold"
                px={["30px", "40px", "40px"]}
                onClick={() => setTabName("all")}
              >
                All
              </Tab>
              <Tab
                _selected={{ bg: "#3C7B7917" }}
                color="#6A6969"
                fontSize={["14px", "14px", "18px"]}
                px={["30px", "40px", "40px"]}
                onClick={() => setTabName("approved")}
              >
                Approved
              </Tab>
              <Tab
                _selected={{ bg: "#3C7B7917" }}
                color="#6A6969"
                fontSize={["14px", "14px", "18px"]}
                px={["30px", "40px", "40px"]}
                onClick={() => setTabName("declined")}
              >
                Declined
              </Tab>
            </TabList>
          </Tabs>
          <RequestFilterSection
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleSearch={handleSearchByDate}
          />
        </Stack>
      </Flex>
      <CSVDownload
        headers={requestCsvHeaders}
        data={requestCsv(allRequestsFound)}
      />
      <RequestTable request={records} setRefresh={setRefresh} />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {allRequestsFound?.length} Requests
        </Text>
        <Pagination
          totalData={allRequestsFound.length ?? 0}
          dataPerPage={dataPerPge}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
      <Footer />
    </Box>
  );
};

export default RequestsPage;
