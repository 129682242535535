import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import FullPageLoader from "../components/fullPageLoader";
import SearchField from "../components/SearchField";
import { getTransactionsByStaffId } from "../services/transactions";
import StaffTransactionDetailsTable from "../components/table/transaction/StaffTransactionDetailsTable";

const StaffTransactionDetailsPage = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
    const {state} = useLocation()

  useEffect(() => {
    getTransactionsByStaffId(setTransactions, id, setLoading);
  }, [id]);

  const handleSearch = () => {};
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Flex width="100%" justify="space-between" alignItems="center" mb="30px">
        <Heading as="h2" fontSize="26">
          Transaction History for {state?.fullName}
        </Heading>
        <Flex width="40%" justify="space-between" align="center">
          <SearchField placeholder="" handleSearch={handleSearch} />
        </Flex>
      </Flex>
      <StaffTransactionDetailsTable
        transactions={transactions}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default StaffTransactionDetailsPage;
