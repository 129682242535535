import { Avatar, Flex, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { formatToNaira } from "../../../utils/numberFormat";
import CustomModal from "../../CustomModal";
import { AiOutlineEye } from "react-icons/ai";
import { TransactionDetailsModal } from "../../tabs/TransactionDetailsModal";

const TransactionDetailsTableBody = ({ data }) => {
  return (
    <Tbody>
      {data?.map((data, key) => (
        <Tr key={key} fontSize={"16px"}>
          <Tooltip label={data?.userId?.fullName || "N/A"}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Flex align="center">
                <Avatar
                  mr="10px"
                  size="xs"
                  name={data?.userId?.fullName}
                  src={data?.userId?.image}
                  alt={`Avater of ${data?.userId?.fullName}`}
                />
                <Text
                  isTruncated
                  maxWidth="170px !important"
                  textTransform="capitalize"
                >
                  {data?.userId?.fullName}
                </Text>
              </Flex>
            </Td>
          </Tooltip>

          {/* <Tooltip
            label={
              data?.departmentId?.department ||
              data?.departmentId?.[0]?.department ||
              "N/A"
            }
          >
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              cursor="pointer"
              // onClick={() =>
              //   data?.departmentId?._id &&
              //   navigate(`/transactions/${data?.departmentId?._id}`)
              // }
            >
              {data?.departmentId?.department ||
                data?.departmentId?.[0]?.department ||
                "N/A"}
            </Td>
          </Tooltip> */}
          <Tooltip
            isTruncated
            maxWidth="170px !important"
            label={
              data?.requestId?.category?.name || data?.categoryId?.[0]?.name || "N/A"
            }
          >
            <Td
              borderRight="1px solid #dadedf !important"
              textTransform="capitalize"
            >
              {data?.requestId?.category?.name || data?.categoryId?.[0]?.name || "N/A"}
            </Td>
          </Tooltip>

          <Td borderRight="1px solid #dadedf !important">
            {formatToNaira(data?.amount)}
          </Td>
          <Td borderRight="1px solid #dadedf !important">
            {dayjs(data?.createdAt).format("DD MMM YYYY")}
          </Td>

          <Td
            color={data?.paymentFor === "payIn" ? "#C9A721" : "green"}
            borderRight="1px solid #dadedf !important"
            textTransform="capitalize"
          >
            <Text>{data?.paymentFor}</Text>
          </Td>
          <Td
            // textAlign={"center"}
            // bg={
            //   data?.status?.toLowerCase() === "pending"
            //     ? "#FEF4CB"
            //     : data?.status?.toLowerCase() === "cancelled"
            //     ? "#F5DEDE"
            //     : data?.status?.toLowerCase() === "approved"
            //     ? "#C7DBDB"
            //     : "#F5DEDE"
            // }
            color={
              data?.status?.toLowerCase() === "pending"
                ? "#D6B941"
                : data?.status?.toLowerCase() === "cancelled"
                ? "#F67373"
                : data?.status?.toLowerCase() === "confirmed"
                ? "#0D6565"
                : "#F67373"
            }
            // p="2px 10px"
            // borderRadius={"10px"}
            textTransform="capitalize"
          >
            {data?.status?.toLowerCase() === "confirmed"
              ? "Disbursed"
              : data?.status}
          </Td>
          <Td>
            <Tooltip lael="More details">
              <CustomModal
                icon={<AiOutlineEye size="18px" color={"#154141"} />}
                header={`Transactions Details`}
                iconColor={"#154141"}
                data={data}
              >
                <TransactionDetailsModal data={data} />
              </CustomModal>
            </Tooltip>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default TransactionDetailsTableBody;
