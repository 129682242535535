import React from "react";
import { Table } from "@chakra-ui/react";
import {  transactionsDetailsTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import TransactionDetailsTableBody from "./TransactionDetailsTableBody";

const TransactionDetailsTable = ({ transactions }) => {
  return (
    <Table variant="striped" colorScheme="gray" bg="#fff" borderRadius={"5px"}>
      <TableHead tableHeadData={transactionsDetailsTableHead} />
      <TransactionDetailsTableBody data={transactions} />
    </Table>
    // </TableContainer>
  );
};

export default TransactionDetailsTable;
