import { Box, HStack } from '@chakra-ui/react'
import React from 'react'
import { PinInput, PinInputField } from "@chakra-ui/react";

function SetPin({handlePinChange, size}) {

  return (
    <Box>
      <HStack spacing="20px">
        <PinInput onChange={handlePinChange} size={size}>
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
    </Box>
  );
}

export default SetPin
