import React, { useEffect, useState } from "react";
import { Box, Flex, useDisclosure, Heading } from "@chakra-ui/react";
import { CustomBtn } from "../components/CustomBtn";
import ContactUsModal from "../components/ContactUs";
import { getSupportTicket, deleteTicket } from "../services/support";
import { useGetState } from "../contexts/useGetState";
import FullPageLoader from "../components/fullPageLoader";
import SupportCard from "../components/SupportCard";

const Notification = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [ticket, setTicket] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { state } = useGetState();
  const [isloading, setIsLoading] = useState(false);

  const _id = state?.userInfo?._id;
  useEffect(() => {
    getSupportTicket(setTicket, _id, setLoading);
  }, [refresh, _id]);

  const handleDelete = async (ticketId) => {
    setIsLoading(true);
    const payload = { isDelete: true };
    await deleteTicket(ticketId, payload, setIsLoading, setRefresh);
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Flex justifyContent={"space-between"}>
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "0px"]}
        >
          Support Messages
        </Heading>
        <CustomBtn text="Send Message" handleClick={onOpen} />
      </Flex>
      <SupportCard
        data={ticket}
        handleDelete={handleDelete}
        isloading={isloading}
        setRefresh={setRefresh}
        setLoading={setLoading}
        onClose={onClose}
      />
      <ContactUsModal
        {...{ isOpen, onClose }}
        setRefresh={setRefresh}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default Notification;
