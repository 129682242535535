import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const addItems = async (
  payload,
  setLoading,
  setRefresh,
  onClose
) => {
  try {
    await http.put(AUTH_ROUTES.ADD_ITEMS(payload?._id), payload);
    successNotifier("Items Created Successfully");
    setRefresh((prev) => !prev);
    onClose();
  } catch (e) {
    errorNotifier(e.response.data.errors.message);
  } finally {
    setLoading(false);
  }
};


export const deleteItem = async (itemId,item, setLoading, setRefresh) => {
  try {
    await http.put(AUTH_ROUTES.DELETE_ITEM(itemId), item);
    successNotifier("Item Deleted Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
