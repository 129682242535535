import React from "react";
import { Table } from "@chakra-ui/react";
import { categoriesTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import CategoriesTableBody from "./CategoriesTableBody";

const CategoriesTable = ({ categories, setRefresh, setCloseModal }) => {
  return (
    <Table variant="striped" colorScheme="gray" bg="#fff" borderRadius={"5px"}>
      <TableHead tableHeadData={categoriesTableHead} />
      <CategoriesTableBody
        data={categories}     
        setRefresh={setRefresh}
        setCloseModal={setCloseModal}
      />
    </Table>
    // </TableContainer>
  );
};

export default CategoriesTable;
