import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { FormInputs } from "./FormInput";
import React, {useState} from "react";
import { deleteItem } from "../services/addItem";
import { CustomBtn } from "./CustomBtn";


function DeleteItem({ text, data, itemId }) {

    console.log(data);
    console.log(itemId);
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    const id = data?._id
     const item_Id= itemId

    const handleDelete = () => {
        setLoading(true);
        deleteItem(id, {"item":item_Id}, setLoading, setRefresh);
      };
    
  return (
    <Box>
      <Text>Are You Sure You Want to Delete This Item ?</Text>
      <Flex align="center" mt="30px" gap="10px" justifyContent="center">
        <CustomBtn text={text || "Delete"} handleClick={handleDelete} />
        <CustomBtn bg="#890e0a" border="none" text={text|| "cancel"} />
      </Flex>
    </Box>
  );
}

export default DeleteItem;
