import { Box, Flex, HStack, Heading, Text } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import React from "react";
import SearchField from "../components/SearchField";
import ResponsePageTable from "../components/table/ResponsePage/ResponsePageTable";
import { Pagination } from "../components/Pagination";
import CSVDownload from "../components/CSVDownload";
import { responseCsvHeaders } from "../constants/csvHeaders.constants";
import { responseCsv } from "../utils/csvContents";
import FullPageLoader from "../components/fullPageLoader";
import { getQuotation, searchQuotation } from "../services/quotation";
import CustomModal from "../components/CustomModal";
import { CustomBtn } from "../components/CustomBtn";
import AddRequestQuoteModal from "../components/requestQoute/AddRequestQuoteModal";
import { getCategories } from "../services/category";
import Notification from "../components/Notification";

const RequestQuotesPage = () => {
  const [categories, setCategories] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [quotation, setQuotation] = useState([]);
  const [, setSearchLoading] = useState(false);
  const [filteredResponse, setFilteredResponse] = useState([]);
  const [notFoundError, setNotFoundError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 20;
  const lastIndex = dataPerPage * currentPage;
  const firstIndex = lastIndex - dataPerPage;
  const responseData =
    filteredResponse.length > 0 ? filteredResponse : quotation;
  const records = responseData.slice(firstIndex, lastIndex);
  const total = dataPerPage * currentPage + records.length - dataPerPage;
  useEffect(() => {
    getQuotation(setLoading, setQuotation);
    getCategories(setCategories, setLoading);
  }, [refresh]);

  const handleSearch = async (e) => {
    setSearchLoading(true);
    const keyword = e.target.value;
    if (!keyword.length) return;
    await searchQuotation(
      setFilteredResponse,
      keyword,
      setSearchLoading,
      setNotFoundError
    );
  };
  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      {notFoundError && <Notification text="No Quotation Found" />}
      <Flex
        width="100%"
        justify={["start", "start", "space-between"]}
        alignItems={["start", "start", "center"]}
        direction={["column", "column", "row"]}
        mb={["20px", "20px", ""]}
      >
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", ""]}
        >
          Request Quote
        </Heading>
        <Box
          width={["100%", "100%", "40%"]}
          justify="space-between"
          align="center"
        >
          <SearchField placeholder="Quotes" handleSearch={handleSearch} />
        </Box>
        <HStack spacing="50px">
          <Box width={["50%", "80%", "100%"]}>
            <CustomModal
              icon={<CustomBtn text="Request Quote" color="#fff" />}
              header={`Request Quote`}
              iconColor={"#154141"}
            >
              <AddRequestQuoteModal
                categories={categories}
                setRefresh={setRefresh}
              />
            </CustomModal>
          </Box>
          <Box>
            <CSVDownload
              headers={responseCsvHeaders}
              data={responseCsv(quotation)}
            />
          </Box>
        </HStack>
      </Flex>
      <ResponsePageTable data={records} />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {responseData?.length} Response
        </Text>
        <Pagination
          totalData={responseData.length ?? 0}
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default RequestQuotesPage;
