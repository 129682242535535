import React, { useState } from "react";
import { FormInputs } from "./FormInput";

function Search({ data, setSelectedItem }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  function performSearch(searchTerm) {
    // Replace this with your own search logic

    return data?.banks?.filter((item) =>
      item?.bankName?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    // Perform the search and update the searchResults state
    const results = performSearch(searchTerm);
    setSearchResults(results);
  };

  const handleSelect = (item) => {
    setSelectedItem(item);
    setSearchTerm(item?.bankName);
    setSearchResults([]);
  };

  return (
    <div>
      
      <FormInputs
        type="text"
        name="bankName"
        label="Bank Name"
        placeholder={"Enter a bank name to search for banks"}
        value={searchTerm}
        handleChange={handleSearch}
      />

      {searchResults.length > 0 && (
        <ul style={{ height: "200px", overflowY: "scroll" }}>
          {searchResults?.map((item) => (
            <li
              key={item?.bankCode}
              onClick={() => handleSelect(item)}
              style={{ cursor: "pointer" }}
            >
              {item?.bankName}
            </li>
          ))}
        </ul>
      )}

      {/* {selectedItem && (
        <div>
          <p>You have selected: {selectedItem.name}</p>
        </div>
      )} */}
    </div>
  );
}

export default Search;
