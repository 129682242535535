import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import AddCategoryModal from "../components/AddCategoryModal";
import { CustomBtn } from "../components/CustomBtn";
import CustomModal from "../components/CustomModal";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import CategoriesTable from "../components/table/categories/CategoriesTable";
import { getCategories } from "../services/category";
import AddItemModal from "../components/AddItemModal";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  // const [closeModal, setCloseModal] = useState(null)
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPge = 20;
  const lastIndex = dataPerPge * currentPage;
  const firstIndex = lastIndex - dataPerPge;
  const records = categories.slice(firstIndex, lastIndex);


  useEffect(() => {
    getCategories(setCategories, setLoading);
  }, [refresh]);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Flex width="100%" justify="space-between" alignItems="center" mb="30px">
        <Heading as="h2" fontSize={["22px", "22px", "30px"]}>
          Categories
        </Heading>
        <Flex gap={6}>
          <CustomModal
            icon={
              <CustomBtn childComp={<AiOutlinePlus />} text="Add Category" />
            }
            header={`Add New Category`}
            iconColor={"#154141"}
          >
            <AddCategoryModal setRefresh={setRefresh} text={`Add Category`} />
          </CustomModal>
          <CustomModal
            icon={
              <CustomBtn
                bg={"transparent"}
                color={"#3C7B79"}
                childComp={<AiOutlinePlus />}
                text="Add New Item"
              />
            }
            header={`Add New Item`}
            iconColor={"#154141"}
          >
            <AddItemModal
              setRefresh={setRefresh}
              text={`Add New Item`}
              categories={categories}
            />
          </CustomModal>
        </Flex>
      </Flex>
      <CategoriesTable
        categories={categories}
        setRefresh={setRefresh}
        // setCloseModal={setCloseModal}
      />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {records?.length} of {categories?.length} Requests
        </Text>
        <Pagination
          totalData={categories.length ?? 0}
          dataPerPage={dataPerPge}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default CategoriesPage;
