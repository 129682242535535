import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FilterSection from "../components/FilterSection";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import CustomTab from "../components/tabs/CustomTab";
import {
  filterTransactions,
  getPayinTransactions,
  getPayoutTransactions,
  searchTransactions,
} from "../services/transactions";

const Transactionspage = () => {
  const [payoutTransactions, setPayoutTransactions] = useState([]);
  const [payinTransactions, setPayinTransactions] = useState([]);
  const [searchedTransactions, setSearchedTransactions] = useState([]);
  const [tabName, setTabName] = useState("Pay Out");
  const [, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPge = 20;
  const lastIndex = dataPerPge * currentPage;
  const firstIndex = lastIndex - dataPerPge;

  const transactions = tabName === "Pay Out" ? payoutTransactions : payinTransactions
  const transactionsData =
    searchedTransactions.length > 0 ? searchedTransactions : transactions;
  const records = transactionsData.slice(firstIndex, lastIndex);

  const total = dataPerPge * currentPage + records.length - dataPerPge;

  const tabTitle = tabName === "Pay Out" ? "payOut" : "payIn"
  const handleSearch = async (e) => {
    const keyword = e.target.value;
    await searchTransactions(
      setSearchedTransactions,
      keyword,
      setSearchLoading,
      tabTitle
    );
  };

  const handleSearchByDate = async (e) => {
    await filterTransactions(
      setSearchedTransactions,
      setSearchLoading,
      startDate,
      e.target.value
    );
  };

  useEffect(() => {
    getPayoutTransactions(setPayoutTransactions, setLoading);
    getPayinTransactions(setPayinTransactions, setLoading);
  }, []);

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      <FilterSection
        header={"Transactions"}
        placeholder={"by departments, amounts"}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        handleSearch={handleSearch}
        handleSearchByDate={handleSearchByDate}
        transactionsData={transactionsData}
      />
      <CustomTab
        transactions={records}
        setLoading={setLoading}
        setTabName={setTabName}
        payin={payinTransactions}
      />

      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {transactionsData?.length} Requests
        </Text>
        <Pagination
          totalData={transactionsData.length ?? 0}
          dataPerPage={dataPerPge}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default Transactionspage;
