import React, { useState } from "react";
import { CustomBtn } from "../CustomBtn";
import { formatToNaira } from "../../utils/numberFormat";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from "@chakra-ui/react";
import { MdDriveFolderUpload } from "react-icons/md";
import { updateCompanyDetails } from "../../services/accountSettings";

function GeneralSpending({ setRefresh, userInfo, companyDetails }) {
  const [spendingLimit, setSpendingLimit] = useState(
    () => companyDetails?.[0]?.spendingLimit
  );
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      companyId: userInfo?.companyId,
      spendingLimit,
    };
    await updateCompanyDetails(payload, setLoading, setRefresh);
  };
  return (
    <Box bg="#fff" w={["100%", "100%", "20vw"]} p="15px">
      <Box bg="white" color="#000">
        <Text fontWeight={"Bold"} color="#000" fontSize="17px">
          General Spending Limit
        </Text>
      </Box>
      <Box
        maxW="lg"
        display="flex"
        justifyContent="space-between"
        mx="auto"
        py={3}
      >
        <Box width="80vw">
          <Text color="#686868" fontIze="14px">
            Current Limit
          </Text>
          <Text fontWeight="Bold" mt="10px">
            {" "}
            {formatToNaira(companyDetails?.[0]?.spendingLimit)}
          </Text>
        </Box>

        <Text color="#686868" fontIze="14px">
          Monthly
        </Text>
      </Box>
      <Box display="flex" alignItems="center" mt="10px">
        <FormControl>
          <FormLabel color="#757272">Change Limit</FormLabel>
          <InputGroup>
            <InputLeftAddon children={"₦"} height="38px" />
            <Input
              height="38px"
              label="Set Spending Limits"
              type="number"
              value={spendingLimit}
              onChange={(e) => setSpendingLimit(e.target.value)}
            />
          </InputGroup>
        </FormControl>
      </Box>

      <Flex
        justifyContent={["flex-end"]}
        position={"relative"}
        my={["10px", "10px", ""]}
        top={["10px", "10px", "36px"]}
        width="100% !important"
      >
        <CustomBtn
          bg="btnbg"
          text="Update"
          childComp={<MdDriveFolderUpload size={20} color="white" />}
          handleClick={handleSubmit}
          loading={loading}
          disabled={
            parseFloat(companyDetails?.[0]?.spendingLimit) ===
            parseFloat(spendingLimit)
          }
        />
      </Flex>
    </Box>
  );
}

export default GeneralSpending;
