import React from "react";
import { Box, Table } from "@chakra-ui/react";
import { departmentsTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import DepartmentTableBody from "./DepartmentTableBody";

const DepartmentTable = ({ departments, setRefresh }) => {
  return (
    <Box width={"100%"} overflowX={"scroll"}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
      >
        <TableHead tableHeadData={departmentsTableHead} />
        <DepartmentTableBody data={departments} setRefresh={setRefresh} />
      </Table>
    </Box>
    // </TableContainer>
  );
};

export default DepartmentTable;
