import Accountimage from "./assets/Accountimage.png"

export const dummyData = [
  {
    id: 1,
    name: "Philip Jackson Peter",
    vendor: "Peter Boyega",
    email: "peter@gmail.com",
    phoneNumber: "07037363664",
    accountName: "Peter Jackson",
    accountNumber: "00214697831",
    bank: "Access",
    address: "25b Marigold street",
    maritalStatus: "Married",
    department: "Kitchen",
    employmentStatus: "Employed",
    regNo: "!2345",
    income: "30M",
    companyName: "Zealight Innovation Labs",
    date: new Date(),
    type: "Lender",
    category: "Food",
    paid: 90500,
    interest: "10%",
    duration: "4 Months",
    disbursed: 1200000,
    status: "Cancelled",
  },
  {
    id: 2,
    name: "John Snow",
    vendor: "Mary Boyega",
    email: "john@gmail.com",
    phoneNumber: "07037363664",
    accountName: "Peter Jackson",
    accountNumber: "00214697831",
    bank: "Access",
    address: "25b Marigold street",
    maritalStatus: "Single",
    department: "Engineering",
    employmentStatus: "Employed",
    regNo: "!2345",
    income: "30M",
    companyName: "Zealight Innovation Labs",
    date: new Date(),
    disbursed: 10000,
    paid: 1000000,
    interest: "12%",
    type: "Lender",
    duration: "4 Months",
    category: "Power",
    status: "Paid",
  },
  {
    id: 3,
    name: "Peter Jacksons",
    vendor: "John Boyega",
    email: "peter@gmail.com",
    phoneNumber: "07037363664",
    accountName: "Peter Jackson",
    accountNumber: "00214697831",
    bank: "Access",
    address: "25b Marigold street",
    maritalStatus: "Married",
    department: "Marketing",
    employmentStatus: "Employed",
    regNo: "!2345",
    income: "30M",
    companyName: "Zealight Innovation Labs",
    date: new Date(),
    disbursed: 10000,
    paid: 12000,
    interest: "15%",
    type: "Lender",
    duration: "4 Months",
    category: "Lead Generation",
    status: "Paid",
  },
  {
    id: 4,
    name: "Peter Jackson",
    vendor: "Andrew Boyega",
    email: "peter@gmail.com",
    phoneNumber: "07037363664",
    accountName: "Peter Jackson",
    bank: "Access",
    accountNumber: "00214697831",
    address: "25b Marigold street",
    maritalStatus: "Single",
    department: "Sales",
    employmentStatus: "Employed",
    regNo: "!2345",
    income: "30M",
    companyName: "Zealight Innovation Labs",
    date: new Date(),
    disbursed: 10000,
    paid: 8000,
    interest: "10%",
    type: "Lender",
    duration: "4 Months",
    category: "Revenue",
    status: "Cancelled",
  },
  {
    id: 5,
    name: "Micheal Jackson",
    vendor: "Philip Boyega",
    email: "mikejk@gmail.com",
    phoneNumber: "07037346664",
    accountName: "Micheal Jackson",
    bank: "Access",
    accountNumber: "00214697831",
    address: "25b Marigold street",
    maritalStatus: "Single",
    department: "Sales",
    employmentStatus: "Employed",
    regNo: "!2345",
    income: "30M",
    companyName: "Zealight Innovation Labs",
    date: new Date(),
    disbursed: 10000,
    paid: 150000,
    interest: "10%",
    type: "Lender",
    duration: "4 Months",
    category: "Revenue",
    status: "Cancelled",
  },
];

export const LendersData = [
  {
    id: 1,
    pic: "../Images/first.png",
    name: "Joshua Yaju",
    email: "Joshuayaju@gmail.com",
  },
  {
    id: 2,
    pic: "../Images/second.png",
    name: "Joshua Yaju",
    email: "Joshuayaju@gmail.com",
  },
  {
    id: 3,
    pic: "../Images/third.png",
    name: "Joshua Yaju",
    email: "Joshuayaju@gmail.com",
  },
  {
    id: 4,
    pic: "../Images/fourth.png",
    name: "Joshua Yaju",
    email: "Joshuayaju@gmail.com",
  },
  {
    id: 5,
    pic: "../Images/fifth.png",
    name: "Joshua Yaju",
    email: "Joshuayaju@gmail.com",
  },
  {
    id: 6,
    pic: "../Images/first.png",
    name: "Joshua Yaju",
    email: "Joshuayaju@gmail.com",
  },
];

export const dummyData1 = [
  {
    pic: "",
    name: "Amaka Grace",
    date: new Date(),
    amount: "30000",
    department: "Kitchen",
    status: "Pending",
    account: "30243344402",
    bank: "UBA",
  },
  {
    pic: "https://bit.ly/dan-abramov",
    name: "Susan Dav",
    date: new Date(),
    amount: "20000",
    department: "Light",
    status: "Approved",
    account: "30243344402",
    bank: "Access Bank",
  },
  {
    pic: "",
    name: "John Mark",
    date: new Date(),
    amount: "15000",
    department: "Kitchen",
    status: "Cancelled",
    account: "30243344402",
    bank: "GTB",
  },
  {
    pic: "",
    name: "John Mark",
    date: new Date(),
    amount: "30000",
    department: "Light",
    status: "Approved",
    account: "30243344402",
    bank: "Zenith Bank",
  },
  {
    pic: "",
    name: "John Mark",
    date: new Date(),
    amount: "30000",
    department: "Sales",
    status: "Cancelled",
    account: "30243344402",
    bank: "Polaris Bank",
  },
  {
    pic: "",
    name: "John Mark",
    date: new Date(),
    amount: "30000",
    department: "Kitchen",
    status: "Approved",
    account: "30243344402",
    bank: "First Bank",
  },
];


export const messages = [
    { 
      id: 1,
      image: Accountimage,
      email:"zealight@gmail.com",
       sender: 'Adminstrator', 
       text: 'how do i do the message chat where message sent and recieve shows and also the name of the person  sending message and picture and last seen showin on top with chakra ui',
        time: '10:00 AM'
       },
    {
       id: 2,
       image: Accountimage,
       email:"zealight@gmail.com",
        sender: 'Adminstrator',
        text: 'Am Good. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit justo et pellentesque dictum. Nullam lacinia risus nec gravida lacinia. Morbi quis libero ligula. Integer eu tortor vel libero ullamcorper fringilla. Nam at justo vel velit euismod bibendum. Nunc finibus sollicitudin felis vitae malesuada. Phasellus vel purus vestibulum, bibendum ex in, semper est. Proin vel magna eu urna feugiat volutpat sed eu ante.', 
        time: '10:01 AM' 
      },
      { 
        id: 3, 
        image: Accountimage,
        email:"zealight@gmail.com",
        sender: 'Adam Taylor', 
        text: 'how do i do the message chat where message sent and recieve shows and also the name of the person  sending message and picture and last seen showin on top with chakra ui',
        time: '10:05 AM' 
      },
      { 
        id: 4, 
        image: Accountimage,
        email:"zealight@gmail.com",
        sender: 'Adam Taylor', 
        text: 'Am Good. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed blandit justo et pellentesque dictum. Nullam lacinia risus nec gravida lacinia. Morbi quis libero ligula. Integer eu tortor vel libero ullamcorper fringilla. Nam at justo vel velit euismod bibendum. Nunc finibus sollicitudin felis vitae malesuada. Phasellus vel purus vestibulum, bibendum ex in, semper est. Proin vel magna eu urna feugiat volutpat sed eu ante.', 
        time: '10:15 AM' 
    },
  ]
  
export const overviewDummy = [
  {
    name: "Austin Dev",
    date: "28 Nov 2022",
    amount: "N 15, 000",
    status: "Approved",
  },
  {
    name: "Austin Dev",
    date: "28 Nov 2022",
    amount: "N 15, 000",
    status: "Pending",
  },
  {
    name: "Austin Dev",
    date: "28 Nov 2022",
    amount: "N 15, 000",
    status: "Cancelled",
  },
  {
    name: "Austin Dev",
    date: "28 Nov 2022",
    amount: "N 15, 000",
    status: "Pending",
  },
  {
    name: "Austin Dev",
    date: "28 Nov 2022",
    amount: "N 15, 000",
    status: "Approved",
  },
 
]
