import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomBtn } from "./CustomBtn";
import { FormInputs } from "./FormInput";
import { createCategories, updateCategory } from "../services/category";

const AddCategoryModal = ({ setRefresh, data, text, onClose, props }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(() => data?.name);

  const handleSubmit = async (datum) => {
    setLoading(true);
    const payload = { name, _id: datum?._id };
    !data
      ? await createCategories(setLoading, setRefresh, onClose)(payload)
      : await updateCategory(setLoading, setRefresh, onClose)(payload);
  };

  return (
    <Box width="100%">
      <FormInputs
        type="text"
        name="name"
        label="Category Title"
        value={name}
        handleChange={(e) => setName(e.target.value)}
      />

      <Flex width="100%" justify="center" mt={"50px"}>
        <CustomBtn
          height="48px"
          text={text}
          width="80%"
          loading={loading}
          handleClick={() => handleSubmit(data)}
          disabled={!name}
        />
      </Flex>
    </Box>
  );
};

export default AddCategoryModal;
