import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, {useState} from "react";
import { readNotification } from "../../services/notifications";

export const NotificationCard = ({ data, setRefresh }) => {
  const relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);

  const [loading, setLoading] = useState(false);

  const handleRead = async () => {
    setLoading(true);
    await readNotification(data._id, setLoading, setRefresh);
  };

  return (
    <Box 
    p="10px"
     color="#929292" 
     onClick={handleRead} 
     cursor={"pointer"}
     style={{
      backgroundColor: data?.read === true ? "white" : "transparent",
    }}
     >
      <Flex alignItems={"center"} mb="5px">
        {/* <Avatar src={""} name={data?.name} alt="" size={"md"} /> */}
        <Box
          ml="20px"         
        >
          <Text color="#352848" fontWeight="bold" fontSize=".9em">
            {data?.title}
          </Text>
          <Text fontSize=".87em">{data?.message}</Text>
          <Text fontSize={".7em"}>{dayjs(data?.createdAt).from(dayjs())}</Text>
        </Box>
      </Flex>
    </Box>
  );
};
