import React from "react";
import { Line } from "react-chartjs-2";
import { Flex, Text } from "@chakra-ui/react";

const CategoryExpensesChart = ({ data, itemColors }) => {
  if (!data || data.length < 0) {
    return (
      <Flex justify={"center"} align={"center"} h={"400px"} bg="#ECECEE">
        <Text fontWeight={"semibold"} fontSize={"22px"}>
          No data available
        </Text>
      </Flex>
    );
  }

  const formatData = (data) => {
    const labels = Object.keys(data[0]?.transactions || {});
    const datasets = data.map((item) => ({
      label: item.name,
      data: Object.values(item.transactions),
      borderColor: item.color,
      backgroundColor: item.color,
      fill: false,
      pointRadius: 5,
      pointBorderColor: item.color,
      pointBackgroundColor: item.color,
      pointBorderWidth: 2,
    }));

    return {
      labels,
      datasets,
    };
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
      },
      y: {
        display: true,
        title: {
          display: false,
        },
        suggestedMin: 0, 
        suggestedMax: 10000, 
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div>
      <Line
        style={{ height: "400px" }}
        data={formatData(data)}
        options={options}
      />
    </div>
  );
};

export default CategoryExpensesChart;
