import { HStack, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import CustomModal from "../../CustomModal";
import DeleteModal from "../../DeleteModal";
import { deleteCtegory } from "../../../services/category";
import { useState } from "react";
import AddCategoryModal from "../../AddCategoryModal";
import CategoryDetailsModal from "./CategoryDetailsModal";
import { TbEdit } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";

const CategoriesTableBody = ({ data, setRefresh}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = (id) => {
    setLoading(true);
    deleteCtegory(id, setLoading, setRefresh);
  };

  return (
    <Tbody>
      {data?.map((data, key) => (
        <Tr key={key} fontSize={"16px"}>
          <Tooltip
            // isTruncated
            // maxWidth="170px !important"
            label={data?.name}
          >
            <Td>{data?.name}</Td>
          </Tooltip>

          <Tooltip>
            <Td>{data?.items?.length}</Td>
          </Tooltip>

          <Td>
            <HStack spacing={"20px"}>
              <CustomModal
                icon={<TbEdit size="22px" color={"#154141"} />}
                header={`Edit Category`}
                iconColor={"#154141"}
                data={data}
              >
                <AddCategoryModal
                  text="Update Category"
                  data={data}
                  setRefresh={setRefresh}
                  // onClose={onClose}
                />
              </CustomModal>

              <CustomModal
                icon={<AiOutlineEye size="18px" color={"#154141"} />}
                header={`Category Details`}
                iconColor={"#154141"}
                data={data}
              >
                <CategoryDetailsModal data={data} />
              </CustomModal>

              <CustomModal
                icon={<BsTrash size="18px" color={"#154141"} />}
                header={`Delete Category`}
                iconColor={"#154141"}
                data={data}
              >
                <DeleteModal
                  text="Delete Category"
                  handleClick={() => handleDelete(data?._id)}
                  loading={loading}
                />
              </CustomModal>
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default CategoriesTableBody;
