import { Avatar, HStack, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { BsTrash } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { formatToNaira } from "../../../utils/numberFormat";
import CustomModal from "../../CustomModal";
import DeleteModal from "../../DeleteModal";
import AddStaffModal from "../../AddStaffModal";
import { deleteStaff } from "../../../services/staff";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

// import LendersDrawerContent from "../../drawerChildren/LendersDrawerContent";

const StaffTableBody = ({ data, department, setRefresh }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteStaff(id, setLoading, setRefresh);
  };
  return (
    <Tbody>
      {data?.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"}>
          <Td
            isTruncated
            maxWidth="170px !important"
            borderRight="1px solid #dadedf !important"
          >
            <Avatar
              size="xs"
              src={datum?.image}
              name={datum?.fullName}
              alt={`Avatar of ${datum?.fullName}`}
            />
          </Td>
          <Tooltip label={datum?.fullName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              cursor="pointer"
              onClick={() =>
                datum?._id && navigate(`/staff/${datum?._id}`, { state: datum })
              }
            >
              {datum?.fullName}
            </Td>
          </Tooltip>
          <Tooltip isTruncated maxWidth="170px !important" label={datum?.email}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.email}
            </Td>
          </Tooltip>

          <Tooltip isTruncated label={datum?.phoneNumber}>
            <Td
              borderRight="1px solid #dadedf !important"
              isTruncated
              maxWidth="170px !important"
            >
              {datum?.phoneNumber}
            </Td>
          </Tooltip>

          <Td
            borderRight="1px solid #dadedf !important"
            textTransform={"capitalize"}
          >
            {datum?.role}
          </Td>

          <Tooltip label={datum?.department?.department}>
            <Td
              borderRight="1px solid #dadedf !important"
              isTruncated
              maxWidth="170px !important"
            >
              {datum?.department?.department}
            </Td>
          </Tooltip>
          <Td>
            <HStack spacing={"20px"}>
              <CustomModal
                icon={<TbEdit size="22px" color={"#154141"} />}
                header={`Edit Staff`}
                iconColor={"#154141"}
                data={datum}
              >
                <AddStaffModal
                  text="Update Staff"
                  data={datum}
                  department={department}
                  setRefresh={setRefresh}
                />
              </CustomModal>

              <CustomModal
                icon={<BsTrash size="18px" color={"#154141"} />}
                header={`Delete Staff`}
                iconColor={"#154141"}
                data={datum}
              >
                <DeleteModal
                  text="Delete Staff"
                  loading={loading}
                  handleClick={() => handleDelete(datum?._id)}
                />
              </CustomModal>
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default StaffTableBody;
