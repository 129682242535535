import { PinInput, PinInputField, Box, Flex } from "@chakra-ui/react";
import { CustomBtn } from "./CustomBtn";

const DisbursePin = ({ pin, setPin, handleSubmit, onClose }) => {
  const handleChange = (value) => {
    setPin(value);
  };
  const handleSubmitPin = () => {
    handleSubmit(pin); 
    onClose(); 
  };
  

  return (
    <>
      <Flex width="100%" justify="center" align="center" gap="1rem">
        <PinInput
          value={pin}
          onChange={(value) => handleChange(value)}
          size="lg"
          autoFocus
        >
          {Array.from({ length: 4 }, (_, index) => (
            <PinInputField
              key={index}
              size="lg"
              type="number"
              color="black"
              borderColor="#005F58"
              mr={4}
            />
          ))}
        </PinInput>
      </Flex>
      <Flex width="100%" justify="center" mt={["2rem"]}>
        <CustomBtn text={"Submit"} handleClick={handleSubmitPin} />
      </Flex>
    </>
  );
};

export default DisbursePin;
