import { Box, Flex, FormLabel, Select } from "@chakra-ui/react";
import React, { useState } from "react";
import { CustomBtn } from "./CustomBtn";
import { FormInputs } from "./FormInput";
import { createAccount } from "../services/account";
import Search from "./SearchItems";

const AddAccountModal = ({ setRefresh, data, categories, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({
    bankName: "",
    bankCode: "",
  });
  const [formValues, setformValues] = useState({
    accountName: "",
    phoneNumber: "",
    accountNumber: "",
    category: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const payload = {
      ...formValues,
      bankCode: selectedItem?.bankCode,
      bankName: selectedItem?.bankName,
      service: formValues.category,
    };
    await createAccount(payload, setLoading, setRefresh)(onClose);
  };

  return (
    <Box>
      <FormInputs
        type="email"
        name="email"
        label="Email"
        placeholder="Please enter your email"
        value={formValues.email}
        handleChange={handleChange}
      />
      <FormInputs
        type="text"
        name="phoneNumber"
        label="Phone Number"
        placeholder="Please enter your email"
        value={formValues.phoneNumber}
        handleChange={handleChange}
      />
      <FormInputs
        type="text"
        name="accountName"
        label="Account Name"
        placeholder="Please enter the name of this account"
        value={formValues.accountName}
        handleChange={handleChange}
      />
      <FormInputs
        type="text"
        name="accountNumber"
        label="Account Number"
        placeholder="Please enter a valid bank account number"
        value={formValues.accountNumber}
        handleChange={handleChange}
      />
      <Search data={data} setSelectedItem={setSelectedItem} />
      <FormLabel>Vendor's Category</FormLabel>
      <Select
        h="45px"
        placeholder="Enter the service this person provides"
        name="category"
        value={formValues.category}
        onChange={handleChange}
      >
        {categories.map((category) => (
          <option value={category?._id}>{category?.name}</option>
        ))}
      </Select>

      <Flex width="100%" justify="center" mt={"50px"}>
        <CustomBtn
          height="48px"
          text={"Add Account"}
          width="80%"
          loading={loading}
          handleClick={handleSubmit}
          disabled={
            !formValues.accountName ||
            !formValues.accountNumber ||
            !selectedItem.bankCode ||
            !selectedItem.bankName ||
            !formValues.category
          }
        />
      </Flex>
    </Box>
  );
};

export default AddAccountModal;
