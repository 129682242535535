import { Box, Flex,  Heading, Table, Text } from "@chakra-ui/react";
import TableHead from "../components/table/TableHead";
import { requestQuoteTableHead } from "../table.constants";
import CSVDownload from "../components/CSVDownload";
import RequestListTable from "../components/table/ResponsePage/RequestListTable";
import { useLocation } from "react-router-dom";
import { vendorModalCsvHeaders } from "../constants/csvHeaders.constants";
import { vendorResponseCsv } from "../utils/csvContents";


function RequestQuoteList() {
  const { state } = useLocation();
  const data = state.vendorResponse;


  return (
    <Box>
      <Box width="100%" overflowX={["scroll", "scroll", "hidden"]}>
        <Flex alignItems="center" justify="space-between">
          <Box>
            <Heading mb="20px" as="h2" fontSize={["22px", "22px", "30px"]}>
              Response List
            </Heading>
            <Flex  gap="10px">
              <Text fontSize="20px">Title:</Text>
              <Text fontSize="20px">{state?.title.toUpperCase()}</Text>
            </Flex>
          </Box>
          <Flex gap="10px" align="center">
            <CSVDownload
              headers={vendorModalCsvHeaders}
              data={vendorResponseCsv(data)}
            />
            <Text>Download CSV List</Text>
          </Flex>
        </Flex>
        <Table
          variant="striped"
          colorScheme="gray"
          bg="#fff"
          borderRadius={"5px"}
          mt="30px"
          overflow="scroll"
        >
          <TableHead tableHeadData={requestQuoteTableHead} />
          <RequestListTable data={data} />
        </Table>
      </Box>
    </Box>
  );
}

export default RequestQuoteList;
