import React from 'react'
import { Text, Flex, Box } from '@chakra-ui/react';
import { HiChevronLeft } from "react-icons/hi";
import { HiChevronRight } from "react-icons/hi";
import { CustomBtn } from "../components/CustomBtn";

function Footer() {
  return (
    <Flex justify="space-between" align="center" mt="20px" display={"none"}>
      <Text fontSize="16px" fontWeight="semibold">
        showing 6-6 request
      </Text>
      <Flex align="center">
        <Box
          bg="#fff"
          p="10px"
          mr="20px"
          borderRadius="8px"
          boxShadow="0px 3px 6px #00000017"
          cursor="pointer"
        >
          <HiChevronLeft size="18px" color="#707070" />
        </Box>
        <CustomBtn bg="" text="1" />
        <Box
          bg="#fff"
          p="10px"
          ml="20px"
          borderRadius="8px"
          boxShadow="0px 3px 6px #00000017"
          cursor="pointer"
        >
          <HiChevronRight size="18px" color="#707070" />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Footer