import React from "react";
import { Tbody, Tr, Td, Tooltip, HStack } from "@chakra-ui/react";
import { formatToNaira } from "../../../utils/numberFormat";
import dayjs from "dayjs";
import CustomModal from "../../CustomModal";
import DeleteModal from "../../DeleteModal";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import ScheduleModalContent from "./ScheduleModalContent";
import { deleteSchedule } from "../../../services/schedule";
import { useState } from "react";

const SchedulePaymentBody = ({ data, categories, setRefresh }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete =  (id) => {
    setLoading(true);
    deleteSchedule(id, setLoading, setRefresh);
  };

  console.log(data, "datta")
  return (
    <Tbody>
      {data.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"}>
          <Tooltip label={datum?.VendorName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.account?.accountName}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.AccountName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.account?.accountName}
            </Td>
          </Tooltip>
          <Tooltip label={datum.AccountNumber}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.account?.accountNumber}
            </Td>
          </Tooltip>
          <Tooltip label={datum.BankName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.account?.bankName}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.VendorCategories}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.category}
            </Td>
          </Tooltip>
          <Tooltip label={datum?.PaymentDate}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {dayjs(datum?.scheduleDate).format("DD MMM YYYY")}
            </Td>
          </Tooltip>
          <Tooltip label={datum.PaymentAmount}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {formatToNaira(datum?.amount)}
            </Td>
          </Tooltip>
          <Td>
            <HStack spacing={"20px"}>
              <CustomModal
                icon={<FiEdit size="18px" color={"#154141"} />}
                header={`Update Schedule`}
                maxH="500px"
                overflow="scroll"
                data={datum}
              >
                <ScheduleModalContent
                  text={"Update Schedule"}
                  categories={categories}
                  data={datum}
                  setRefresh={setRefresh}
                />
              </CustomModal>
              <CustomModal
                icon={<BsTrash size="18px" color={"#154141"} />}
                header={`Delete Details`}
                data={datum}
              >
                <DeleteModal
                  text="Delete Accounts"
                  loading={loading}
                  handleClick={() => handleDelete(datum?._id)}
                />
              </CustomModal>
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default SchedulePaymentBody;
