import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SearchField from "../components/SearchField";
import CustomModal from "../components/CustomModal";
import { CustomBtn } from "../components/CustomBtn";
import { AiOutlinePlus } from "react-icons/ai";
import SchedulePaymentTable from "../components/table/schedulePayment/SchedulePaymentTable";
import ScheduleModalContent from "../components/table/schedulePayment/ScheduleModalContent";
import { Pagination } from "../components/Pagination";
import { searchSchedule } from "../services/schedule";
import { getCategories } from "../services/category";
import CSVDownload from "../components/CSVDownload";
import { scheduleCsvHeaders } from "../constants/csvHeaders.constants";
import { scheduleCsv } from "../utils/csvContents";
import { getSchedule } from "../services/schedule";
import FullPageLoader from "../components/fullPageLoader";
import { getAccount } from "../services/account";
import Notification from "../components/Notification";

const SchedulePaymentPage = () => {
  const [categories, setCategories] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [filteredSchedule, setFilteredSchedule] = useState([]);
  const [, setSearchLoading] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 20;
  const lastIndex = dataPerPage * currentPage;
  const firstIndex = lastIndex - dataPerPage;
  const scheduleData =
    filteredSchedule.length > 0 ? filteredSchedule : schedules;
  const records = scheduleData.slice(firstIndex, lastIndex);
  const total = dataPerPage * currentPage + records.length - dataPerPage;

  useEffect(() => {
    getSchedule(setSchedules, setLoading);
    getCategories(setCategories, setLoading);
    getAccount(setAccounts, setLoading);
  }, [refresh]);

  const handleSearch = async (e) => {
    const keyword = e.target.value;
    await searchSchedule(
      setFilteredSchedule,
      keyword,
      setSearchLoading,
      setNotFoundError
    );
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      {notFoundError && <Notification text="No Vendor Found" />}
      <Flex
        width="100%"
        justify={["start", "start", "space-between"]}
        alignItems={["start", "start", "start", "center"]}
        direction={["column", "column", "column", "row"]}
        mb={["20px", "20px", "20px", ""]}
      >
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "20px", "0"]}
        >
          Schedule Payment
        </Heading>

        <Flex
          width={["100%", "100%", "100%", "60%"]}
          flexWrap={["wrap", "wrap", "nowrap"]}
          justify="space-between"
          gap={["15px", "15px", "30px"]}
          align="center"
        >
          <Box width={["100%", "100%", "60%"]}>
            <SearchField handleSearch={handleSearch} />
          </Box>
          <Box>
            <CustomModal
              icon={
                <CustomBtn
                  childComp={<AiOutlinePlus />}
                  text="Create New Schedules"
                />
              }
              header={`Add New Schedule`}
              iconColor={"#154141"}
              maxH="500px"
              overflow="scroll"
            >
              <ScheduleModalContent
                text={"Add Schedule"}
                accounts={accounts}
                categories={categories}
                setRefresh={setRefresh}
              />
            </CustomModal>
          </Box>
          <CSVDownload
            headers={scheduleCsvHeaders}
            data={scheduleCsv(scheduleData)}
          />
        </Flex>
      </Flex>
      <SchedulePaymentTable
        users={records}
        categories={categories}
        setRefresh={setRefresh}
      />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {scheduleData?.length} Response
        </Text>
        <Pagination
          totalData={scheduleData.length ?? 0}
          dataPerPage={dataPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default SchedulePaymentPage;
