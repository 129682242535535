import {
  Flex,
  HStack,
  Progress,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { BsTrash } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { formatToNaira } from "../../../utils/numberFormat";
import CustomModal from "../../CustomModal";
import DepartmentModalContent from "../../DepartmentModalContent";
import DeleteModal from "../../DeleteModal";
import { deleteDepartment } from "../../../services/department";
import { useState } from "react";

// import LendersDrawerContent from "../../drawerChildren/LendersDrawerContent";

const DepartmentTableBody = ({ data, setRefresh }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async (deptId) => {
    setLoading(true);
    const payload = { isdelete: true };
    await deleteDepartment(payload, deptId, setLoading, setRefresh);
  };

  return (
    <Tbody>
      {data?.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"}>
          <Tooltip label={datum?.department}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.department}
            </Td>
          </Tooltip>

          <Td borderRight="1px solid #dadedf !important">
            {formatToNaira(datum?.spendingLimit)}
          </Td>
          <Td borderRight="1px solid #dadedf !important" width="40% !important" isTruncated>
            <Progress
              colorScheme={
                parseFloat(
                  (datum?.amountSpent * 100) /
                    datum?.spendingLimit
                ).toFixed(1) <= 50
                  ? "green"
                  : parseFloat(
                      (datum?.amountSpent * 100) /
                        datum?.spendingLimit
                    ).toFixed(1) >= 51 &&
                    parseFloat(
                      (datum?.amountSpent * 100) /
                        datum?.spendingLimit
                    ).toFixed(1) <= 75
                  ? "yellow"
                  : "red"
              }
              value={
                datum?.amountSpent &&
                parseFloat(
                  (datum?.amountSpent * 100) /
                    datum?.spendingLimit
                ).toFixed(1)
              }
              rounded={"full"}
              h={"17px"}
              border="1px solid #15414120"
            />
            <Flex width="100%" justify={"space-between"} mt="5px">
              <Text>
                {formatToNaira(datum?.amountSpent?.toString()) || "--"} Spent out
                of {formatToNaira(datum?.spendingLimit)}
              </Text>
              <Text>
                {datum?.amountSpent &&
                  parseFloat(
                    (datum?.amountSpent * 100) / datum?.spendingLimit
                  ).toFixed(1)}
                %
              </Text>
            </Flex>
          </Td>
          <Td>
            <HStack spacing={"20px"}>
              <CustomModal
                // icon={AiOutlineEye}
                icon={<TbEdit size="22px" color={"#154141"} />}
                header={`Edit Department`}
                iconColor={"#154141"}
                data={datum}
              >
                <DepartmentModalContent
                  text="Update Department"
                  data={datum}
                  setRefresh={setRefresh}
                />
              </CustomModal>

              <CustomModal
                icon={<BsTrash size="18px" color={"#154141"} />}
                header={`Delete Department`}
                iconColor={"#154141"}
                data={datum}
              >
                <DeleteModal
                  text="Delete Department"
                  loading={loading}
                  handleClick={() => handleDelete(datum?._id)}
                />
              </CustomModal>
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default DepartmentTableBody;
