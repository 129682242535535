import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const refundWallet =
  (payload) =>
  (setLoading) =>
  (setRefresh) =>
  (setPaymentModal) =>
  async (setPaymentData) => {
    try {
      const {
        data: { data },
      } = await http.post(AUTH_ROUTES.REFUND_WALLET, payload);
      // successNotifier("Wallet refunded Successfully");
      setPaymentModal(true);
      // setLoading(false);
      setPaymentData(data);
      setRefresh((prev) => !prev);
    } catch (e) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } finally {
      setLoading(false);
      // setRefresh((prev) => !prev);
    }
  };
