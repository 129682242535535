import { Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { formatToNaira } from "../../../utils/numberFormat";
import CustomModal from "../../CustomModal";
import { TransactionDetailsModal } from "../../tabs/TransactionDetailsModal";

const DashboardTableBody = ({ data, payin, info}) => {
  const navigate = useNavigate();
  return (
    <Tbody>
      {data?.map((data) => (
        <Tr key={data?._id} fontSize={"16px"} maxWidth="170px !important">
          {!payin &&  (
            <Tooltip
              label={
                data?.departmentId?.department ||
                data?.departmentId?.[0]?.department ||
                "N/A"
              }
            >
              <Td
                isTruncated
                maxWidth="170px !important"
                borderRight="1px solid #dadedf !important"
                cursor="pointer"
                onClick={() =>
                  data?.departmentId?._id &&
                  navigate(`/transactions/${data?.departmentId?._id}`)
                }
              >
                {data?.departmentId?.department ||
                  data?.departmentId?.[0]?.department ||
                  "N/A"}
              </Td>
            </Tooltip>
          )}
          {!payin && (
            <Tooltip
              isTruncated
              maxWidth="170px !important"
              label={
                data?.requestId?.category?.name ||
                data?.requestId?.[0]?.category?.name ||
                "N/A"
              }
            >
              <Td borderRight="1px solid #dadedf !important" maxWidth="170px !important">
                {data?.requestId?.category?.name ||
                  data?.requestId?.[0]?.category?.name ||
                  "N/A"}
              </Td>
            </Tooltip>
                )}

          <Td
            borderRight="1px solid #dadedf !important"
            maxWidth="170px !important"
          >
            {formatToNaira(data?.amount)}
          </Td>
          <Tooltip label={dayjs(data?.createdAt).format("DD MMM YYYY")}>
            <Td
              borderRight="1px solid #dadedf !important"
              width="170px !important"
              isTruncated
            >
              {dayjs(data?.createdAt).format("DD MMM YYYY")}
            </Td>
          </Tooltip>

          <Td
            color="#C9A721"
            borderRight="1px solid #dadedf !important"
            maxWidth="170px !important"
          >
            <Text
              textAlign={"center"}
              bg={
                data?.status?.toLowerCase() === "pending"
                  ? "#FEF4CB"
                  : data?.status?.toLowerCase() === "cancelled"
                  ? "#F5DEDE"
                  : data?.status?.toLowerCase() === "confirmed"
                  ? "#C7DBDB"
                  : "#F5DEDE"
              }
              color={
                data?.status?.toLowerCase() === "pending"
                  ? "#D6B941"
                  : data?.status?.toLowerCase() === "cancelled"
                  ? "#F67373"
                  : data?.status?.toLowerCase() === "confirmed"
                  ? "#0D6565"
                  : "#F67373"
              }
              p="2px 10px"
              borderRadius={"10px"}
            >
              {data?.status.toLowerCase() === "confirmed"
                ? "Disbursed"
                : data?.status}
            </Text>
          </Td>
          <Td maxWidth="170px !important">
            <CustomModal
              icon={<AiOutlineEye size="18px" color={"#154141"} />}
              header={`Transactions Details`}
              iconColor={"#154141"}
              data={data}
            >
              <TransactionDetailsModal data={data} />
            </CustomModal>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default DashboardTableBody;
