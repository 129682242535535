import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { MdDriveFolderUpload } from "react-icons/md";
import { updateAccount, updateImage } from "../../services/accountSettings";
import { CustomBtn } from "../CustomBtn";
import { FormInputs } from "../FormInput";

const UserProfile = ({ state, setRefresh }) => {
  const [imageLoading, setImageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    fullName: state?.userInfo?.fullName.trim(),
    email: state?.userInfo?.email,
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdatePic = async (e) => {
    setImageLoading(true);
    const image = e.target.files?.[0];
    console.log("IMAGES", image)
    const formData = new FormData();
    formData.append("image", image);
    await updateImage(formData, setImageLoading, setRefresh);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = {
      ...formData,
      _id: state.userInfo?._id,
    };
    setLoading(true);
    await updateAccount(payload, setLoading, setRefresh);
  };

  return (
    <Box
      bg="#fff"
      w={["100%", "100%", "70vw"]}
      p="10px 20px"
      borderRadius="5px"
    >
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        justify={"flex-start"}
      >
        <Box width={["30%", "30%", "20%"]} position="relative">
          <Input
            type="file"
            name="input"
            id="input"
            width={0}
            height={0}
            visibility="hidden"
            overflow="hidden"
            onChange={handleUpdatePic}
          />
          <FormLabel
            htmlFor="input"
            display="inline-block"
            backgroundColor={"#FFF"}
            position="relative"
          >
            <Image
              src={state?.userInfo?.image}
              alt="User"
              w={["60px", "60px", "90px"]}
              h={["60px", "60px", "90px"]}
              borderRadius="50%"
              // mr="10px"
            />
            {imageLoading && (
              <Box position={"absolute"} top="5" left="5">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Box>
            )}
          </FormLabel>
        </Box>

        <Box width={["60%", "60%", "80%"]}>
          <Text fontWeight={"Bold"} color="#000">
            {state?.userInfo?.fullName}
          </Text>
          <Text fontWeight={"Trenda-Regular"} color="#686868" fontSize="14px">
            {state?.userInfo?.email}
          </Text>
        </Box>
      </Stack>

      <Stack p="20px" pt="0" pb="20px">
        <form>
          <Box
            display="flex"
            flexDirection={["column", "column", "row"]}
            alignItems="center"
            justifyContent="space-between"
            spacing={"8px"}
          >
            <FormControl w={["100%", "100%", "50%"]} mr={["", "", "15px"]}>
              <FormInputs
                type="text"
                label="Name"
                name="fullName"
                value={formData?.fullName}
                handleChange={handleChange}
                disabled
              />
            </FormControl>

            <FormControl w={["100%", "100%", "50%"]} mr={["", "", "15px"]}>
              <FormInputs
                type="email"
                label="Email"
                name="email"
                value={formData?.email}
                handleChange={handleChange}
              />
            </FormControl>
          </Box>

          <Flex justifyContent="flex-end" mt={4}>
            <CustomBtn
              bg="btnbg"
              text="Update"
              childComp={<MdDriveFolderUpload size={20} color="white" />}
              type="submit"
              loading={loading}
              handleClick={handleSubmit}
              disabled={
                formData.email === state?.userInfo?.email &&
                formData.fullName === state?.userInfo?.fullName
              }
            />
          </Flex>
        </form>
      </Stack>
    </Box>
  );
};

export default UserProfile;
