import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getMonthlyCategoryExpense = async (
  setMonthlyCategoryExpense,
  setLoading
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_MONTHLY_CATEGORY_EXPENSE);
    setMonthlyCategoryExpense(data);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
export const getDailyCategoryExpense = async (
  setDailyCategoryExpense,
  setLoading
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DAILY_CATEGORY_EXPENSE);
    setDailyCategoryExpense(data);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getExpensePerItem = async (setExpensePerItem, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_EXPENSE_PER_ITEM);
    console.log("setExpensePerItem", data);
    setExpensePerItem(data?.data?.data);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getDailyPayin = async (setDailyPayin, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DAILY_PAYIN);
    //console.log("daily payin api", data);
    setDailyPayin(data?.totalFundAccrued);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
export const getMontlyPayin = async (setMontlyPayin, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_MONTHLY_PAYIN);
    setMontlyPayin(data?.totalFundAccrued);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
export const getDailyPayout = async (setDailyPayout, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_DAILY_PAYOUT);
    setDailyPayout(data?.totalFundDisbursed);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
export const getMontlyPayout = async (setMontlyPayout, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_MONTHLY_PAYOUT);
    setMontlyPayout(data?.totalFundsDisbursed);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};

export const getMonthlyPayoutToVendors = async (setMonthlyPayoutToVendors, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_MONTHLY_PAYOUTSTOVENDORS);
    setMonthlyPayoutToVendors(data?.data?.data);
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
export const getAnnualPayoutToVendors = async (setAnnualPayoutToVendors, setLoading) => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_ANNUAL_PAYOUTSTOVENDORS);
    setAnnualPayoutToVendors(data?.data?.data)
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    console.log(e?.response?.data?.message || e?.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
