import { Avatar, Flex, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { formatToNaira } from "../../../utils/numberFormat";

// import LendersDrawerContent from "../../drawerChildren/LendersDrawerContent";

const StaffTransactionDetailsTableBody = ({ data }) => {
  return (
    <Tbody>
      {data?.map((data) => (
        <Tr key={data?._id} fontSize={"16px"}>
          <Tooltip label={data?.userId?.fullName || "N/A"}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              <Flex align="center">
                <Avatar
                  mr="10px"
                  size="xs"
                  name={data?.userId?.fullName}
                  src={data?.userId?.image}
                  alt={`Avater of ${data?.userId?.fullName}`}
                />
                <Text
                  isTruncated
                  maxWidth="170px !important"
                  textTransform="capitalize"
                >
                  {data?.userId?.fullName}
                </Text>
              </Flex>
            </Td>
          </Tooltip>

          <Tooltip label={data?.departmentId?.department || "N/A"}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              cursor="pointer"
              // onClick={() =>
              //   data?.departmentId?._id &&
              //   navigate(`/transactions/${data?.departmentId?._id}`)
              // }
            >
              {data?.departmentId?.department || "N/A"}
            </Td>
          </Tooltip>
          <Tooltip
            isTruncated
            maxWidth="170px !important"
            label={data?.categoryId?.name || "N/A"}
          >
            <Td
              borderRight="1px solid #dadedf !important"
              textTransform="capitalize"
            >
              {data?.categoryId?.name || data?.categoryId?.[0]?.name || "N/A"}
            </Td>
          </Tooltip>

          <Td borderRight="1px solid #dadedf !important">
            {formatToNaira(data?.amount)}
          </Td>
          <Td borderRight="1px solid #dadedf !important">
            {dayjs(data?.createdAt).format("DD MMM YYYY")}
          </Td>

          <Td
            color="#C9A721"
            borderRight="1px solid #dadedf !important"
            textTransform="capitalize"
          >
            <Text>{data?.paymentFor}</Text>
          </Td>
          <Td
            
            color={
              data?.status?.toLowerCase() === "pending"
                ? "#D6B941"
                : data?.status?.toLowerCase() === "cancelled"
                ? "#F67373"
                : data?.status?.toLowerCase() === "approved"
                ? "#0D6565"
                : "#F67373"
            }
            // p="2px 10px"
            // borderRadius={"10px"}
            textTransform="capitalize"
          >
            {data?.status}
            {/* <CustomModal
              icon={<BsTrash size="18px" color={"#154141"} />}
              header={`Delete Transaction`}
              iconColor={"#154141"}
              data={data}
            >
              <AccountModalContent text="Delete Transaction" />
            </CustomModal> */}
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default StaffTransactionDetailsTableBody;
