import { Box, Divider, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import SearchField from "../SearchField";
import FriendsCard from "./FriendsCard";
import { IoIosPeople } from "react-icons/io";
import { BsFillChatDotsFill } from "react-icons/bs";

const FriendsList = ({
  staff,
  setRefresh,
  conversations,
  addToChatList,
  setAddToChatList,
  setConversations,
  recipientDetails,
  showChatFeed,
  setShowChatFeed,
  foundConversations,
  setRecipientDetails,
  setShowFriendsList,
  showFriendsList,
  conversationsById,
  setConversationsById,
}) => {
  const [filteredData, setFilteredData] = useState(() => staff);
  const [filteredConversations, setFilteredConversations] = useState([]);

  // const [show, setShow] = useState("conversations");
  // console.log(conversations, "FILTERED", filteredData);

  const filteredToShow =
    filteredConversations?.length > 0 ? filteredConversations : conversations;

  const listToDisplay =
    addToChatList === "conversations" ? filteredToShow : filteredData;
  const handleFilter = (e) => {
    const keyword = e.target.value;

    if (keyword !== "") {
      if (addToChatList === "friends") {
        const filteredResult = staff?.filter((user) => {
          return (
            user?.fullName.toLowerCase()?.includes(keyword?.toLowerCase()) ||
            user?.email.toLowerCase()?.includes(keyword?.toLowerCase())
          );
        });
        setFilteredData(filteredResult);
      } else {
        const filteredResult = conversations?.filter((conversation) => {
          return conversation?.entityTwoId?.fullName
            .toLowerCase()
            ?.includes(keyword?.toLowerCase());
        });
        setFilteredConversations(filteredResult);
      }
    } else {
      setFilteredData(staff);
    }
  };

  // console.log("filteredData", filteredData);
  return (
    <Box
      width={["100%", "100%", "38%"]}
      bg="#fff"
      pb="40px"
      h="70vh"
      overflowY={"scroll"}
      className="friends"
      borderTopRightRadius={"5px"}
      borderTopLeftRadius="5px"
      borderBottomRightRadius={"5px"}
      borderBottomLeftRadius="5px"
    >
      <Box bg="white" width="100%">
        <Flex p="15px 20px" shadow="sm" mb="2px" align={"center"} gap="10px">
          {addToChatList === "conversations" ? (
            <IoIosPeople
              title="People icon"
              color="#154141"
              size={24}
              onClick={() => {
                setAddToChatList("friends");
              }}
            />
          ) : (
            <BsFillChatDotsFill
              title="Chat icon"
              color="#154141"
              size={24}
              onClick={() => {
                setAddToChatList("conversations");
              }}
            />
          )}
          <SearchField
            placeholder="Name or Email"
            handleSearch={handleFilter}
          />
        </Flex>
        <Box bg="#ECF1F0">
          {listToDisplay?.map((data, key) => (
            <FriendsCard
              key={key}
              data={data}
              foundConversations={foundConversations}
              setAddToChatList={setAddToChatList}
              setRefresh={setRefresh}
              recipientDetails={recipientDetails}
              setRecipientDetails={setRecipientDetails}
              setConversations={setConversations}
              setConversationsById={setConversationsById}
              setShowChatFeed={setShowChatFeed}
              showChatFeed={showChatFeed}
              setShowFriendsList={setShowFriendsList}
              showFriendsList={showFriendsList}
              conversationsById={conversationsById}
            />
          ))}
        </Box>
        <Divider />
      </Box>
    </Box>
  );
};

export default FriendsList;
