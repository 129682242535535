import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";
import axiosInstance from "./formDataConfig";

export const getStaff = async (setStaff, setLoading) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_STAFF);
    setStaff(data);
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  } finally {
    setLoading(false);
  }
};

export const searchStaff = async (
  setStaff,
  keyword,
  setLoading,
  setNotFoundError
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_STAFF(keyword));
    setStaff(data);
  } catch (e) {
    setNotFoundError((prev) => !prev);
  } finally {
    setLoading(false);
  }
};

export const createStaff =
  (payload) => (setLoading) => (setRefresh) => async (onClose) => {
    try {
      await http.post(AUTH_ROUTES.CREATE_STAFF, payload);
      successNotifier("Staff Created Successfully");
      setRefresh((prev) => !prev);
      onClose();
    } catch (e) {
      if (e.response) {
        if (e.response?.data?.message === "Unable to verify token.") {
          errorNotifier("Session timed out");
          window.location.reload();
        }
        errorNotifier(
          e.response?.data?.message || e.response?.data?.data?.message
        );
      } else {
        errorNotifier("Network error, Please check your internet connection");
      }
    } finally {
      setLoading(false);
    }
  };

export const staffBulkUpload = async (payload, setRefresh) => {
  try {
    await axiosInstance.post(AUTH_ROUTES.BULK_UPLOAD, payload);
    successNotifier("Staff Uploaded Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    if (e.response) {
      if (e.response?.data?.message === "Unable to verify token.") {
        errorNotifier("Session timed out");
        window.location.reload();
      }
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Network error, Please check your internet connection");
    }
  }
};

export const updateStaff =
  (payload) => (setLoading) => (setRefresh) => async (onClose) => {
    try {
      await http.put(AUTH_ROUTES.UPDATE_STAFF, payload);
      successNotifier("Staff Updated Successfully");
      setRefresh((prev) => !prev);
      onClose();
    } catch (e) {
      if (e.response) {
        if (e.response?.data?.message === "Unable to verify token.") {
          errorNotifier("Session timed out");
          window.location.reload();
        }
        errorNotifier(
          e.response?.data?.message || e.response?.data?.data?.message
        );
      } else {
        errorNotifier("Network error, Please check your internet connection");
      }
      //for users that have not verified their email
    } finally {
      setLoading(false);
    }
  };

export const deleteStaff = async (staffId, setLoading, setRefresh) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_STAFF(staffId));
    successNotifier("Staff Deleted Successfully");
    setRefresh((prev) => !prev);
  } catch (e) {
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  } finally {
    setLoading(false);
  }
};
