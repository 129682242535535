import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ChakraProvider,
  createStandaloneToast,
  extendTheme,
  Flex,
  Progress,
  Text,
} from "@chakra-ui/react";
import { AiFillInfoCircle } from "react-icons/ai";

const { toast, ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  colors: {
    white: "#FFF",
    dark: "#000",
    grey: "#F9FBFB",
    primary: "#154141",
    btnbg: "#4A9290",

    tablegray: {
      500: "#F9FBFB",
    },
    lightgreen: {
      500: "#3C7B79"
    },
    gray: {
      500: "#F8F8FE",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
      <ToastContainer />
    </ChakraProvider>
  </React.StrictMode>
);

export const errorNotifier = (errorMessage) => {
  return toast({
    // title: "Error",
    description:
      typeof errorMessage === "string" ? errorMessage : "SOMETHING WENT WRONG",
    status: "error",
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });
};

export const successNotifier = (info) => {
  return toast({
    // title: "Success",
    description: info,
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top",
  });
};
export const fileUploadNotifier = (toastRef) => {
  return toast({
    duration: null,
    isClosable: false,
    position: "top",
    render: (id) => {
      toastRef.current = id;
      return (
        <Flex
          bg="#6C4097"
          p={4}
          borderRadius="md"
          gridGap={3}
          alignItems="center"
        >
          <AiFillInfoCircle color="white" />
          <Flex direction="column" flexGrow={1}>
            <Text color="white" fontWeight="bold" mb={2}>
              Importing Contacts
            </Text>
            <Progress
              size="xs"
              sx={{
                "&": {
                  background: "white",
                  "& > *": {
                    background: "#6C4097",
                  },
                },
              }}
              isIndeterminate
            />
          </Flex>
        </Flex>
      );
    },
  });
};

export const shortNotifier = (info) => {
  return toast({
    // title: "Success",
    description: info,
    // status: "success",
    duration: 5000,
    isClosable: true,
    position: "bottom",
  });
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
