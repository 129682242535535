import React from "react";
import { Box, Table } from "@chakra-ui/react";
import { requestTableHead } from "../../../table.constants";
import TableHead from "../TableHead";
import TableBody from "./RequestTableBody";

function RequestTable({ request, setRefresh }) {
  return (
    <Box
      width={"100%"}
      overflowX={["scroll", "scroll", "scroll", "scroll", "hidden"]}
    >
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
        mt="50px"
      >
        <TableHead tableHeadData={requestTableHead} />
        <TableBody
          request={request}
          setRefresh={setRefresh}
        />
      </Table>
    </Box>
  );
}

export default RequestTable;
