export const formatToNaira = (amount) => {
  if (!amount) return;
  const toNumber = parseInt(amount, 10);
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(toNumber);
};

export const formatInputToCommaSeparated = (input) => {
  // Implement your logic to format input as comma-separated values
  // Example: Convert '123456' to '123,456'
  if (!input) return;
  
  return Number(input || 0).toLocaleString();
};
