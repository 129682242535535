import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { TextInputs } from "./TextInput";
import { CustomBtn } from "./CustomBtn";
import { makePayOut, updateRequest } from "../services/request";
import { formatToNaira } from "../utils/numberFormat";
import CustomModal from "./CustomModal";
import RequestCommentModal from "./RequestCommentModal";
import { useGetState } from "../contexts/useGetState";
import { TransactionDetailsModal } from "./tabs/TransactionDetailsModal";
import DisbursePin from "./DisbursePin";

const RequestModalContent = ({ request, setRefresh, onClose }) => {
  const { state } = useGetState();
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState("");

  const approverLimits = state?.companyDetails?.[0].approverAmountLimit
    .filter((approverLimit) => approverLimit.approverLimit)
    .sort((a, b) => a.approverLimit - b.approverLimit);

  const findApproverLimitForRequest = approverLimits?.find(
    (limit) => request.amount <= limit.approverLimit
  );

  const alreadyApproved = request?.approvers?.find(
    (approver) => approver?.approverId?._id === state?.userInfo?._id
  );

  const handleUpdate = async (status, transactionPin) => {
    setLoading(true);

    if (
      request?.status.toLowerCase() === "approved" &&
      alreadyApproved &&
      findApproverLimitForRequest?.approverNeed <= request?.approvers?.length
    ) {
      const payload = {
        userType: "Admin",
        userId: state?.userInfo?._id,
        amount: request?.amount,
        paymentFor: "payOut",
        requestId: request?._id,
        companyId: request?.companyId?._id,
        transactionPin: transactionPin,
      };
      await makePayOut(payload, setLoading, setRefresh);
    } else {
      const payload = {
        id: request?._id,
        status,
      };
      await updateRequest(payload, setLoading, setRefresh);
    }
  };
  console.log("STATE", state, "DATA", request);
  return (
    <Box>
      <Flex justify="space-between" width="100%" gap="20px">
        <TextInputs label="Request Title" text={request?.title} />
        <TextInputs label="Description" text={request?.description} />
      </Flex>
      <Flex justify="space-between" align="end">
        <TextInputs
          label="Amount"
          text={formatToNaira(request?.amount)}
          width="48%"
        />
        <CustomModal
          icon={<CustomBtn text=" Comment" disabled />}
          header={`Comment`}
        >
          <RequestCommentModal data={request} />
        </CustomModal>
      </Flex>

      <Flex justify="space-between" gap="20px" mt="10px">
        <TextInputs
          label="Account Number"
          text={request?.userAccount?.accountNumber || "N/A"}
          width="60%"
        />
        <TextInputs
          label="Bank Name"
          text={request?.userAccount?.bankName || "N/A"}
          width="60%"
        />
      </Flex>

      <Flex justify="space-between" align="end" gap="20px" mt="10px">
        <TextInputs
          label="Account Name"
          text={request?.userAccount?.accountName || "N/A"}
          width="48%"
        />
        {request?.userAccount?.status === "new" && <CustomBtn text="Save" />}
      </Flex>
      <Flex
        justify={request?.status === "Pending" ? "space-between" : "center"}
        align="center"
        mt="25px"
      >
        {request?.disbursed === "Completed" ||
        request?.disbursed === "InProgress" ? (
          <Box textAlign="center">
            <Text>
              {request?.disbursed === "Completed"
                ? "This request has been approved and cash as been disbursed to the given account kindly view"
                : "This request has been approved and cash disbursement is currently in progress"}
            </Text>
            <CustomModal
              header={`Transactions Details`}
              iconColor={"#154141"}
              title="Transaction Details"
              textAlign="center"
              cursor="pointer"
              color="#276765"
            >
              <TransactionDetailsModal data={request} />
            </CustomModal>
          </Box>
        ) : request?.status === "Approved" &&
          findApproverLimitForRequest?.approverNeed <=
            request?.approvers?.length ? (
          <Box textAlign="center">
            <CustomModal
              icon={
                <CustomBtn
                  text={
                    !state?.companyDetails?.[0]?.walletEnabled
                      ? "Disabled Feature"
                      : request?.status === "Approved" &&
                        !alreadyApproved &&
                        findApproverLimitForRequest?.approverNeed >
                          request?.approvers?.length
                      ? "Approve"
                      : request?.status === "Pending"
                      ? "Approve"
                      : request?.status.toLowerCase() === "declined"
                      ? "Cant Disburse Funds"
                      : !state?.userInfo?.canDisburseFunds
                      ? "You dont have disburse right"
                      : "Disburse Funds"
                  }
                  bg={
                    (request?.status.toLowerCase() === "declined" ||
                      !state?.companyDetails?.[0]?.walletEnabled) &&
                    "red"
                  }
                  loading={loading}
                  handleClick={() => {
                    if (
                      request?.status === "Pending" ||
                      (request?.status === "Approved" &&
                        findApproverLimitForRequest?.approverNeed >
                          request?.approvers?.length &&
                        !alreadyApproved)
                    ) {
                      handleUpdate("Approved");
                    }
                  }}
                  disabled={
                    request?.status.toLowerCase() === "declined" ||
                    (request?.approvers.length <
                      findApproverLimitForRequest?.approverNeed &&
                      alreadyApproved) ||
                    !state?.companyDetails?.[0]?.walletEnabled
                  }
                />
              }
              header={`Enter Transfer Pin`}
            >
              <DisbursePin
                pin={pin}
                setPin={setPin}
                handleSubmit={() => handleUpdate("Approved", pin)}
                request={request}
                setRefresh={setRefresh}
                onClose={onClose}
              />
            </CustomModal>
          </Box>
        ) : (
          <CustomBtn
            text={
              request?.status === "Pending"
                ? "Approve"
                : request?.status === "Approved" &&
                  findApproverLimitForRequest?.approverNeed >
                    request?.approvers?.length &&
                  !alreadyApproved
                ? "Approve"
                : alreadyApproved
                ? "You have approved already"
                : request?.status.toLowerCase() === "declined"
                ? "Cant Disburse Funds"
                : "Disburse Funds"
            }
            handleClick={() => handleUpdate("Approved")}
            loading={loading}
            bg={request?.status.toLowerCase() === "declined" && "red"}
            disabled={
              request?.status.toLowerCase() === "declined" || alreadyApproved
            }
          />
        )}
        {request?.status === "Pending" && (
          <CustomBtn
            text="Decline"
            bg="#fff"
            color="#FF7070"
            border="1px solid #FF7070"
            handleClick={() => handleUpdate("Declined")}
            loading={loading}
          />
        )}
      </Flex>
      {!state?.companyDetails?.[0]?.walletEnabled ? (
        <Text color="green" textAlign="center" mt="1rem">
          This feature has been disabled, Please contact Admin
        </Text>
      ) : request?.approvers.length <
          findApproverLimitForRequest?.approverNeed &&
        request?.status === "Approved" ? (
        <Text color="green" textAlign="center" mt="1rem">
          {findApproverLimitForRequest?.approverNeed -
            request?.approvers.length}{" "}
          Approvers Remaining
        </Text>
      ) : (
        ""
      )}
    </Box>
  );
};

export default RequestModalContent;
