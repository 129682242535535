import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { fileUploadNotifier } from "..";
import AddStaffModal from "../components/AddStaffModal";
import BulkUpload from "../components/BulkUpload";
import { CustomBtn } from "../components/CustomBtn";
import CustomModal from "../components/CustomModal";
import FullPageLoader from "../components/fullPageLoader";
import { Pagination } from "../components/Pagination";
import SearchField from "../components/SearchField";
import StaffTable from "../components/table/staff/Staff";
import { getStaff, searchStaff, staffBulkUpload } from "../services/staff";
import { getDepartmentWithHighestExpenses } from "../services/overview";
import Notification from "../components/Notification";
import CSVDownload from "../components/CSVDownload";
import { staffCsvHeaders } from "../constants/csvHeaders.constants";
import { staffCsv } from "../utils/csvContents";

const StaffPage = () => {
  const [staff, setStaff] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredStaff, setFilteredStaff] = useState([]);
  const [, setSearchLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [inputFile, setInputFile] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPge = 20;
  const lastIndex = dataPerPge * currentPage;
  const firstIndex = lastIndex - dataPerPge;
  const staffData = filteredStaff.length > 0 ? filteredStaff : staff;
  const records = staffData.slice(firstIndex, lastIndex);
  const total = dataPerPge * currentPage + records.length - dataPerPge;

  const departmentArray = Object.keys(departments).map((key) => ({
    id: key,
    ...departments[key],
  }));

  const toastRef = useRef();

  async function uploadHandler(file) {
    let formData = new FormData();
    if (file !== "") {
      formData.append("csv", file);

      await staffBulkUpload(formData, setRefresh);
    }
  }

  useEffect(() => {
    if (inputFile) {
      fileUploadNotifier(toastRef);
    }
    uploadHandler(inputFile)
      .then(() => {
        setRefresh([]);
      })
      .finally(() => toastRef.current?.onClose());
  }, [inputFile, setRefresh]);

  useEffect(() => {
    getStaff(setStaff, setLoading);
    getDepartmentWithHighestExpenses(setDepartments, setLoading);
  }, [refresh]);

  const handleSearch = async (e) => {
    const keyword = e.target.value;
    await searchStaff(
      setFilteredStaff,
      keyword,
      setSearchLoading,
      setNotFoundError
    );
  };

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box width="100%">
      {notFoundError && <Notification text="No Account Found" />}
      <Flex
        width="100%"
        justify={["start", "start", "space-between"]}
        alignItems={["start", "start", "center"]}
        direction={["column", "column", "row"]}
        mb="30px"
      >
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "0"]}
        >
          Staff
        </Heading>
        <Flex
          direction={["column-reverse", "column-reverse", "column", "row"]}
          width={["100%", "100%", "100%", "84%", "75%"]}
          justify="space-between"
          align="center"
        >
          <Box
            width={["100%", "100%", "100%", "47%"]}
            my={["10px", "10px", "10px", ""]}
          >
            <SearchField
              placeholder="Staff Name/Email"
              handleSearch={handleSearch}
            />
          </Box>

          <Flex gap="20px" align={"center"}>
            {/* <Box width={["25%"]}> */}
            <CustomModal
              icon={
                <CustomBtn
                  // width={["90%", "90%", "90%", "90%", "100%"]}
                  childComp={<AiOutlinePlus />}
                  text="Add Staff"
                />
              }
              header={`Add New Staff`}
              maxH="500px"
              overflow="scroll"
              iconColor={"#154141"}
            >
              <AddStaffModal
                text="Add Staff"
                department={departmentArray}
                setRefresh={setRefresh}
              />
            </CustomModal>

            <BulkUpload setInputFile={setInputFile} />
            <CSVDownload headers={staffCsvHeaders} data={staffCsv(records)} />
          </Flex>
        </Flex>
      </Flex>
      <StaffTable
        users={records}
        department={departmentArray}
        setRefresh={setRefresh}
      />
      <Flex justify={"space-between"} my="30px">
        <Text color="#373737">
          Showing {total} of {staffData?.length} Staffs
        </Text>
        <Pagination
          totalData={staffData.length ?? 0}
          dataPerPage={dataPerPge}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Flex>
    </Box>
  );
};

export default StaffPage;
