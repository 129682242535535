import React, { useState } from "react";
import { FormInputs } from "../../FormInput";
import { Box, Flex, Input, FormLabel } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import {
  formatInputToCommaSeparated,
  formatToNaira,
} from "../../../utils/numberFormat";
import { CustomBtn } from "../../CustomBtn";
import { createSchedule, updateSchedule } from "../../../services/schedule";
import dayjs from "dayjs";
import FilterSearch from "../../FilterSearch";
import { errorNotifier } from "../../..";

const ScheduleModalContent = ({
  text,
  categories,
  data,
  accounts,
  onClose,
  setRefresh,
}) => {
  const placeholderAmount = 20000;
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    scheduleId: data?._id || "",
    accountId: "",
    scheduleDate: data?.scheduleDate || dayjs().format("YYYY-MM-DD"),
    scheduleOccurence: data?.scheduleOccurence || "",
    category: data?.category || "",
    amount: data?.amount || "",
    vendor: data?.account?._id || "",
    accountName: data?.account?.accountName || "",
    accountNumber: data?.account?.accountNumber || "",
    bankName: data?.account?.bankName || "",
  });

  const [amount, setAmount] = useState("");
  console.log(data);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") setAmount(value?.replace(/,/g, ""));
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async () => {
    if (!formValues?.scheduleOccurence)
      return errorNotifier("Please select occurrence");
    if (!formValues?.amount)
      return errorNotifier("Please enter a valid amount");
    setLoading(true);
    const payload = {
      ...formValues,
      amount: formValues?.amount?.replace(/,/g, ""),
    };
    const updatePayload = {
      amount: formValues?.amount?.replace(/,/g, ""),
      scheduleDate: formValues?.scheduleDate,
      scheduleOccurence: formValues?.scheduleOccurence,
      scheduleId: data?._id,
    };
    !data
      ? await createSchedule(payload, setLoading, setRefresh, onClose)
      : await updateSchedule(updatePayload, setLoading, setRefresh, onClose);
  };
  return (
    <Box>
      <FormLabel>Vendor's Category</FormLabel>
      <Select
        mb={"20px"}
        h="45px"
        placeholder={formValues.category}
        name="category"
        value={formValues.category}
        onChange={handleChange}
      >
        {categories?.map((category) => (
          <option value={category?.name}>{category?.name}</option>
        ))}
      </Select>
      {!data ? (
        <FilterSearch
          label="Vendor's Name"
          data={accounts}
          setFormValues={setFormValues}
        />
      ) : (
        <FormInputs
          type="text"
          name="vendor"
          label="Vendor's Name"
          disabled={!!data}
          value={formValues.accountName}
          handleChange={handleChange}
        />
      )}
      <FormInputs
        type="text"
        name="accountName"
        //disabled={true}
        label="Account Name"
        value={formValues.accountName}
        handleChange={handleChange}
      />
      <FormInputs
        type="number"
        name="accountNumber"
        label="Account Number"
        //disabled={true}
        value={formValues.accountNumber}
        handleChange={handleChange}
      />
      <FormInputs
        type="text"
        name="bankName"
        label="Bank Name"
        //disabled={true}
        value={formValues.bankName}
        handleChange={handleChange}
      />

      <Box mb="20px">
        <FormLabel>Schedule Date</FormLabel>
        <Flex
          width={"100%"}
          justify={"space-between"}
          align={"center"}
          gap={"20px"}
        >
          <Input
            type="date"
            name="scheduleDate"
            label="Payment Amount"
            value={dayjs(formValues.scheduleDate).format("YYYY-MM-DD")}
            onChange={handleChange}
          />
          <Select
            placeholder="Choose occurence"
            label="Payment Amount"
            name="scheduleOccurence"
            value={formValues.scheduleOccurence}
            onChange={handleChange}
          >
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
          </Select>
        </Flex>
      </Box>
      <FormInputs
        type="text"
        name="amount"
        label="Payment Amount"
        value={formatInputToCommaSeparated(amount)}
        handleChange={handleChange}
        placeholder={formatToNaira(placeholderAmount)}
      />

      <Box align="center" mt="40px">
        <CustomBtn
          text={text}
          width="250px"
          height="48px"
          loading={loading}
          handleClick={handleSubmit}
          disabled={
            !formValues.category ||
         
            !formValues.accountName ||
            !formValues.accountNumber ||
            !formValues.bankName ||
            !formValues.amount
          }
        />
      </Box>
    </Box>
  );
};

export default ScheduleModalContent;
