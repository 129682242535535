import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import {
  transactionsPayinTableHead,
  transactionsPayoutTableHead,
} from "../../table.constants";
import TransactionTable from "../table/transaction/TransactionTable";
import TabHeadings from "./TabHeadings";

const CustomTab = ({
  tabHeadings = ["Pay Out", "Pay In"],
  transactions,
  setTabName,
}) => {
  return (
    <Tabs width="100%">
      <TabHeadings tabHeadings={tabHeadings} setTabName={setTabName} />

      <TabPanels width="100% !important" mt="20px">
        <TabPanel width="100% !important" p="0 !important" key="payOut">
          <TransactionTable
            key="payOut"
            payin={false}
            transactions={transactions}
            tableHeadData={transactionsPayoutTableHead}
          />
        </TabPanel>
        <TabPanel width="100% !important" p="0 !important" key="payIn">
          <TransactionTable
            key="payIn"
            transactions={transactions}
            tableHeadData={transactionsPayinTableHead}
            payin={true}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default CustomTab;
