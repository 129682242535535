import React, { useRef, useState } from "react";
import { Box, Text} from "@chakra-ui/react";
import { CustomBtn } from "./CustomBtn";
import { FormInputs } from "./FormInput";
import { Select } from "@chakra-ui/react";
import { createStaff, updateStaff } from "../services/staff";
import { Checkbox } from "@chakra-ui/react";
import { isValidEmail } from "../utils/emailTest";

function AddStaffModal({ text, department, setRefresh, data, onClose }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValues] = useState({
    userId: data?._id,
    fullName: data?.fullName,
    email: data?.email,
    phoneNumber: data?.phoneNumber,
    role: data?.role,
    department: data?.department?._id,
    canDisburseFunds: data?.canDisburseFunds,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }))
  };

  const fieldRef = useRef(null);

  const handleScrollToField = () => {
    fieldRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleSubmit = async (e) => {
    if (error) return handleScrollToField();

    setLoading(true);
    const payload = { ...formValues };
    !data
      ? await createStaff(payload)(setLoading)(setRefresh)(onClose)
      : await updateStaff(payload)(setLoading)(setRefresh)(onClose);
  };

  const handleBlur = (email) => {
    if (email === "") return;
    const isValid = isValidEmail(email);
    console.log("IS VALID", isValid);
    if (!isValid) {
      setError(true);
    } else {
      setError(false);
    }
    // setError(true);
    return isValid;
  };

  return (
    <Box>
      <FormInputs
        type="text"
        name="fullName"
        label="Staff Name"
        value={formValues.fullName}
        handleChange={handleChange}
      />

      <FormInputs
        fieldRef={fieldRef}
        type="email"
        name="email"
        label="Email Address"
        value={formValues.email}
        handleKeyDown={() => isValidEmail(formValues.email)}
        handleChange={handleChange}
        handleBlur={() => handleBlur(formValues.email)}
      />
      {error && (
        <Text color="red" fontSize="1rem" mb="1rem">
          Email is invalid
        </Text>
      )}
      <FormInputs
        type="text"
        name="phoneNumber"
        label="Phone Number"
        value={formValues.phoneNumber}
        handleChange={handleChange}
      />
      <Box fontSize="18px" color="#616161">
        <Text pb="10px" fontWeight="semibold">
          Role
        </Text>
        <Select
          name="role"
          placeholder="Please select a role"
          size="lg"
          value={formValues.role}
          onChange={handleChange}
        >
          <option value="requester">Requester</option>
          <option value="approver">Approver</option>
        </Select>
      </Box>
      <Box
        mt="10px"
        display={formValues.role === "approver" ? "block" : "none"}
      >
        <Checkbox
          fontSize="12px"
          name="canDisburseFunds"
          isChecked={formValues.canDisburseFunds}
          value={formValues.canDisburseFunds}
          onChange={(e) => {
            const check = e.target.checked;
            setFormValues((prev) => ({
              ...prev,
              canDisburseFunds: check,
            }));
          }}
        >
          <Text fontSize="14px">Approver Should Disburse Funds ?</Text>
        </Checkbox>
      </Box>

      <Box fontSize="18px" color="#616161" mt="20px">
        <Text pb="10px" fontWeight="semibold">
          Department
        </Text>
        <Select
          name="department"
          placeholder={formValues.department || "Please select a department"}
          size="lg"
          value={formValues.department}
          onChange={handleChange}
        >
          {department?.map((dept) => (
            <option key={dept?._id} value={dept?._id}>
              {dept?.department}
            </option>
          ))}
        </Select>
      </Box>

      <Box align="center" mt="40px">
        <CustomBtn
          text={text}
          width="250px"
          height="48px"
          loading={loading}
          handleClick={handleSubmit}
          disabled={
            !formValues.department ||
            !formValues.fullName ||
            !formValues.email ||
            !formValues.phoneNumber
          }
        />
      </Box>
    </Box>
  );
}

export default AddStaffModal;
