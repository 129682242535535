import { createContext, useEffect, useState } from "react";
import { getUserProfile } from "./getUserProfile";
import { getCompanyDetails } from "../services/accountSettings";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  const [state, setState] = useState({ signin: true });
  const [, setData] = useState({});
  const [, setLoading] = useState(true);

  const dispatch = (incoming) => {
    setState((prev) => ({ ...prev, ...incoming }));
  };

  useEffect(() => {
    getUserProfile(setState).then((data) =>
      getCompanyDetails(setData, setLoading, data?.companyId).then((dat) =>
        dispatch({ companyDetails: dat })
      )
    );
  }, [state?.userInfo?.fullName]);
  return (
    <UserProfileContext.Provider value={{ dispatch, state }}>
      {children}
    </UserProfileContext.Provider>
  );
};
