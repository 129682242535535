import React from "react";
import { Text, Box, Tooltip } from "@chakra-ui/react";

export const TextInputs = ({ label, text, width }) => {
  return (
    <Box width={width || "100%"}>
      <Text
        color="#616161"
        fontSize="17px"
        mb={"10px"}
        fontWeight="semibold"
        mt={"10px"}
      >
        {label}
      </Text>
      <Tooltip label={text}>
        <Text
          border="1px solid #70707025"
          py="6.5px"
          px="10px"
          borderRadius="8px"
          color="#616161"
          maxWidth="200px"
          isTruncated
        >
          {text}
        </Text>
      </Tooltip>
    </Box>
  );
};
