import { useState } from "react";
import {
  Box,
  Input,
  Avatar,
  Flex,
  Text,
  Tooltip,
  Stack,
  Skeleton,
} from "@chakra-ui/react";
import { GrAttachment } from "react-icons/gr";
import { CustomBtn } from "../CustomBtn";
import MessageCard from "./MessageCard";
import { getCoversationsById, sendMessage } from "../../services/messages";
import { useGetState } from "../../contexts/useGetState";
import dayjs from "dayjs";
// import chatBg from "../../assets/chatBg.png";
import { AiOutlineArrowLeft } from "react-icons/ai";

const ChatFeed = ({
  recipientDetails,
  conversationsById,
  setRefresh,
  setChatBoxRef,
  setAddToChatList,
  setConversations,
  setShowChatFeed,
  setShowFriendsList,
}) => {
  const { state } = useGetState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [file, setFile] = useState();

  // console.log(conversationsById, "recipientDetails");
  const recipientOrSender =
    recipientDetails?.entityOneId?._id === state?.userInfo?._id
      ? recipientDetails?.entityTwoId
      : recipientDetails?.entityOneId;

  const recipientId = recipientOrSender?._id || recipientDetails?._id;

  // function handleKeyDown(event) {
  //   if (event.key === "Enter" && event.shiftKey) {
  //     event.preventDefault();
  //     setMessage(message + "\n");
  //   }
  // }

  // console.log("FILE", file);

  const handleSubmit = async (e) => {
    setBtnLoading(true);

    const formData = new FormData();
    formData.append("image", file);
    formData.append("recipient", "User");
    formData.append("message", message);
    formData.append("recipientId", recipientId);
    const data = await sendMessage(formData, setBtnLoading, setRefresh);
    setLoading(true);
    await getCoversationsById(
      data?.conversationId,
      setConversations,
      setLoading
    );
    setAddToChatList("conversations");
    setMessage("");
    setFile();
  };
  return (
    <Box
      w={["100%", "100%", "62%"]}
      p="5px"
      h="70vh"
      bg="#fff"
      // overflowY={"scroll"}
      borderTopRightRadius={"5px"}
      borderTopLeftRadius="5px"
      borderBottomRightRadius={"5px"}
      borderBottomLeftRadius="5px"
    >
      {window.innerWidth < 600 && (
        <AiOutlineArrowLeft
          onClick={() => {
            setShowChatFeed(false);
            setShowFriendsList(true);
          }}
        />
      )}
      <Flex align="center" boxShadow="sm" p="9px 20px">
        <Avatar
          name={recipientOrSender?.fullName || recipientDetails?.fullName}
          src={recipientDetails?.image}
        />
        <Box>
          <Text ml="10px" fontWeight="bold">
            {recipientOrSender?.fullName || recipientDetails?.fullName}
          </Text>
          <Text ml="10px" fontSize="12px" color="#707070">
            Last seen{" "}
            {dayjs(conversationsById?.[0]?.updatedAt).format(
              "d-MMM-YYYY, hh:mm a"
            )}
          </Text>
        </Box>
      </Flex>
      <Box mt="16px" bg="#fff" h="50vh">
        <Box
          h="90%"
          p="10px"
          overflowY={"scroll"}
          className="friends"
          ref={(ref) => ref && setChatBoxRef(ref)}
        >
          {
            loading ? (
              <Stack>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            ) : (
              conversationsById?.map((message, i, conv) => {
                const prevDate = conv[i - 1];
                return (
                  <MessageCard
                    key={message?._id}
                    message={message}
                    showDate={
                      dayjs(prevDate?.createdAt).format("DD-MM-YYYY") ===
                      dayjs(message?.createdAt).format("DD-MM-YYYY")
                    }
                  />
                );
              })
            )
            // : (
            //   <Stack>
            //     <Skeleton height="20px" />
            //     <Skeleton height="20px" />
            //     <Skeleton height="20px" />
            //     <Skeleton height="20px" />
            //     <Skeleton height="20px" />
            //     <Skeleton height="20px" />
            //   </Stack>
            // )
            // : (
            //   <Flex
            //     bgImage={chatBg}
            //     bgPos={"center"}
            //     bgSize={"contain"}
            //     bgRepeat={"no-repeat"}
            //     direction={"column"}
            //     width="100%"
            //     justify={"center"}
            //     align={"center"}
            //     h="100%"
            //     fontWeight="semibold"
            //     color="gray"
            //   >
            //     <Text fontSize={"20px"}>
            //       You dont have any active chat with {recipientDetails?.fullName}
            //     </Text>
            //     <Text fontSize="18px" mb="40px">
            //       Start a chat with {recipientDetails?.fullName}
            //     </Text>
            //   </Flex>
            // )
          }
        </Box>
        <Flex px="15px">
          <Box mr="20px" cursor={"pointer"} position="relative">
            <GrAttachment size="22px" />
            <Box position={"absolute"} top="0" width="40px">
              <input
                // ref={uploadFile}
                accept="image/jpeg, image/jpg, image/png"
                // onChange={handleFileUpload}
                onChange={(e) => setFile(e.target.files?.[0])}
                type="file"
                style={{ opacity: "0", width: "30px", fontWeight: "bold" }}
                cursor={"pointer"}
              />
              <Tooltip label={file?.name}>
                <Text width="40px" isTruncated fontSize="10px">
                  {file?.name}
                </Text>
              </Tooltip>
            </Box>
          </Box>
          <Input
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            mr="20px"
            border="1px solid #70707021"
            bg="#9898982D"
          />
          <CustomBtn
            text="Send"
            p="15px 40px !important"
            loading={btnLoading}
            disabled={!message && !file}
            handleClick={handleSubmit}
          />
        </Flex>
      </Box>
    </Box>
  );
};

export default ChatFeed;
