import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Heading, Input, Text } from "@chakra-ui/react";

import { CustomBtn } from "../components/CustomBtn";
import { loginUser } from "../services/login";
import AuthFlowWrapper from "../components/AuthFlowWrapper";

const LoginPage = () => {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogin = async () => {
    setLoading(true);
    const payload = { ...formValues };
    await loginUser(payload, setLoading);
  };
  
  return (
    <AuthFlowWrapper>
      <Box
        p={["20px", "20px", "50px"]}
        bg="#fff"
        textAlign={"center"}
        width={["80%", "80%", "100%", "60%", "40%"]}
        borderRadius={"10px"}
        mt="20px"
      >
        <Heading as="h2" fontSize={"22px"} mb="5px">
          Welcome!
        </Heading>
        <Text maxW={"370px"} fontSize="18px" ml="30px">
          Kindly fill in your sign in details and proceed to dashboard
        </Text>
        <Box mt="40px">
          <Input
            mb="25px"
            height={"45px"}
            width="100%"
            placeholder="Email"
            border="1px solid #3C7B79B8"
            type={"email"}
            name={"email"}
            value={formValues?.email}
            onChange={handleChange}
          />
          <Input
            mb="40px"
            height={"45px"}
            width="100%"
            placeholder="Password"
            border="1px solid #3C7B79B8"
            type={"password"}
            name="password"
            value={formValues.password}
            onChange={handleChange}
          />
          <CustomBtn
            width={["100%","100%","50%"]}
            text={"Sign In"}
            handleClick={handleLogin}
            loading={loading}
          />
          <Text>
            Forgot password?{" "}
            <Link style={{ color: "#3C7B79" }} to="/reset-password">
              Get a new password
            </Link>
          </Text>
        </Box>
      </Box>
    </AuthFlowWrapper>
  );
};

export default LoginPage;
